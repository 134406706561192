
// Serivce
import { RoutingService } from "app/services/routing/routing.service";
import { Component, Input, OnInit } from '@angular/core';
import { EndpointService } from 'app/services/generic/endpoint.service';
import { AuthService } from 'app/services/auth/auth.service';
import { FunctionsService } from 'app/services/generic/functions.service';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-restaurant-pedidos-mesas-custom',
  templateUrl: './restaurant-pedidos-mesas-custom.component.html',
  styleUrls: ['./restaurant-pedidos-mesas-custom.component.scss']
})
export class RestaurantPedidosMesasCustomComponent implements OnInit {

  @Input() structure = null;
  @Input() index: number;
  @Input() param: any = null;

  public loaded = false;
  public loadedW = false;

  public coordenada_y_max = 0;

  public previewMode = 0;
  public statusMesa = 'defaultMesa';
  public maxWidth = null;
  public maxHeight = null;
  public maxWidthPX = null;
  public maxHeightPX = null;

  public paddingAll = 10;

  public nodesX = 18;
  public nodesY = 20;
  public coordXs = []
  public coordYs = []
  public lineSize = null;
  public fotoSize = 30;
  public fotoSizePX = this.fotoSize + "px";
  public lines = [];
  public mesas = [];
  public objetos = [];
  public posicionesOcupadas = new Map<number, number[]>();
  public idCompany = null;
  private currentDate = new Date(Date.now());
  private currentTime = new Date(Date.now()).toLocaleTimeString('en-GB');
  private dialogEliminarMesa = 0;
  private dialogSituarMesa = 0;
  private dialogAñadirPedido = 0;
  private dialogVerReservas = 0;
  private dialogAñadirPedidoMuliple = 0;
  private dialogEliminarObjeto = 0;
  private dialogSituarObjeto = 0;
  private idPedido = 0;
  public from = 0;

  constructor(private endpointService: EndpointService, private authService: AuthService, private functionsService: FunctionsService, private genericService: GenericService, private routingService: RoutingService) { }



  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /// CUSTOM SITUAR MESAS MAPA MESAS (id_functional_type 151, id_functional_area = 35699)
  /// ['internal_routing_id_functional_area] -> dialog descolocar mes
  /// ['id_table_relations'] -> dialog situar mesa

  /// CUSTOM PEDIDOS MAPA MESAS (id_functional_type 152, id_functional_area = 36736)
  /// ['id_db'] -> dialog pedidos mesas, caso: multiples pedidos en una misa mesa
  /// ['internal_routing] -> routing a pedido, caso: unico pedido en la mesa
  /// ['id_table_relations'] -> dialog añadir nuevo pedido, caso: mesa libre y dividir mesa

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ngOnInit(): void {
    if (this.structure[this.index]['id_functional_type'] == 154) {
      this.from = 1;
    }
    // Para edición de mesas
    if(this.structure[this.index]['internal_routing_id_functional_area']) this.dialogEliminarMesa = +this.functionsService.cloneVariable(this.structure[this.index]['internal_routing_id_functional_area']);
    if(this.structure[this.index]['id_table_relations']) this.dialogSituarMesa = +this.functionsService.cloneVariable(this.structure[this.index]['id_table_relations']);
    if(this.structure[this.index]['min_rows']) this.dialogEliminarObjeto = +this.functionsService.cloneVariable(this.structure[this.index]['min_rows']);
    if(this.structure[this.index]['max_rows']) this.dialogSituarObjeto = +this.functionsService.cloneVariable(this.structure[this.index]['max_rows']);
    // Para funcionamiento de mesas
    if(this.structure[this.index]['id_table_relations']) {
      if(this.from == 1) this.dialogVerReservas = +this.functionsService.cloneVariable(this.structure[this.index]['id_table_relations']);
      else this.dialogAñadirPedido = +this.functionsService.cloneVariable(this.structure[this.index]['id_table_relations']);;
    }
    if(this.structure[this.index]['id_db']) this.dialogAñadirPedidoMuliple = +this.functionsService.cloneVariable(this.structure[this.index]['id_db']);
    if(this.structure[this.index]['internal_routing_id_functional_area'])  this.idPedido = +this.functionsService.cloneVariable(this.structure[this.index]['internal_routing_id_functional_area']);

    setInterval(() => {
      this.updateTimestamp();
    }, 1000);
    if (this.structure[this.index]['id_functional_type'] == 151) this.previewMode = 1;
    let interns = this.genericService.getInternParam(this.structure[this.index], this.param);
    let idSala = null;
    for(let i in interns) {
      if(interns[i]['id_db'] == 1 && interns[i]['bd_table'] == "rest_salas" && interns[i]["bd_field"] == "id_sala") {
        idSala = interns[i]['value'];
        break;
      }
    }
    if (idSala) {
      this.endpointService.getRestMesas(this.authService.getIdCompany(), idSala, this.from, this.functionsService.fecha_inicio_reservas, this.functionsService.hora_inicio_reservas, this.functionsService.fecha_fin_reservas, this.functionsService.hora_fin_reservas).subscribe(data => {
        this.getCustomWidth();
        if (data) {
          this.mesas = data['response']['mesas'];
          this.objetos = data['response']['objetos'];
          this.calculateDataMesas();
          this.calculateDataObejos();
          this.initCoordenadas();
          // this.initMapaMesas();
          if(this.previewMode == 1) {
            this.drawLines();
          }
          this.idCompany = this.authService.getIdCompany();
        }
      });
    } else {
      console.log("ERRORRR => No hay sala");
    }
  }

  private animateImage(id) {
    let imageId = 'ImageMesa'+id;
    let imgElement = document.getElementById(imageId); 
    if (imgElement) {
      imgElement.classList.add('movimiento-fijo');
    }
    let imageId0 = 'ImageMesa'+id+'0';
    let imgElement0 = document.getElementById(imageId0); 
    if (imgElement0) {
      imgElement0.classList.add('movimiento-fijo');
    }
  }

  private updateTimestamp(): void {
    this.currentDate = new Date(Date.now());
    this.currentTime = this.currentDate.toLocaleTimeString('en-GB');
    for (let i in this.mesas) {
      this.mesas[i]["passedTimeMs"] += 1000;
      let passedTimeMs = this.mesas[i]["passedTimeMs"];
      let passedTime = this.convertMillisecondsToTime(passedTimeMs);
      this.mesas[i]["passedTime"] = passedTime;
      
      if (this.mesas[i] && this.mesas[i]["timeDifferenceMs"] && this.mesas[i]["timeDifferenceMs"] != null) {
        this.mesas[i]["timeDifferenceMs"] += 1000;
        let timeDifferenceMs = this.mesas[i]["timeDifferenceMs"];
          if (this.mesas[i]['num_pedidos'] == 1 && !this.mesas[i]["passed"] && (timeDifferenceMs/1000)/60 > this.mesas[i]['minutos']) {
            this.mesas[i]["passed"] = true;
            this.animateImage(this.mesas[i].id_mesa);
          }          
          let timeDifference = this.convertMillisecondsToTime(timeDifferenceMs);
          this.mesas[i]["timeDifference"] = timeDifference;
        } 
      }
  }

  public initCoordenadas() {
    for (let i = 1; i < this.nodesX - 1; i++) {
      this.coordXs.push(i);
    }
    for (let i = 1; i < this.nodesY - 1; i++) {
      this.coordYs.push(i);
    }
  }

  public getCustomWidth() {
    let elementSize = null;
    let actualClass = "generic-type-" + this.structure[this.index]['id_functional_type'];
    elementSize = document.getElementsByClassName(actualClass)[0];
    if (elementSize !== undefined) {
      let element = elementSize.getBoundingClientRect();
      this.maxWidth = element.width - 10;
      if(this.maxWidth < 1200) this.maxWidth = 1200; 
    } else {
      this.maxWidth = 1200;
    }
    this.maxWidthPX = this.maxWidth + "px";
    this.lineSize = this.maxWidth / (this.nodesX - 1);
    console.log("maxWidth", this.maxWidth, "elementSize", elementSize, document.getElementsByClassName(actualClass), "lineSize", this.lineSize);
    this.loadedW = true;
  }

  public getCustomHeight() {
    this.maxHeight = this.nodesY * this.lineSize;
    this.maxHeightPX = this.maxHeight + "px";
    this.loaded = true;
    console.log("maxHeight", this.maxHeight, "maxHeightPX", this.maxHeightPX);
  }
  
  drawOneLine(startX, startY, endX, endY, typeLine) {
    this.lines.push({ startX, startY, endX, endY, typeLine });
  }

  drawLines() {
    for (let i = 0; i < this.nodesX; i++) {
      for (let j = 0; j < this.nodesY; j++) {
        let startX = i * this.lineSize;
        let endX = (i + 1) * this.lineSize;
        let startY = j * this.lineSize;
        let endY = (j + 1) * this.lineSize;
        let typeLine = 12;
        
        if (i < this.nodesX - 1 && j < this.nodesY - 1) {
          this.drawOneLine(startX, startY, startX, endY, typeLine);
          this.drawOneLine(startX, startY, endX, startY, typeLine);
        } else {
          if (i != this.nodesX - 1) {
            //Draw only horizontal line
            this.drawOneLine(startX, startY, endX, startY, 2);
          } else if (j != this.nodesY - 1) {
            //Draw only vertical line
            this.drawOneLine(startX, startY, startX, endY, 2);
          }
        }
      }
    }
    this.getCustomHeight();
  }
  
  public addElement(coordenada_x, coordenada_y, type = 0) {
    let xfounded = false;
    let yfounded = false;
    let table = "rest_mesas";
    if(type == 1) table = "rest_mesas_objetos";

    if (!this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]) {
      this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']] = this.genericService.createNewParamVariable();
    }

    let intern = this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']];
    if (intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']] && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern']) {
      for (let j in intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern']) {
        if (intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['id_db'] == 1 && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['bd_table'] == table && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['bd_field'] == "coordenada_x") {
          intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['value'] = coordenada_x;
          xfounded = true;
          if (yfounded) break;
        }
        if (intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['id_db'] == 1 && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['bd_table'] == table && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['bd_field'] == "coordenada_y") {
          intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['value'] = coordenada_y;
          yfounded = true;
          if (xfounded) break;
        }
      }
    }
    if (!xfounded) {
      let obj = { id_db: 1, bd_table: table, bd_field: "coordenada_x", value: coordenada_x }
      intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'].push(obj);
      xfounded = true;
    }
    if (!yfounded) {
      let obj = { id_db: 1, bd_table: table, bd_field: "coordenada_y", value: coordenada_y }
      intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'].push(obj);
      yfounded = true;
    }

    if(type == 0) this.structure[this.index]['internal_routing_id_functional_area'] = this.functionsService.cloneVariable(this.dialogSituarMesa);
    else if(type == 1) this.structure[this.index]['internal_routing_id_functional_area'] = this.functionsService.cloneVariable(this.dialogSituarObjeto);
    this.functionsService.openDialog(this.structure[this.index], 3);    
  }

  public deleteElement(idElement, type = 0) {
    let idFounded = false;
    let xfounded = false;
    let yfounded = false;
    let table = "rest_mesas";
    if(type == 1) table = "rest_mesas_objetos";
    let field = "id_mesa";
    if(type == 1) field = "id_objeto";

    if (!this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]) {
      this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']] = this.genericService.createNewParamVariable();
    }

    let intern = this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']];
    if (intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']] && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern']) {
      for (let j in intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern']) {
        if (intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['id_db'] == 1 && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['bd_table'] == table && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['bd_field'] == field) {
          intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['value'] = idElement;
          idFounded = true;
        }
        if (intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['id_db'] == 1 && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['bd_table'] == table && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['bd_field'] == "coordenada_x") {
          intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['value'] = null;
          xfounded = true;
        }
        if (intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['id_db'] == 1 && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['bd_table'] == table && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['bd_field'] == "coordenada_y") {
          intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['value'] = null;
          yfounded = true;
        }
        if(idFounded && xfounded && yfounded) break;
      }
    }
    if (!idFounded) {
      let obj = { id_db: 1, bd_table: table, bd_field: field, value: idElement }
      intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'].push(obj);
    }
    if (!xfounded) {
      let obj = { id_db: 1, bd_table: table, bd_field: "coordenada_x", value: null }
      intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'].push(obj);
    }
    if (!yfounded) {
      let obj = { id_db: 1, bd_table: table, bd_field: "coordenada_y", value: null }
      intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'].push(obj);
    }

    if(type == 0) this.structure[this.index]['internal_routing_id_functional_area'] = this.functionsService.cloneVariable(this.dialogEliminarMesa);
    else if(type == 1) this.structure[this.index]['internal_routing_id_functional_area'] = this.functionsService.cloneVariable(this.dialogEliminarObjeto);
    this.functionsService.openDialog(this.structure[this.index], 3);
  }

  public pedidoMesa(mesa) {
    console.log("hello world", mesa)
    let id_mesa = mesa.id_mesa;
    let id_pedido = mesa.id_pedido;
    if(this.from == 1) {
      let fecha_inicio_reserva = 0;
      if(this.functionsService.fecha_inicio_reservas) fecha_inicio_reserva = this.functionsService.fecha_inicio_reservas;
      let fecha_fin_reserva = 0;
      if(this.functionsService.fecha_fin_reservas) fecha_fin_reserva = this.functionsService.fecha_fin_reservas;
      let hora_inicio_reserva = 0;
      if(this.functionsService.hora_inicio_reservas) hora_inicio_reserva = this.functionsService.hora_inicio_reservas;
      let hora_fin_reserva = 0;
      if(this.functionsService.hora_fin_reservas) hora_fin_reserva = this.functionsService.hora_fin_reservas;
      let num_reservas = mesa.num_reservas;
      if(num_reservas > 0) {
        this.functionsService.addWindowParam(id_mesa, 1, "rest_mesas", "id_mesa", this.structure[this.index]['id_functional_parent_initial'])
        this.functionsService.addWindowParam(fecha_inicio_reserva, 1, "comodin_fecha_reserva", "comodin_fecha_inicio_reserva", this.structure[this.index]['id_functional_parent_initial'], false)
        this.functionsService.addWindowParam(fecha_fin_reserva, 1, "comodin_fecha_reserva", "comodin_fecha_fin_reserva", this.structure[this.index]['id_functional_parent_initial'], false)
        this.functionsService.addWindowParam(hora_inicio_reserva, 1, "comodin_fecha_reserva", "comodin_hora_inicio_reserva", this.structure[this.index]['id_functional_parent_initial'], false)
        this.functionsService.addWindowParam(hora_fin_reserva, 1, "comodin_fecha_reserva", "comodin_hora_fin_reserva", this.structure[this.index]['id_functional_parent_initial'], false)
        this.structure[this.index]['internal_routing_id_functional_area'] = this.functionsService.cloneVariable(this.dialogVerReservas);
        this.functionsService.openDialog(this.structure[this.index], 3);
      }
    } else {
      let num_pedidos = mesa.num_pedidos;
      if (id_pedido != null && num_pedidos == 1) {
        //UNICO PEDIDO -> ROUTING CON ID PEDIDO -> ['internal_routing']
        this.functionsService.addWindowParam(id_pedido, 1, "rest_pedidos", "id_pedido", this.structure[this.index]['id_functional_parent_initial'], true, "output");
        this.functionsService.route = this.structure[this.index]["internal_routing"];
        this.functionsService.id_route = this.functionsService.cloneVariable(this.idPedido);
        this.routingService.previousModule = this.structure[this.index]["id_functional_parent_initial"];
        this.functionsService.goRouting(this.structure[this.index]["internal_routing"], true);
      } else {
        //DIALOGS
        this.functionsService.addWindowParam(id_mesa, 1, "rest_mesas", "id_mesa", this.structure[this.index]['id_functional_parent_initial'])
        if (num_pedidos == 0) { //NO HAY PEDIDOS
          this.structure[this.index]['internal_routing_id_functional_area'] = this.functionsService.cloneVariable(this.dialogAñadirPedido);
        } else { //MULTIPLES PEDIDOS
          this.structure[this.index]['internal_routing_id_functional_area'] = this.functionsService.cloneVariable(this.dialogAñadirPedidoMuliple);
        }
        this.functionsService.openDialog(this.structure[this.index], 3);
      }
    }
  }
  
  private convertMillisecondsToTime(timeDifferenceMs) {
    let date = new Date(timeDifferenceMs);
    let hours = date.getUTCHours().toString().padStart(2, '0');
    let minutes = date.getUTCMinutes().toString().padStart(2, '0');
    let seconds = date.getUTCSeconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  }

  private calculateDataMesas() {
    for (let i in this.mesas) {
      if (this.mesas[i]) {
        //TIEMPO INSERT
        if (this.mesas[i]["fecha_insert"]) {
          let fechaInsertDate = new Date(this.mesas[i]["fecha_insert"]);          
          let passedTimeMs = this.currentDate.getTime() - fechaInsertDate.getTime();
          let passedTime = this.convertMillisecondsToTime(passedTimeMs);
          this.mesas[i]["passedTimeMs"] = passedTimeMs;          
          this.mesas[i]["passedTime"] = passedTime;
        } 
        //TIEMPO MODIFICACIÓN
        if (this.mesas[i]["ultima_modificacion"] && this.mesas[i]["ultima_modificacion"] != null) {
          let lastModifiedDate = new Date(this.mesas[i]["ultima_modificacion"]);
          this.mesas[i]["ultima_modificacion"] = lastModifiedDate.toLocaleTimeString('en-GB');
          
          let timeDifferenceMs = this.currentDate.getTime() - lastModifiedDate.getTime();
          let timeDifference = this.convertMillisecondsToTime(timeDifferenceMs);
          this.mesas[i]["timeDifferenceMs"] = timeDifferenceMs;          
          this.mesas[i]["timeDifference"] = timeDifference;
            this.mesas[i]["passed"] = false;
        } 
        //TAMAÑO DE LAS IMAGENES DE MESAS
        if (!(this.mesas[i]["width"])) this.mesas[i]["height"] = 1;
        if (!(this.mesas[i]["height"])) this.mesas[i]["height"] = 1;
        this.mesas[i]["widthCalculated"] = (this.mesas[i]["width"] * this.lineSize - this.paddingAll*2) + "px";
        this.mesas[i]["heightCalculated"] = (this.mesas[i]["height"] * this.lineSize - this.paddingAll*2) + "px";
        if(this.mesas[i]["coordenada_y"] && this.mesas[i]["height"] && this.mesas[i]["coordenada_y"] + this.mesas[i]["height"] > this.coordenada_y_max) this.coordenada_y_max = this.mesas[i]["coordenada_y"] + this.mesas[i]["height"];
      }
    }
    if(this.previewMode != 1) {
      this.nodesY = this.coordenada_y_max;
      this.getCustomHeight();
    }
  }
  
  private calculateDataObejos() {
    for (let i in this.objetos) {
      if (this.objetos[i]) {
        //TAMAÑO DE LAS IMAGENES DE objetos
        if (!(this.objetos[i]["width"])) this.objetos[i]["height"] = 1;
        if (!(this.objetos[i]["height"])) this.objetos[i]["height"] = 1;
        this.objetos[i]["widthCalculated"] = (this.objetos[i]["width"] * this.lineSize) + "px";
        this.objetos[i]["heightCalculated"] = (this.objetos[i]["height"] * this.lineSize) + "px";
        if(this.objetos[i]["coordenada_y"] && this.objetos[i]["height"] && this.objetos[i]["coordenada_y"] + this.objetos[i]["height"] > this.coordenada_y_max) this.coordenada_y_max = this.objetos[i]["coordenada_y"] + this.objetos[i]["height"];
      }
    }
    if(this.previewMode != 1) {
      this.nodesY = this.coordenada_y_max;
      this.getCustomHeight();
    }
  }

  private initMapaMesas() {
    for (let mesa of this.mesas) {
      if (mesa && mesa.coordenada_x && mesa.coordenada_y) {
        let existingArray = this.posicionesOcupadas.get(mesa.coordenada_x);
        if (existingArray) {
          if (existingArray.includes(mesa.coordenada_y)) {
            this.genericService.openSnackBar("Error: Existe mesas solapadas!", 5000, ["red-snackbar",]);
          }
          else {
            existingArray.push(mesa.coordenada_y);
          }
        } else {
          this.posicionesOcupadas.set(mesa.coordenada_x, [mesa.coordenada_y]);
        }
      }
    }
  }

  notOccupiedPosition(coordenada_x, coordenada_y) {
    let existingArray = this.posicionesOcupadas.get(coordenada_x);
    if (existingArray) {
      return !(existingArray.includes(coordenada_y));
    }
    return true;
  }

  CanviarStatus(status) {
    this.statusMesa = status;
  }

  dividirMesa(id_mesa, num_pedidos) {
    let idFounded = false;

    if (!this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]) {
      this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']] = this.genericService.createNewParamVariable();
    }

    let intern = this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']];
    if (intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']] && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern']) {
      for (let j in intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern']) {
        if (intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['id_db'] == 1 && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['bd_table'] == "rest_mesas" && intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['bd_field'] == "id_mesa") {
          intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'][j]['value'] = id_mesa;
          idFounded = true;
          break;
        }
      }
    }
    if (!idFounded) {
      let obj = { id_db: 1, bd_table: "rest_mesas", bd_field: "id_mesa", value: id_mesa }
      intern['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['intern'].push(obj);
    }

    this.structure[this.index]['internal_routing_id_functional_area'] = this.functionsService.cloneVariable(this.dialogAñadirPedido);
    this.functionsService.openDialog(this.structure[this.index], 3);
  }
}
