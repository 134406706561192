import {ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {DynamicReportBody} from "../workflow/subcomponents/dynamic-report/body/dynamic-report-body";
import {MatDialog} from "@angular/material/dialog";
import {EndpointService} from "../services/generic/endpoint.service";
import {AuthService} from "../services/auth/auth.service";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {DeviceDetectorService} from "ngx-device-detector";
import {Router} from "@angular/router";
import {AdviceReportService} from "../services/advice-report/advice-report.service";
import {ButtonArray} from "../auto-plantilla/auto-plantilla.component";
import {forkJoin} from "rxjs";

@Component({
  selector: 'notificacion-superior',
  templateUrl: './notificacion-superior.component.html',
  styleUrls: ['./notificacion-superior.component.css']
})

export class NotificacionSuperiorComponent implements OnInit{

  public iconD;
  public colorD;
  public typeD;
  public toolD;
  public activeD;

  public bodyReport: DynamicReportBody;

  public containerArray : {idType: number, container: any, color: string, id: number} [] = [];
  public buttonArray: {active: boolean, name: string, text: string, tooltip: string, actionArray: [], colorText: string,
    colorBackground: string, colorEdge: string, icon: string, icon_color: string, icon_type: number, icon_tooltipe: string,
    icon_active: boolean, link: string, pantalla: number, otra_plantilla: number} [] = [];

  public notificacionSuperior : {plantilla: any, boton: any, demo: boolean, not: any} [] = [];

  constructor(public dialog: MatDialog,
              private endpointService: EndpointService,
              private cd: ChangeDetectorRef,
              public authService: AuthService,
              private _bottomSheet: MatBottomSheet,
              public deviceService: DeviceDetectorService,
              // private _changeDetectorRef: ChangeDetectorRef,
              public adviceReport: AdviceReportService) { }

  ngOnInit(): void {

    this.bodyReport = new DynamicReportBody();

    this.containerArray = [];
    this.notificacionSuperior = [];

    this.buttonArray.push({active: false, name: "Botón 1: ", text: null, tooltip: null, actionArray: [], colorText: "#000000",
      colorBackground: "#000000", colorEdge: "#000000", icon: "event", icon_color: "#000000", icon_type: 1, icon_tooltipe: null,
      icon_active: true, link: null, pantalla: null, otra_plantilla: null});

    this.buttonArray.push({active: false, name: "Botón 2: ", text: null, tooltip: null, actionArray: [], colorText: "#000000",
      colorBackground: "#000000", colorEdge: "#000000", icon: "event", icon_color: "#000000", icon_type: 1, icon_tooltipe: null,
      icon_active: true, link: null, pantalla: null, otra_plantilla: null});

    this.buttonArray.push({active: false, name: "Botón 3: ", text: null, tooltip: null, actionArray: [], colorText: "#000000",
      colorBackground: "#000000", colorEdge: "#000000", icon: "event", icon_color: "#000000", icon_type: 1, icon_tooltipe: null,
      icon_active: true, link: null, pantalla: null, otra_plantilla: null});

    this.adviceReport.close.subscribe( i =>{
      this.close(i);
    });

    this.adviceReport.closeRemoto.subscribe( i =>{
      this.closeRemoto(i);
    });

  }

  public openSuperior(id: number, not?: any){

    this.containerArray = [];
    let auxButton: ButtonArray[] = [];
    if(this.notificacionSuperior.find(sup => sup.plantilla.id === not) === undefined) {
      this.endpointService.getPlantilla(id, this.authService.empresaId).subscribe(data =>{
        console.log("data openSuperior", data);
        this.bodyReport.handlePlantillaInfoResponse(data['response']['plantilla'][0], data['response']['screen'], data['response']['icon']);
        let p1 = new Promise<void>((resolve, reject) => {
          if(data['response']['button1'][0]) {

            if(data['response']['iconButton1']['length'] > 0){

              this.iconD = data['response']['iconButton1'][0].icon_nombre;
              this.colorD = data['response']['iconButton1'][0].icon_color;
              this.typeD = data['response']['iconButton1'][0].id_icon_clase;
              this.toolD = data['response']['iconButton1'][0].icon_tootltip;
              this.activeD = true;

            }else{

              this.iconD = "event";
              this.colorD = "#000000";
              this.typeD = 1;
              this.toolD = null;
              this.activeD = false;

            }

            let btn1: ButtonArray = null;
            btn1 = {
              id_button: data['response']['button1'][0].id,
              active: true,
              name: "Botón 1: ",
              text: data['response']['button1'][0].button_texto,
              tooltip: data['response']['button1'][0].button_tooltip,
              actionArray: data['response']['button1'][0].action,
              colorText: data['response']['button1'][0].button_color_texto,
              colorBackground: data['response']['button1'][0].button_color_fondo,
              colorEdge: data['response']['button1'][0].button_color_borde,
              icon: this.iconD,
              icon_color: this.colorD,
              icon_type: this.typeD,
              icon_tooltipe: this.toolD,
              icon_active: this.activeD,
              link: data['response']['button1'][0].button_accion_link,
              pantalla: data['response']['button1'][0].id_pantalla,
              otra_plantilla: data['response']['button1'][0].id_plantilla_ego_button,
              order: 1
            };
            resolve();
            auxButton[0] = btn1;
          } else {
            resolve();
          }
        });

        let p2 = new Promise<void>((resolve, reject) => {
          if(data['response']['button2'][0]) {

            if(data['response']['iconButton2']['length'] > 0){

              this.iconD = data['response']['iconButton2'][0].icon_nombre;
              this.colorD = data['response']['iconButton2'][0].icon_color;
              this.typeD = data['response']['iconButton2'][0].id_icon_clase;
              this.toolD = data['response']['iconButton2'][0].icon_tootltip;
              this.activeD = true;

            }else{

              this.iconD = "event";
              this.colorD = "#000000";
              this.typeD = 1;
              this.toolD = null;
              this.activeD = false;

            }

            let btn2: ButtonArray = null;
            btn2 = {
              id_button: data['response']['button2'][0].id,
              active: true,
              name: "Botón 2: ",
              text: data['response']['button2'][0].button_texto,
              tooltip: data['response']['button2'][0].button_tooltip,
              actionArray: data['response']['button2'][0].action,
              colorText: data['response']['button2'][0].button_color_texto,
              colorBackground: data['response']['button2'][0].button_color_fondo,
              colorEdge: data['response']['button2'][0].button_color_borde,
              icon: this.iconD,
              icon_color: this.colorD,
              icon_type: this.typeD,
              icon_tooltipe: this.toolD,
              icon_active: this.activeD,
              link: data['response']['button2'][0].button_accion_link,
              pantalla: data['response']['button2'][0].id_pantalla,
              otra_plantilla: data['response']['button2'][0].id_plantilla_ego_button,
              order: 2
            };
            resolve();
            auxButton[1] = btn2;
          } else {
            resolve();
          }
        });

        let p3 = new Promise<void>((resolve, reject) => {
          if(data['response']['button3'][0]){

            if(data['response']['iconButton3']['length'] > 0){

              this.iconD = data['response']['iconButton3'][0].icon_nombre;
              this.colorD = data['response']['iconButton3'][0].icon_color;
              this.typeD = data['response']['iconButton3'][0].id_icon_clase;
              this.toolD = data['response']['iconButton3'][0].icon_tootltip;
              this.activeD = true;

            }else{

              this.iconD = "event";
              this.colorD = "#000000";
              this.typeD = 1;
              this.toolD = null;
              this.activeD = false;

            }

            let btn3: ButtonArray = null;
            btn3 = {
              id_button: data['response']['button3'][0].id,
              active: true, name: "Botón 3: ",
              text: data['response']['button3'][0].button_texto,
              tooltip: data['response']['button3'][0].button_tooltip,
              actionArray: data['response']['button3'][0].action,
              colorText: data['response']['button3'][0].button_color_texto,
              colorBackground: data['response']['button3'][0].button_color_fondo,
              colorEdge: data['response']['button3'][0].button_color_borde,
              icon: this.iconD,
              icon_color: this.colorD,
              icon_type: this.typeD,
              icon_tooltipe: this.toolD,
              icon_active: this.activeD,
              link: data['response']['button3'][0].button_accion_link,
              pantalla: data['response']['button3'][0].id_pantalla,
              otra_plantilla: data['response']['button3'][0].id_plantilla_ego_button,
              order: 3
            };
            resolve();
            auxButton[2] = btn3;
          } else {
            resolve();
          }
        });


        const observersArray = [];
        observersArray.push(p1);
        observersArray.push(p2);
        observersArray.push(p3);
        forkJoin(observersArray).subscribe(dataa => {
          this.buttonArray = auxButton;
          this.notificacionSuperior.push({plantilla: this.bodyReport, boton: this.buttonArray, demo: false, not: not});
        });
      });
    }
  }

  public prueba(){
    console.log('parece que entro...');
  }

  reset(){
    //while (this.notificacionSuperior.length > 0) this.notificacionSuperior.pop();
    this.notificacionSuperior = [];
  }

  close(i: number){
    this.notificacionSuperior.splice(i, 1);
    this.adviceReport.notificationSuperior.splice(i, 1);
    //console.log(this.adviceReport.notificationSuperior);
  }

  closeRemoto(id: number){
    let index = this.notificacionSuperior.findIndex( x => x.plantilla.id == id);
    this.notificacionSuperior.splice(index, 1);
    this.adviceReport.notificationSuperior.splice(index, 1);
    //console.log(this.adviceReport.notificationSuperior);
  }

}
