import {Component, OnInit, Input, ChangeDetectorRef, AfterContentChecked, OnDestroy} from '@angular/core';
import { EndpointService } from "../../../services/generic/endpoint.service";
import { RoutingService } from "../../../services/routing/routing.service";
import { GenericService } from 'app/services/generic/generic.service';
import { Location } from '@angular/common';
import { AuthService } from 'app/services/auth/auth.service';
import { FunctionsService } from 'app/services/generic/functions.service';
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';
import { GenericCreatorService } from 'app/services/generic/custom/generic-creator.service';
import { ConditionsClientService } from 'app/services-client/conditions-client.service';

@Component({
  selector: 'app-expansion',
  templateUrl: './expansion.component.html',
  styleUrls: ['./expansion.component.css']
})
export class ExpansionComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() structure = null;
  @Input() index = null;
  @Input() param : any = null;

  public child: any;
  public array: any;
  public ids: any;
  private monitoreo;
  public loaded = false;
  public openedexpansion = 0;
  public idTable = null;
  public idRow = null;

  constructor(private endPointService: EndpointService,
    private routingService: RoutingService,
    private changeDetector: ChangeDetectorRef,
    public genericService: GenericService,
    private location: Location,
    private authService: AuthService,
    public functionsService: FunctionsService,
    public functionsControllerService: FunctionsControllerService,
    public genericCreatorService: GenericCreatorService,
    public conditionsClientService: ConditionsClientService) { }

  ngOnInit(): void {
    if(this.structure[this.index]['child'] && this.structure[this.index]['child']['id_functional_parent_initial_dsb'] && this.structure[this.index]['child']['id_functional_parent_initial_dsb'] == 0) {
      if (this.routingService.urlHashtag != null) {
        const id = this.routingService.urlHashtag;
        for (const key in this.structure[this.index]['child']) {
          if (this.structure[this.index]['child'][key]['id_functional_area'] == id) {
            this.closeAllExpansions();
            this.structure[this.index]['child'][key]['expansion_start_opened'] = 1;
            this.structure[this.index]['child'][key]['expansionActiveNow'] = true;
            this.openedexpansion = +key;
            this.location.replaceState(this.routingService.urlWithoutHashtag + '#' + this.structure[this.index]['child'][key]['id_functional_area']);
            break;
          }
          const element = this.genericService.findElementWithIdLoop(this.genericService.getAllStructures(1), id);
          if (element) {
            this.closeAllExpansions();
            this.structure[this.index]['child'][key]['expansion_start_opened'] = 1;
            this.structure[this.index]['child'][key]['expansionActiveNow'] = true;
            this.openedexpansion = +key;
            this.location.replaceState(this.routingService.urlWithoutHashtag + '#' + id);
            break;
          }
        }
      }
    }
    if(this.param !== null) {
      let aux = this.param.split('.')
      if(aux.length == 2) {
        this.idTable = aux[0]
        this.idRow = aux[1]
      }
      else if(aux.length == 3) {
        this.idTable = aux[0] + '.' + aux[1]
        this.idRow = aux[2]
      }
    }
    /*this.endPointService.insertMonitoringInformation(
      this.authService.getLoggedInUserId(), this.structure[this.index]['child'][this.openedexpansion]['id_functional_area'], this.structure[this.index]['child'][this.openedexpansion]['id_functional_area'], this.genericService.staticHTML).subscribe(data => {
      this.monitoreo = data['response'];
    });*/
    this.loaded = true;
  }

  ngAfterContentChecked() {
    //this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
      this.endPointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {});
  }

  setOpened(index: number) {
    //if(this.structure[this.index]['child'][0]['id_functional_parent_initial_dsb'] == 0) {
      for (const i in this.structure[this.index]['child']) {
        if(index == +i) {
          /*this.endPointService.insertMonitoringInformation(
            this.authService.getLoggedInUserId(), this.structure[this.index]['child'][i]['id_functional_area'], this.structure[this.index]['child'][i]['id_functional_area'], this.genericService.staticHTML).subscribe(data => {
            this.monitoreo = data['response'];
          });*/

          const url = this.routingService.urlWithoutHashtag;
          this.structure[this.index]['child'][i]['expansion_start_opened'] = 1;
          this.structure[this.index]['child'][i]['expansionActiveNow'] = true;
          this.location.replaceState(url + '#' + this.structure[this.index]['child'][i]['id_functional_area']);
        } else {
          this.structure[this.index]['child'][i]['expansion_start_opened'] = 0;
          this.structure[this.index]['child'][i]['expansionActiveNow'] = false;
        }
      }
    //}
  }

  setClosed(index: number) {
    for (const i in this.structure[this.index]['child']) {
      if(index == +i) {
        this.structure[this.index]['child'][i]['expansion_start_opened'] = 0;
        this.structure[this.index]['child'][i]['expansionActiveNow'] = false;

        if(this.structure[this.index]['child'][i]['monitoreo'] != 0) {
          
          /*this.endPointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {
          });*/
        }
      }
    }
  }

  closeAllExpansions() {
    for (const i in this.structure[this.index]['child']) {
      this.structure[this.index]['child'][i]['expansion_start_opened'] = 0;
      this.structure[this.index]['child'][i]['expansionActiveNow'] = false;
    }
  }

  iconClick(id, event) {
    this.structure[this.index]['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
    if(this.idTable == null && this.idRow == null) {
      this.functionsControllerService.getFunction(this.structure[this.index]['child'][id['key']]);
    } else {
      this.genericService.tablesActualRows.push({"id_functional_area": this.idTable, "rowID": this.idRow});
      this.functionsControllerService.getFunction(this.structure[this.index]['child'][id['key']], this.genericService.tableData[this.idTable]['data'][this.idRow], this.idTable)
    }
  }
}
