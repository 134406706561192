import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {AuthService} from "../../../services/auth/auth.service";
import {MatTableDataSource} from "@angular/material/table";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {map, startWith} from "rxjs/operators";
import {Observable} from "rxjs";
import {EndpointService} from "../../../services/generic/endpoint.service";
import {MessageDialogComponent} from "../../../utils/message-dialog/message-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'journey-ego-pop',
  templateUrl: './journey-ego-pop.component.html',
  styleUrls: ['./journey-ego-pop.component.css']
})

export class JourneyEgoPopComponent implements OnInit {

  plantillaGroup: UntypedFormGroup;
  filteredOptionsPlant: Observable<any[]>;

  campanaGroup: UntypedFormGroup;
  filteredOptionsCamp: Observable<any[]>;

  plantillasArray = new Array();
  campanaArray = new Array();

  public dataSource: MatTableDataSource<any>;

  public inputFilter: string;

  public idJourney;

  public active: boolean;
  public name: string;
  public actualPlan;
  public campana: number;
  public deletePlan;

  public lastActive: boolean;
  public lastName: string;
  public lastPlant;
  public lastCampana: number;

  displayedColumns: string[] = ['lopd', 'name', 'status'];

  constructor(public dialogRef: MatDialogRef<JourneyEgoPopComponent>,
              private _formBuilder: UntypedFormBuilder,
              public dialog: MatDialog,
              public endpointService: EndpointService,
              private snackBar:MatSnackBar,
              private authService:AuthService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit(): void {

    this.idJourney = this.data.id;
    this.deletePlan = new Array();

    this.endpointService.getJourneyPopupInfo().subscribe(data => {
      this.plantillasArray = data['response'].plantillas;
      this.campanaArray = data['response'].campanas;
    });

    this.active = false;
    this.name = null;
    this.actualPlan = new Array();
    this.campana = null;

    this.lastPlant = new Array();

    if(this.idJourney == null){
      this.lastActive = this.active;
      this.lastName = this.name;
      this.lastCampana = this.campana;
    }else{
      this.endpointService.getJourney(this.idJourney).subscribe( data => {
        this.active = data['response'].journey[0].activo;
        this.name = data['response'].journey[0].nombre;
        this.actualPlan = data['response'].plantillas;

        console.log(this.actualPlan);
        this.dataSource = new MatTableDataSource(this.actualPlan);
        this.campana = data['response'].journey[0].id_campanya;

        this.lastActive = this.active;
        this.lastName = this.name;
        for(let x = 0; x < this.actualPlan.length; x++){

          if(this.actualPlan[x].estado == 1) this.actualPlan[x].estado = true;
          else this.actualPlan[x].estado = false;

          this.lastPlant.push({id: this.actualPlan[x].id, nombre: this.actualPlan[x].nombre, estado: this.actualPlan[x].estado});
        }
        this.lastCampana = this.campana;
      });
    }

    this.plantillaGroup = this._formBuilder.group({
      plantilla: ''
    });

    this.campanaGroup = this._formBuilder.group({
      campana: ''
    });

    //console.log("Mi id: " + this.idJourney);

  }

  eliminarPlantilla(id: number){

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        message: 'CONFIRMA QUE QUIERES REALIZAR ESTA ACCIÓN',
        labelButton1: 'CANCELAR',
        labelButton2: 'CONFIRMAR'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === 2) {

        if (this.actualPlan[id].save == 1) this.deletePlan.push(this.actualPlan[id]);

        this.actualPlan.splice(id, 1);
        this.dataSource = new MatTableDataSource(this.actualPlan);
        console.log(this.actualPlan);
        console.log(this.dataSource);
        console.log(this.deletePlan);
      }
    });
  }

  displayFnCamp(object?: any): string {

    if(object) this.campana = object.id;

    this.filteredOptionsCamp = this.campanaGroup.get('campana').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre),
        map(name => name ? this._filterCamp(name) : this.campanaArray.slice()),
      );

    return object && object.nombre ? object.nombre : undefined;
  }

  private _filterCamp(name: string): any[] {
    const filterValue = name.toLowerCase();

    const softFilterd = this.campanaArray.filter(option => option.nombre.toLowerCase().includes(filterValue));

    return softFilterd;
  }

  displayFnPlant(object?: any): string {

    if(object) this.getPlantilla(object);

    this.filteredOptionsPlant = this.plantillaGroup.get('plantilla').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre),
        map(name => name ? this._filterSoft(name) : this.plantillasArray.slice()),
      );

    return object && object.nombre ? object.nombre : undefined;
  }

  private _filterSoft(name: string): any[] {
    const filterValue = name.toLowerCase();

    const softFilterd = this.plantillasArray.filter(option => option.nombre.toLowerCase().includes(filterValue));

    return softFilterd;
  }

  getPlantilla(select: any){
    //console.log(select.id + " " + select.nombre + " " + select.estado);
    let save = 0;

    if(this.deletePlan.find(p => p.id == select.id) != undefined){
      this.deletePlan.splice(this.deletePlan.indexOf(this.deletePlan.find(p => p.id == select.id)), 1);
      save = 1;
    }

    if(this.actualPlan.find(p => p.id == select.id) == undefined){
      this.actualPlan.push({id: select.id, nombre: select.nombre, estado: select.estado, save: save});
      this.dataSource = new MatTableDataSource(this.actualPlan);
    }

    console.log(this.actualPlan);
    console.log(this.dataSource);

    this.plantillaGroup.controls['plantilla'].setValue('');
  }

  guardar(){

    let text;

    if(this.idJourney != null) text = "Se aplicara los cambios al Journey. ¿Está seguro?";
    else text = "Se creará un nuevo Journey. ¿Está seguro?";

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        message: text,
        labelButton1: 'No',
        labelButton2: 'Sí'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === 2) {

        this.endpointService.editJourney(this.actualPlan, this.idJourney, this.active, this.name, this.campana, this.authService.userId, this.deletePlan).subscribe( data => {
          let text;
          this.lastPlant = new Array();

          if(this.idJourney == null) text = "Se ha creado un nuevo Journey";
          else text = "Se ha editado la configuración del Journey";

          this.snackBar.open(text, 'X', {
            duration: 4000, panelClass: ['green-snackbar']
          });

          this.idJourney = data['response'];

          this.deletePlan = new Array();
          for(let i = 0; i < this.actualPlan.length; i++){
            this.actualPlan[i].save = 1;
            this.lastPlant.push({id: this.actualPlan[i].id, nombre: this.actualPlan[i].nombre, estado: this.actualPlan[i].estado});
          }

          console.log(this.actualPlan);
          console.log(this.lastPlant);

        });
      }
    });

  }

  cancelar(){

    if(this.onChanges()){

      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: 'auto',
        height: 'auto',
        data: {
          message: 'Se ha detectado cambios en el Journey. Los datos del Journey no se guardaran. ¿Estás seguro?',
          labelButton1: 'No',
          labelButton2: 'Sí'
        }
      });

      dialogRef.afterClosed().subscribe(data => {
        if (data === 2) {
          this.dialogRef.close();
        }
      });

    }else{
      this.dialogRef.close();
    }

  }

  arrayEqual(a, b) : boolean{

    let bool = true;
    let x = 0;

    if(a.length != b.length) bool = false;

    console.log(x);
    console.log(a.length);

    while(x < a.length && bool){

      let index = b.findIndex((element, index) =>{
        if(element.estado === a[x].estado && element.nombre === a[x].nombre && element.id === a[x].id) return true;
      });

      console.log("index");
      console.log(index);

      if(index != -1) x++;
      else bool = false;
    }

    console.log(a);
    console.log(b);
    console.log(bool);

    return bool;
  }

  onChanges():boolean{
    return this.active != this.lastActive || this.name != this.lastName || this.campana != this.lastCampana ||
           this.deletePlan.length > 0 || !this.arrayEqual(this.actualPlan, this.lastPlant);
  }

  getNameCampana(id){
    //console.log(id);
    console.log(this.campanaArray.find(p => p.id == id).nombre);
    return this.campanaArray.find(p => p.id == id).nombre;
  }

}
