import { Component, Input, OnInit } from '@angular/core';
import { ConditionsClientService } from 'app/services-client/conditions-client.service';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  @Input() structure = null;
  @Input() index: number;

  loaded = false;

  constructor(public genericService: GenericService, public conditionsClientService: ConditionsClientService) { }
  ngOnInit(): void {
    this.loaded = true;
  }

  public startsWithHashtag = (str: string) => {
    return str.startsWith('#');
  }
}
