import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'app/services/auth/auth.service';
import { EndpointService } from 'app/services/generic/endpoint.service';
import { FunctionsService } from 'app/services/generic/functions.service';


@Component({
  selector: 'app-redsys-custom',
  templateUrl: './redsys-custom.component.html',
  styleUrls: ['./redsys-custom.component.sass']
})

export class RedsysCustomComponent implements OnInit {

  constructor(private endpointService: EndpointService,
    private authService: AuthService,
    public sanitizer: DomSanitizer,
    private functionsService: FunctionsService
  ) {}

  @Input() structure = null;
  @Input() index: number;

  public paramsRedsys = null;
  public firmRedsys = null;

  ngOnInit(): void {
    let idPayment = this.functionsService.loadPaymentData(this.structure[this.index]);
    this.endpointService.payGeneric(idPayment, 4).subscribe((data) => {
      this.firmRedsys = data['response']['firm'];
      this.paramsRedsys = data['response']['params'];
      this.redirectToRedsys();
    });
    
  }

  redirectToRedsys() {
    const redsysUrl = 'https://sis-t.redsys.es:25443/sis/realizarPago';

    const params = {
      Ds_MerchantParameters: this.paramsRedsys,
      Ds_Signature: this.firmRedsys,
      Ds_SignatureVersion: "HMAC_SHA256_V1"
    }
  
    // Crea un formulario oculto
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = redsysUrl;

    // Agrega los campos ocultos al formulario
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = params[key];
        form.appendChild(input);
      }
    }
    document.body.appendChild(form);
    form.submit();
  }

}