import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Location} from "@angular/common";
import { ActivitiesService } from '../activities/activities.service';
import { MatDialog } from '@angular/material/dialog';
import { Subject, EMPTY } from "rxjs";
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  public routeParam = null; // Si no es null hay que filtrar para mostrar un elemento en específico (ej: user-info)
  public previousModule = null;
  public url = null;
  public urlHashtag = null;
  public urlWithoutHashtag = null;
  public urlWithoutHashtagClean = null;
  public moduleId;
  public loadedBreadcrumbs = false;
  public showFAInfo = false;

  public shouldOpenChat: boolean;
  public shouldOpenHelp: boolean;
  public shouldOpenExternalWindow: boolean; 
  public shouldOpenSearch: boolean;
  public shouldOpenMenu: boolean;
  public openButtonAutoPlantilla = new Subject<void>();
  public openButton$ = this.openButtonAutoPlantilla.asObservable();

  public paramsExternalWindow = false;
  public externalWindowStructure = [];
  public elementsThatAffectOthersPrevExternalWindow = null;
  public affectedElementsPrevExternalWindow = null;
  public selectOptionsPrevExternalWindow = null;
  public tableDataPrevExternalWindow = null;
  public currentInitialAreaPrevExternalWindow = null;
  public readModePrevExternalWindow = null;
  public parentStructurePrevExternalWindow = null;
  public initialRoute = "inicio";

  public redirectionURL = null;
  public redirectionJoinGroup = false;
  public shouldRegister = false;


  constructor(public router: Router,
              private location: Location,
              private activitiesService: ActivitiesService,
              private authService: AuthService,
              public dialog: MatDialog,) { }

  go(route) {
    this.closeOtherComponents()
    let aux = route.split('#')
    if(aux.length > 1) {
      this.router.navigate(['/' + aux[0]], {fragment: aux[1]});
      console.log("GO ROUTING 00", route, '/' + aux[0], aux[1]);
    } else {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([aux[0]], {skipLocationChange: true});
      console.log("GO ROUTING 11", route, aux[0]);
    }
  }

  reloadPage(route) {
    this.closeOtherComponents();
    route =this.location.path(true)
    this.prepareRouting();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl(route, {skipLocationChange: true});
    this.activitiesService.clearCustomEventsGeneral();
  }

  closeOtherComponents() {
    this.authService.externalWindowStructure = null;
    this.externalWindowStructure = [];
    this.paramsExternalWindow = false;
    this.elementsThatAffectOthersPrevExternalWindow = null;
    this.affectedElementsPrevExternalWindow = null;
    this.selectOptionsPrevExternalWindow = null;
    this.tableDataPrevExternalWindow = null;
    this.currentInitialAreaPrevExternalWindow = null;
    this.readModePrevExternalWindow = null;
    this.parentStructurePrevExternalWindow = null;
    this.showFAInfo = false;
    
    if(this.shouldOpenChat) {
      this.shouldOpenChat = false;
    }
    if(this.shouldOpenHelp) {
      this.shouldOpenHelp = false;
    }
    if(this.shouldOpenExternalWindow) {
      this.shouldOpenExternalWindow = false;
    }
    if(this.shouldOpenSearch) {
      this.shouldOpenSearch = false;
    }
    if(this.shouldOpenMenu) {
      this.shouldOpenMenu = false;
    }
    this.dialog.closeAll();

  }

  resetRouteParam() {
    this.routeParam = null;
  }

  prepareRouting() {
    if(this.router.url.includes('#')) {
      let hashTagIndex = this.router.url.indexOf('#');
      let hashtag = this.router.url.slice(hashTagIndex+1);
      this.urlHashtag = hashtag;
      this.urlWithoutHashtag = this.router.url.slice(0,hashTagIndex);
    } else {
      this.urlHashtag = null;
      this.urlWithoutHashtag = this.router.url;
    }
    this.getCleanUrl();

  }

  changeUrl(url) {
    this.location.go(url)
    this.urlWithoutHashtag = url;
    this.getCleanUrl();
  }

  getCleanUrl() {
    let urlWithoutHashtagClean = this.urlWithoutHashtag.split('/');
    this.urlWithoutHashtagClean = urlWithoutHashtagClean[urlWithoutHashtagClean.length-1];
  }
}
