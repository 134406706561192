import {Component, EventEmitter, Inject, Input, OnInit, Output} from "@angular/core";
import { EndpointService } from "../../services/generic/endpoint.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {AuthService} from "../../services/auth/auth.service";
import {MatTableDataSource} from "@angular/material/table";
import {PeriodicElement} from "../bill.component";

@Component({
  selector: 'add-movin-coin',
  templateUrl: './add-movin-coin.component.html',
  styleUrls: ['./add-movin-coin.component.scss']
})

export class AddMovinCoinComponent implements OnInit {

  public inmoNumber: number;
  public minInmoNumber: number;
  public price: number;
  public iva: number;
  public dto: number;
  public finalPrice: number;
  public finalPriceIVA: number;
  public idPlan: number;
  public planesArray = new Array();

  public headMovins = ['nmovin', 'price', 'iva', 'dto'];
  public dataSource  = new MatTableDataSource<PeriodicElement>(this.planesArray);

  @Input() type: number;
  @Input() minMovins: number = 0;
  @Output() sendEvent = new EventEmitter();

  public n: any;
  public totalMinSubscription: any;

  public payOnce: boolean;

  constructor(
    public endpointService: EndpointService,
    private dialogRef: MatDialogRef<AddMovinCoinComponent>,
    public snackBar: MatSnackBar,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any)
  {

  }


  ngOnInit(): void {

    this.endpointService.companyHaveSubscription(this.authService.getIdCompany(), this.authService.getIdCompanyType(), this.authService.adminArea).subscribe(data => {

      this.planesArray = data['response']['movinsPlans'];
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.planesArray);

      this.n =  data['response']['actualSubscription']['num_buy'];
      this.totalMinSubscription = data['response']['totalMinSubscription']['priceTotalSum'];

      this.n == null ? this.payOnce = true : this.payOnce = false;

      if (parseFloat(this.n) > parseFloat(this.totalMinSubscription)) {
        this.inmoNumber = this.n;
        this.minInmoNumber = this.n;
      } else {
        this.inmoNumber = this.totalMinSubscription;
        this.minInmoNumber = this.totalMinSubscription;
      }

      if (this.minMovins && this.minMovins > 0) {
        this.inmoNumber = this.minMovins;
        this.minInmoNumber = this.minMovins;
        this.totalMinSubscription = this.minMovins;
        this.payOnce = true;
        this.calcular(this.minMovins);
      }

    });
  }

  calcular(n){
    let trobat = false;
    let i = 0;
    let numero = n;

    while(!trobat && i < this.planesArray.length){
      if(n <= this.planesArray[i].num_max){
        trobat = true;
      }else{
        i++;
      }
    }
    this.price = this.planesArray[0].precio * numero;
    this.price = 1 * numero;// Los MOVINS ya no tienen descuento por volumen
    this.price = +this.price.toFixed(2);

    this.iva = this.price * 1.21;
    this.iva = +this.iva.toFixed(2);

    this.dto = this.planesArray[i].dto_aplicado;
    this.dto = 0; // Los MOVINS ya no tienen descuento por volumen

    this.finalPrice = this.planesArray[i].precio * numero;
    this.finalPrice = 1 * numero; // Los MOVINS ya no tienen descuento por volumen
    this.finalPrice = +this.finalPrice.toFixed(2);

    this.finalPriceIVA = this.finalPrice * 1.21;
    this.finalPriceIVA = +this.finalPriceIVA.toFixed(2);

    this.idPlan = this.planesArray[i].id;
    this.idPlan = this.planesArray[8].id; // Los MOVINS ya no tienen descuento por volumen

  }

  buy() {
    if(this.inmoNumber) {
      if (this.type === 1) {
        this.sendEvent.emit({ movin: this.inmoNumber, final: this.finalPrice, plan: this.idPlan, descuento: this.dto, finalIva: this.finalPriceIVA, payOnce: this.payOnce});
      } else {
        this.dialogRef.close({movin: this.inmoNumber, final: this.finalPrice, plan: this.idPlan, descuento: this.dto, finalIva: this.finalPriceIVA, payOnce: this.payOnce});
        // this.dialogRef.close();
      }
    }else{
      this.snackBar.open('Debe indicar el número de Movins que quiere comprar', 'X', {
        duration: 4000, panelClass: ['red-snackbar']
      });
    }
  }

  changeValue() {
    this.payOnce = !this.payOnce;
  }

  cancelar(){
    this.dialogRef.close();
  }

  cancelarPlan() {

    this.endpointService.cancelarPlanMovin(this.authService.getIdCompany(), this.authService.getIdCompanyType()).subscribe(data => {
      this.changeValue();
      this.snackBar.open('Suscripción cancelada :(', 'X', {
        duration: 4000, panelClass: ['red-snackbar']
      });
    });
  }

}
