import {Injectable, OnInit} from '@angular/core';
import {EndpointService} from './generic/endpoint.service';
import {Subject} from 'rxjs';
import {OptionElement, SubjectListManager} from './LoadPredefinedValuesServices';

@Injectable()
export class LoadExternValuesService implements OnInit {

  private loadedLists: any = [];
  private loading: any = [];

  public static getItemFromListById(list: Array<OptionElement>, id: number): string {
    let val = '';
    list.forEach(e => {
      if (e.id === id) {
        val = e.name;
      }
    });
    return val;
  }

  constructor(private endPointService: EndpointService) {
  }

  ngOnInit() {

  }

  public getFieldList(idModulo: number): SubjectListManager {
    let slm: SubjectListManager = null;
    if (this.loadedLists[idModulo]) {
      return this.loadedLists[idModulo];
    }

    slm = new SubjectListManager();

    this.loading[idModulo] = slm;

    this.endPointService.loadExternValuesService(idModulo).subscribe(data => {
      data['response'].forEach(e => {
        slm.list.push(new OptionElement(e.id, e.name));
      });
      slm.list.forEach(element => {
        slm.hash[element.id] = element;
      });
      slm.loaded = true;
      slm.subject.next(slm.list);
      this.loadedLists[idModulo] = slm;
      this.loading[idModulo] = null;
    });

    return slm;
  }
}
