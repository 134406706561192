import { Component, EventEmitter, Input, OnInit, Output, ViewChild, Renderer2, ElementRef, HostListener } from "@angular/core";
import { FormControl } from "@angular/forms";
import { EndpointService } from "app/services/generic/endpoint.service";
import { AuthService } from "app/services/auth/auth.service";

import {
  AngularEditorConfig,
  AngularEditorService,
} from "@kolkov/angular-editor";
import { Observable } from "rxjs/internal/Observable";
import { map, startWith } from "rxjs/internal/operators";

interface Chip {
  id: number;
  label: string;
  nombre_chip: string;
  nombre_grupo: string;
}

@Component({
  selector: "app-chip-creator-custom",
  templateUrl: "./chip-creator-custom.component.html",
  styleUrls: ["./chip-creator-custom.component.scss"],
})
export class ChipCreatorCustomComponent implements OnInit {
  chipLabelControl = new FormControl("", []);
  chipList: Chip[] = [];
  toggled: boolean = false;
  @Input() htmlContent: any = "";
  @Input() label: any = "";
  @Input() showToolBar: any = true;
  @Input() showIcons: any = true;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    showToolbar: this.showToolBar,
    sanitize: false,
    defaultParagraphSeparator: "",
    toolbarPosition: 'bottom',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'heading',
        'fontName'
      ],
      [
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat'
      ]
    ]
  };
  @ViewChild("editor") editor: any;
  public showSelector = false;
  public showingSelector = false;
  filteredOptions: Observable<{ nombre_grupo: string, chips: Chip[] }[]>;
  @Output() newChipEvent = new EventEmitter();
  constructor(
    public endpointService: EndpointService,
    public htmlEditorService: AngularEditorService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.config.showToolbar = this.showToolBar;

    const editorElement = this.elementRef.nativeElement.querySelector('.angular-editor-wrapper');

    this.renderer.listen(editorElement, 'paste', (event) => {
      event.preventDefault();
      const text = (event.originalEvent || event).clipboardData.getData('text/plain');

      this.insertTextAtCursor(text);
    });

    this.endpointService.getChipsInfo(this.authService.getIdCompany()).subscribe({
      next: (data: any) => {
        this.chipList = data.response;
        this.filteredOptions = this.chipLabelControl.valueChanges.pipe(
          startWith(""),
          map((value: any) => {
            const name = typeof value === "string" ? value : value?.label;
            return this._filter(name ? name as string : '');
          })
        );
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  displayFn(chip: Chip): string {
    return chip && chip.label ? chip.label : "";
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
  
    const groupedChips = this.chipList.reduce((acc, chip) => {
      const group = acc.find(g => g.nombre_grupo === chip.nombre_grupo);
      if (group) {
        if (chip.label.toLowerCase().includes(filterValue)) {
          group.chips.push(chip);
        }
      } else {
        acc.push({
          nombre_grupo: chip.nombre_grupo,
          chips: chip.label.toLowerCase().includes(filterValue) ? [chip] : []
        });
      }
      return acc;
    }, [] as { nombre_grupo: string, chips: Chip[] }[]);
  
    // Elimina los grupos que no tienen chips después de filtrar
    return groupedChips.filter(group => group.chips.length > 0);
  }  

  addChip() {
    this.showingSelector = true;
    this.showSelector = !this.showSelector;
  }

  addText(id: any) {
    let label = "";
    let nombre_chip = "";
    for (let key in this.chipList) {
      if (this.chipList[key].id == id) {
        label = this.chipList[key].label;
        nombre_chip = this.chipList[key].nombre_chip;
        break;
      }
    }
    this.editor.focus();
    this.editor.editorService.restoreSelection();
    this.editor.editorService.insertHtml(
      "<mark contenteditable='false'><span style='border: 1px solid #fff; padding: 3px 0.5em; background-color: rgb(66, 103, 143); color: rgb(255, 255, 255); border-radius: 6px; white-space: nowrap;'><span style='display: none;'>__" + nombre_chip + "__</span>" +
      label + "<span style='display: none;'>--" + nombre_chip + "--</span></span></mark>&nbsp;"
    );
    this.chipLabelControl.setValue("");
    this.showSelector = false;
    this.newChipEvent.emit(this.htmlContent);
  }

  sendHtmlCode(event) {
    this.newChipEvent.emit(event);
  }

  handleSelection(event) {
    let emoji = "" + event.char;
    console.log(event.char);
    this.editor.focus();
    this.editor.editorService.restoreSelection();
    this.editor.editorService.insertHtml(emoji);
    this.newChipEvent.emit(this.htmlContent);
  }

  insertTextAtCursor(text: string) {
    const editor = this.editor;
    const doc = editor.doc;

    const sel = doc.getSelection();
    const range = sel.getRangeAt(0);

    range.deleteContents();

    const plainText = this.getPlainText(text);

    const textNode = doc.createTextNode(plainText);
    range.insertNode(textNode);

    range.setStartAfter(textNode);
    range.setEndAfter(textNode);

    sel.removeAllRanges();
    sel.addRange(range);
  }

  getPlainText(html: string): string {
    const cleanedHtml = html.replace(/(style|class)="[^"]*"/gi, '');
    return cleanedHtml;
  }

  onPaste(event: Event): void {
    event.preventDefault();

    const clipboardData = (event as ClipboardEvent).clipboardData || window['clipboardData'];
    const plainText = clipboardData.getData('text/plain');

    this.insertTextAtCursorOnPaste(plainText);
  }

  insertTextAtCursorOnPaste(text: any): void {
    const editor = this.editor.textArea.nativeElement;

    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const startOffset = range.startOffset;
    const endOffset = range.endOffset;

    if (startOffset && endOffset) {
      if (editor.value) {
        const textBefore = editor.value.substring(0, startOffset);
        const textAfter = editor.value.substring(endOffset);
        editor.value = textBefore + text + textAfter;
      }

      const newStartOffset = startOffset + text.length;
      if (editor.firstChild && editor.firstChild.textContent && editor.firstChild.textContent.length > newStartOffset) {
        range.setStart(editor.firstChild, newStartOffset);
        range.setEnd(editor.firstChild, newStartOffset);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }

    editor.dispatchEvent(new Event('input'));
  }

  @HostListener('document:click', ['$event'])
  detectarClickEnDocumento(event: MouseEvent) {
    if (this.showSelector && !this.isChipSelector(event)) {
      if (this.showingSelector) this.showingSelector = false;
      else this.showSelector = false;
    }
  }

  isChipSelector(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    return inputElement.placeholder === 'Buscar chip';
  }
}