import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {fromEvent, of} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from "rxjs/operators";
import {EndpointService} from "../../../services/generic/endpoint.service";
import {TranslateService} from "@ngx-translate/core";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {UntypedFormControl} from "@angular/forms";
import {GenericService} from "../../../services/generic/generic.service";
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('locationSearchInput', { static: true }) locationSearchInput: ElementRef;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  @Input() structure: any;
  @Input() index: number;
  genericForm: any;

  public fullQuery = false;
  public inputValueLabel: string = null;
  public originalValue;
  public label = "Población";

  public founded = false;
  public idFaToEdit = {};

  apiResponse: any;
  isSearching: boolean;
  moreCharacters: boolean;
  numCharactersSearch = 4;

  public previousSearch = "";

  public locationControl = new UntypedFormControl();

  private updateFormFieldSubscription;
  private panelClosingActionsSubscription;

  constructor(private endpointService: EndpointService,
              private translate: TranslateService,
              private genericService: GenericService,
              private functionsControllerService: FunctionsControllerService) {
    this.isSearching = false;
    this.moreCharacters = false;
    this.apiResponse = [];
  }

  ngOnInit(): void {
    if(this.structure[this.index]['tmp_value']) {
      if(this.structure[this.index]['tmp_value']['fullname']) this.inputValueLabel = this.structure[this.index]['tmp_value']['fullname'];
      else this.inputValueLabel = this.structure[this.index]['tmp_value'];
    }
    if(this.structure[this.index]['label']) this.label = this.structure[this.index]['label'];
    this.originalValue = this.structure[this.index]['tmp_value'] ? JSON.parse(JSON.stringify(this.structure[this.index]['tmp_value'])) : null
    this.genericForm = this.structure[this.index]["form"];
    if(this.inputValueLabel) {
      this.genericForm.patchValue({
        [this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']]: this.inputValueLabel,
      });
    }

    fromEvent(this.locationSearchInput.nativeElement, '' + 'keyup').pipe(
      // Si la anterior llamada es igual a la actual
      distinctUntilChanged(),

      // Obtener el valor
      map((event: any) => {
        if(event.target.value != this.previousSearch) {
          this.previousSearch = event.target.value;
          this.isSearching = true;
          this.moreCharacters = !(event.target.value.length >= this.numCharactersSearch);
          if(event.target.value == "") {
            this.isSearching = false;
            this.moreCharacters = false;
          }
        }
        return event.target.value;
      }),

      // Mayor que 3 caracteres
      filter(res => res.length >= this.numCharactersSearch),

      // Milisegundos entre pulsaciones
      debounceTime(500),

      // Cancelar la llamada anterior si no se ha completado
      switchMap((text) => {
        if(this.isSearching) {
          return this.searchGetCall(text);
        }
      })
      // subscription for response
    ).subscribe((res) => {
      if(!this.moreCharacters) {
        this.apiResponse = res;
        this.isSearching = false;
      }
    });

    this.updateFormFieldSubscription = this.genericService.updateFormFields.subscribe((change) => {
      if(change) this.originalValue = JSON.parse(JSON.stringify(this.inputValueLabel))
    })
  }

  ngOnDestroy() {
    this.updateFormFieldSubscription.unsubscribe()
    this.panelClosingActionsSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.panelClosingActionsSubscription = this.autocomplete.panelClosingActions.subscribe(e => {
      if (!(e && e.source)) {

        const rapi = this.apiResponse;
        if (this.isSearching || this.moreCharacters || rapi == null || rapi['errorCode'] == -1 || (Array.isArray(rapi['response']) && rapi['response'].length <= 0)) {
          this.inputValueLabel = null;
          this.structure[this.index]['tmp_value'] = null;
          this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], this.structure[this.index]['id_functional_parent_initial'], this.structure[this.index]['id_functional_parent'], this.structure[this.index]['id_functional_area'], "location_", this.originalValue, null, this.originalValue, null, this.structure[this.index]['label']);
          this.genericForm.patchValue({
            [this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']]: null
          });
        } else {
          this.inputValueLabel = this.deleteCountryCode(rapi['response'][0].locations[0].fullname);
          this.structure[this.index]['tmp_value'] = rapi['response'][0].locations[0];
          this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], this.structure[this.index]['id_functional_parent_initial'], this.structure[this.index]['id_functional_parent'], this.structure[this.index]['id_functional_area'], "location_", this.originalValue, rapi['response'][0].locations[0]['fullname'], this.originalValue, rapi['response'][0].locations[0]['fullname'], this.structure[this.index]['label']);
          this.genericForm.patchValue({
            [this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']]: rapi['response'][0].locations[0]['fullname'],
          });
        }
        this.autocomplete.closePanel()
      }
    });
  }

  searchGetCall(term: string) {
    if (term === '') return of([]);
    const arrayTerm = term.split(' ');
    return this.endpointService.getLocations(arrayTerm, this.fullQuery)
  }

  optionSelected(event) {
    // Si el string está vacío, poner el valor como null paran no guardar strings vacios en la base de datos.
    // Se podría hacer esto desde la query. Mirarlo más adelante.

    this.inputValueLabel = this.deleteCountryCode(event.option.value.fullname);
    this.structure[this.index]['tmp_value'] = event.option.value;
    this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], this.structure[this.index]['id_functional_parent_initial'], this.structure[this.index]['id_functional_parent'], this.structure[this.index]['id_functional_area'], "location_", this.originalValue, event.option.value.fullname, this.originalValue, event.option.value.fullname, this.structure[this.index]['label']);
    this.genericForm.patchValue({
      [this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']]: event.option.value.fullname,
    });
    
    /*let actualNgForId = this.functionsService.getNgForId(this.structure[this.index]);
    let bd_field = "codigo_postal";
    this.idFaToEdit = this.genericService.findFAWithTableField(this.structure[this.index]['id_db'], this.structure[this.index]['bd_table'], bd_field, this.structure[this.index]["id_functional_parent_initial_dsb"], +actualNgForId);
    if(this.idFaToEdit && this.idFaToEdit["form"] && this.idFaToEdit["form"]["controls"] && this.idFaToEdit["form"]["controls"][this.idFaToEdit["id_functional_area"] + "-" + bd_field]) {
      let posalCode = "00000";
      // Si tenemos los codigos postales, podemos añadirlos. Sino tendremos que cogerlos de Google Maps
      //this.idFaToEdit["form"]["controls"][this.idFaToEdit["id_functional_area"] + "-" + bd_field].setValue(posalCode);
    }*/
  }

  deleteCountryCode(s: string){
    return s.substring(s.lastIndexOf(","), -s.lastIndexOf(","))
  }

  clear() {
    this.inputValueLabel = null;
    this.structure[this.index]['tmp_value'] = null;
    this.functionsControllerService.updateFormHistory(this.structure[this.index], this.structure[this.index]['id_functional_parent_initial_dsb'], this.structure[this.index]['id_functional_parent_initial'], this.structure[this.index]['id_functional_parent'], this.structure[this.index]['id_functional_area'], "location_", this.originalValue, null, this.originalValue, null, this.structure[this.index]['label']);
    this.genericForm.patchValue({
      [this.structure[this.index]['id_functional_area'] + '-' + this.structure[this.index]['bd_field']]: null
    });
  }
}
