import { Component,Inject, OnInit, ViewChild } from '@angular/core';
import {StripePaymentComponent} from "../../../stripe/stripe-payment/stripe-payment.component";
import {EndpointService} from "../../../services/generic/endpoint.service";
import {AuthService} from "../../../services/auth/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {GenericService} from "../../../services/generic/generic.service";

@Component({
  selector: 'app-buy-movins-custom',
  templateUrl: './buy-movins-custom.component.html',
  styleUrls: ['./buy-movins-custom.component.css']
})
export class BuyMovinsCustomComponent implements OnInit {

  @ViewChild('stripePayment') stripePayment: StripePaymentComponent;

  public totalCarro: number;
  public movins: number; // numero de movins que se disponen
  public numMovins;
  public precioFinal;
  public cartArray = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { movins },
    public endpointService: EndpointService,
    private dialogRef: MatDialogRef<BuyMovinsCustomComponent>,
    public dialog: MatDialog,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    public genericService: GenericService
  ) { }

  ngOnInit(): void {
    this.movins = parseInt(this.data.movins, 10);
    this.totalCarro = 0;
    this.cargaCarrito();
  }

  public openStripe($event: any): void {
    let suscripcion = 0;
    this.numMovins = $event.movin;
    this.precioFinal = $event.finalIva;
    this.stripePayment.pay(this.precioFinal, this.numMovins, suscripcion);
  }

  save(n: object) {
    if (n['error'] === 0) {
      this.endpointService.insertPlanMovin(this.authService.getIdCompany(), this.authService.getIdCompanyType(), this.authService.userId, this.numMovins, this.precioFinal, n['paid']).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.movins += this.numMovins;
          if (this.movins >= this.totalCarro) {
            this.movins = this.movins - this.totalCarro;
            this.endpointService.addProducts(this.authService.getIdCompany(), this.authService.getIdCompanyType(), this.authService.userId, this.cartArray, this.totalCarro).subscribe(data => {
              if (data['errorCode'] === 0) {
                this.compraRealizada();
              }
            });
          } else {
            this.snackBar.open('¡Oops! Parece que hubo un error...', 'X', {
              duration: 7000, panelClass: ['green-snackbar']
            });
            this.dialogRef.close(false);
          }
        }
      });
    } else {
      this.snackBar.open('¡Oops! Parece que hubo un error con tu solicitud', 'X', {
        duration: 4000, panelClass: ['red-snackbar']
      });
    }
  }

  public compraRealizada() {
    this.endpointService.deleteElementsCarrito(this.cartArray, this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.cargaCarrito();
        this.snackBar.open('¡Compra realizada con éxito!', 'X', { duration: 7000, panelClass: ['green-snackbar'] });
        // this.dialogRef.close({ movin: this.movins });
        this.genericService.refreshStructure(1);
      } else {
        this.snackBar.open('Ha ocurrido un error', 'X', { duration: 6000, panelClass: ['red-snackbar'] });
      }
    });
  }


  cargaCarrito() {
    this.endpointService.getCarritoItems(this.authService.inmoId, this.authService.getIdCompanyType(), this.authService.userId, this.authService.languageId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.cartArray = data['response'];
        this.cartArray.sort((a,b) => (a.id_product_type > b.id_product_type) ? 1 : ((b.id_product_type > a.id_product_type) ? -1 : 0));
        this.calculaTotal();
      }
    });
  }

  calculaTotal() {
    this.totalCarro = 0;
    this.cartArray.forEach(elem => {
      if (elem.precio) this.totalCarro += parseFloat(elem.precio);
    });

    // Round to 2 decimals
    this.totalCarro = Number(this.totalCarro.toFixed(2));
  }



}
