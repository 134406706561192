import {Component, Inject, OnInit} from "@angular/core";
import {EndpointService} from "../../../../services/generic/endpoint.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from "../../../../services/auth/auth.service";

@Component({
  selector: 'icon-screen',
  templateUrl: './icon-screen.component.html',
  styleUrls: ['./icon-screen.component.css']
})
export class IconScreenComponent implements OnInit {

  public arrayTipo = new Array();

  public icon: string;
  public tipo: number;
  public iconColor: string;
  public tooltipe: string;
  public active: boolean;
  public iconArray;

  constructor(public endpointService: EndpointService,
              private dialogRef: MatDialogRef<IconScreenComponent>,
              public snackBar: MatSnackBar,
              private authService: AuthService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {

    this.endpointService.getTypeIcon().subscribe( data =>{

      this.arrayTipo = data['response'];

    });

    this.icon = this.data.icon;
    this.iconColor = this.data.icon_color;
    this.tipo = this.data.icon_type;
    this.tooltipe = this.data.icon_tooltipe;
    this.active = this.data.icon_active;

  }

  public onOk(){

    if(this.icon){
      this.iconArray = new Array();
      this.iconArray.push({icon: this.icon, color: this.iconColor, type: this.tipo, tooltipe: this.tooltipe, active: this.active});
      this.dialogRef.close(this.iconArray);
    }else{
      this.snackBar.open('Falta nombre del icono', 'X', {
        duration: 4000, panelClass: ['red-snackbar']
      });
    }
  }


  public onCancel(): void {
    this.dialogRef.close();
  }

}
