import {Component, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import {EndpointService} from "../services/generic/endpoint.service";
import {SelectionModel} from "@angular/cdk/collections";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {PortalWarningDialogComponent} from "./subcomponents/portal-warning-dialog/portal-warning-dialog.component";
import {AuthService} from "../services/auth/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {Values} from "../../values/values";
import { MessageDialogComponent } from '../utils/message-dialog/message-dialog.component';

//import { parseCommandLine } from 'typescript';

import { FunctionsService } from 'app/services/generic/functions.service';
import { tr } from 'date-fns/locale';

@Component({
  selector: 'app-search-portals',
  templateUrl: './search-portals.component.html',
  styleUrls: ['./search-portals.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ])
  ]
})
export class SearchPortalsComponent implements OnInit {

  public dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public defaultImg = Values.NO_IMAGE_URL;

  public inmoId: number;
  public portales: any;
  public portalConfig: any;
  public portalHasBeenSelected: boolean;
  public selectedPortal: any;
  loadedP = false;

  // Contadores
  public maxPublicados: number;
  public maxPrecios: number;
  public maxUbicaciones: number;
  public numPublicados: number;
  public numDestacados: number;
  public numPrecios: number;
  public numUbicaciones: number;

//  public displayedColumns: string[] = ['select', 'logos', 'colaboration', 'referencia_inmo', 'Publicado', 'Destacado', 'Mostrar precio', 'Mostrar ubicacion'];
  public displayedColumns: string[] = [];
  public expandedElement: any | null;
  public inputFilter: string;

  public loaded: boolean;
  public loadedPublish = true;

  public selection = new SelectionModel<any>(true, []);
  public selectedAction: string;


  constructor(private endpointService: EndpointService,
              public authService: AuthService,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              public functionsService: FunctionsService) {}

  ngOnInit(): void {
    this.portalHasBeenSelected = false;
    this.inmoId = this.authService.empresaId;

    if (this.inmoId) {
      this.loadPortales(this.inmoId);
    }
  }

  loadPaginator() {
    if(!this.loadedP) {
      setTimeout(() => {
        this.dataSource.paginator = this.paginator;
      });
      this.loadedP = true;
    }
    return true;
  }

  public loadPortales(idInmo: number): void {
    this.endpointService.getPortalsByInmoId(idInmo).subscribe(data => {
      this.loaded = true;
      if (data['errorCode'] == 0) {
        this.portales = data['response'];
        for(let t in this.portales) {
          if(this.portales[t]['id_portal'] == this.functionsService.portalConfigured) {
            this.selectedPortal = this.portales[t];
            this.onChangePortal();
            break;
          }
        }
      }
    });
  }

  public onChangePortal(): void {
    this.portalHasBeenSelected = true;
    this.loadViviendas(this.inmoId, this.selectedPortal.id, this.selectedPortal);
    this.loadPortalInfo(this.inmoId, this.selectedPortal.id);
    this.functionsService.portalConfigured = 0;
  }

  public loadViviendas(idInmo: number, idPortal: number, infoPortal): void {
    console.log('ENTRO CON VALORES: ID INMO', idInmo, 'y ID PORTAL', idPortal);
    this.loaded = false;
    this.inputFilter = '';

    this.endpointService.getViviendasInmoByPortalId(idInmo, idPortal, this.authService.userId).subscribe(data => {
      if (data['errorCode'] == 0) {
        this.displayedColumns = ['select'];
        if (data['response'].collaborationsBuyed) {
          this.displayedColumns.push('logos');
          this.displayedColumns.push('colaboration');
        }
        this.displayedColumns.push('referencia_inmo');
        this.displayedColumns.push('Publicado');
        if (infoPortal.allow_mostrar_destacados) this.displayedColumns.push('Destacado');
        if (infoPortal.allow_mostrar_precio) this.displayedColumns.push('Mostrar precio');
        if (infoPortal.allow_mostrar_ubicacion) this.displayedColumns.push('Mostrar ubicacion');

        this.numPublicados = data['response'].contadores.numPublicados;
        this.numDestacados = data['response'].contadores.numDestacados;
        this.numPrecios = data['response'].contadores.numPrecios;
        this.numUbicaciones = data['response'].contadores.numUbicaciones;

        this.dataSource = new MatTableDataSource<any>(data['response'].viviendas);
        console.log('DATASOURCE', this.dataSource);
        this.loadedP = false;
        this.applyFilter(this.inputFilter);
        this.loaded = true;
      }
    });
  }

  public applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public loadPortalInfo(idInmo: number, idPortal: number): void {
    this.endpointService.getInmoPortal(idInmo, idPortal).subscribe(data => {
      if (data['errorCode'] == 0) {
        this.portalConfig = data['response'][0];
        this.maxPublicados = this.portalConfig.limite_publicaciones;
        this.maxPrecios = this.portalConfig.limite_precios;
        this.maxUbicaciones = this.portalConfig.limite_ubicaciones;
      }
    });
  }

  // Checkbox
  public isAllSelected(): boolean {
    return this.dataSource && this.selection.selected.length === this.dataSource.data.length;
  }

  public masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  public checkboxLabel(row?: any): string {
    if(!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }  else {
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }
  }

  public updateElement(element: any, action: string): void {
    console.log('entro al update', element);    
    const properties = [{id_vivienda: element.id_vivienda, publicado: element.publicado, destacado: element.destacado, precio_mostrado: element.precio_mostrado, mostrar_ubicacion: element.mostrar_ubicacion }];
    this.endpointService.publicar_portales_viviendas(this.authService.empresaId, this.selectedPortal.id, properties).subscribe(async data => {
      console.log('ACTION', action);
      switch(action){
        case "publicar":
          if(data['errorCode']===0){
            if (!element.publicado) {
              this.numPublicados--;
              if (element.destacado) this.numDestacados--;
              if (element.precio_mostrado) this.numPrecios--;
              if (element.mostrar_ubicacion) this.numUbicaciones--;
            } else {
              this.numPublicados++
              if (element.destacado) this.numDestacados++;
              if (element.precio_mostrado) this.numPrecios++;
              if (element.mostrar_ubicacion) this.numUbicaciones++;
            }
          } else {
            console.log(element.publicado, "element.publicado")
            element.publicado = !element.publicado;
            console.log(element.publicado, "element.publicado2")
          }
          break;
        case "destacar":
          if(data['errorCode']===0){
            if (element.destacado) this.numDestacados++;
            else this.numDestacados--;
          } else {
            element.destacado = !element.destacado;
          }
          break;
        case "precio":
          if(data['errorCode']===0){
            if (element.precio_mostrado) this.numPrecios++;
            else this.numPrecios--;
          } else {
            element.precio_mostrado = !element.precio_mostrado;
          }
          break;
        case "ubicacion":
          if(data['errorCode']===0){
            if (element.mostrar_ubicacion) this.numUbicaciones++;
            else this.numUbicaciones--;
          } else {
            element.mostrar_ubicacion = !element.mostrar_ubicacion;
          }
          break;
      }
      if (data['errorCode'] === 0) {
        if(data['errorMessage'] === "OK!" || !data['errorMessage'] || data['errorMessage'] === '') data['errorMessage'] = "Inmueble actualizado";
        this.selection.clear();
        this.snackBar.open(data['errorMessage'], 'X', {
          duration: 6000,
          panelClass: ['lightgreen-snackbar']
        });
      } else {
        this.snackBar.open(data['errorMessage'], 'X', {
          duration: 6000,
          panelClass: ['red-snackbar']
        });
      }
    });
  }

  // Acciones masivas
  public prepareMassiveAction(): void {
    // No caldria: No entrarà mai perquè no s'ha donat valor a l'opció "Ninguna" i el botó "Aplicar" es deshabilita
    if (this.selectedAction !== null) {
      if (this.selection.selected.length > 1) {
        this.openMassiveWarningDialog();
      }
      else {
        this.doMassiveAction();
      }
    }
  }

  private openMassiveWarningDialog(): void {

    const dialogRef = this.dialog.open(PortalWarningDialogComponent, {
      width: 'auto',
      data: {numElementos: this.selection.selected.length},
      autoFocus: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.doMassiveAction();
      }
    });
  }

  public doMassiveAction(): void {
    let filtered_selection = {};
    this.loadedPublish = false;

    for(let key in this.selection.selected ){
      switch(this.selectedAction){
        case 'publicar':
          if(this.selection.selected[key]['publicado'] != 1){
            this.selection.selected[key]['publicado'] = 1;
            filtered_selection[key] = this.selection.selected[key];
          }
          break;
        case 'no_publicar':
          if(this.selection.selected[key]['publicado'] != 0){
            this.selection.selected[key]['publicado'] = 0;
            filtered_selection[key] = this.selection.selected[key];
          }
          break;
        case 'precio':
          if(this.selection.selected[key]['mostrar_precio'] != 1){
            this.selection.selected[key]['mostrar_precio'] = 1;
            filtered_selection[key] = this.selection.selected[key];
          }
          break;
        case 'no_precio':
          if(this.selection.selected[key]['mostrar_precio'] != 0){
            this.selection.selected[key]['mostrar_precio'] = 0;
            filtered_selection[key] = this.selection.selected[key];
          }
          break;
        case 'ubicacion':
          if(this.selection.selected[key]['mostrar_ubicacion'] != 1){
            this.selection.selected[key]['mostrar_ubicacion'] = 1;
            filtered_selection[key] = this.selection.selected[key];
          }
          break;
        case 'no_ubicacion':
          if(this.selection.selected[key]['mostrar_ubicacion'] != 0){
            this.selection.selected[key]['mostrar_ubicacion'] = 0;
            filtered_selection[key] = this.selection.selected[key];
          }
          break;
        case 'destacar':
          if(this.selection.selected[key]['destacado'] != 1){
            this.selection.selected[key]['destacado'] = 1;
            filtered_selection[key] = this.selection.selected[key];
          }
          break;
        case 'no_destacar':
          if(this.selection.selected[key]['destacado'] != 0){
            this.selection.selected[key]['destacado'] = 0;
            filtered_selection[key] = this.selection.selected[key];
          }
          break;
      }
    }

    this.endpointService.publicar_portales_viviendas(this.authService.empresaId, this.selectedPortal.id, filtered_selection, this.selectedAction).subscribe(data => {
      this.loadedPublish = true;
      if (data['errorCode'] === 0) {
        this.selection.clear();
        this.selectedAction = null;
        this.loadViviendas(this.inmoId, this.selectedPortal.id, this.selectedPortal);
        this.snackBar.open('Inmuebles actualizados ','X', {
          duration: 6000,
          panelClass: ['green-snackbar']
        });
      } else {
        this.snackBar.open('Proceso terminado con error ' + data['errorMessage'],'X', {
          duration: 6000,
          panelClass: ['red-snackbar']
        });      
        this.loadViviendas(this.inmoId, this.selectedPortal.id, this.selectedPortal);
      }
    });
  }
}


