 <ng-container *ngIf="loaded && structure[this.index]">

  <div  (click)="genericCreatorService.editOption(structure[index])" fxLayout="row wrap" *ngIf="(search == true || paginatorOptions !== null)" [ngClass]="'bottom-table-generic bottom-table-generic-' + structure[index]['id_functional_area']" ngClass.sm="bottom-table-generic-mb" ngClass.xs="bottom-table-generic-mb">
    <div fxFlex.sm="100" fxFlex.xs="100" *ngIf="search == true" class="bottom-table2" style="display: flex;">
      <div class="generic-buttons-blue-revert massive-action" style="padding-right: 10px; padding-top: 10px;" *ngIf="this.structure[this.index]['type'] == 'checkbox' && this.selection.selected && this.selection.selected.length && this.selection.selected.length > 0">
        <button mat-button>
          <mat-icon *ngIf="!structure[index]['label'] || structure[index]['label'] == ''" style="color: #000;">arrow_right_alt</mat-icon>
          <ng-container *ngIf="structure[index]['label'] && structure[index]['label'] != ''">
            <mat-icon *ngIf="structure[index]['icon'] && structure[index]['icon'] != ''" style="color: #000;">{{structure[index]['icon']}}</mat-icon>
            {{structure[index]['label']}}
          </ng-container>
        </button>
      </div>
      <mat-form-field appearance="outline" [ngClass]="{'value-filter': filter.value !== '', 'input-filter': true}">
        <input #filter placeholder="Buscar" matInput type="text">
        <button matSuffix mat-icon-button aria-label="Filter" matTooltip="Not available">
          <mat-icon style="color: #000;">tune</mat-icon>
        </button>
        <button matSuffix mat-icon-button aria-label="Download" matTooltip="Not available">
          <mat-icon style="color: #000;">download</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <mat-paginator fxFlex.sm="100" fxFlex.xs="100" style="width: 50%;" [pageSizeOptions]="paginatorOptions"></mat-paginator>
  </div>

  <div class="generic-taules" [ngClass]="{'no-click-row': !structure[index]['id_function']}" [ngStyle]="{ 'height': this.getHeightV + 'px', 'max-height': this.structure[this.index]['id_functional_parent_initial_dsb'] > 0 ? this.getHeightTable2V + 'px' : 'calc(100vh - ' + this.getHeightTableV + 'px - 1.2vw)' }" [ngStyle.xs]="{ 'max-height': this.structure[this.index]['id_functional_parent_initial_dsb'] > 0 ? this.getHeightTable2V + 'px' : 'calc(100vh - ' + this.getHeightTableV + 'px - 1.2vw)' }"  [ngStyle.sm]="{ 'max-height': this.structure[this.index]['id_functional_parent_initial_dsb'] > 0 ? this.getHeightTable2V + 'px' : 'calc(100vh - ' + this.getHeightTableV + 'px - 1.2vw)' }">
    <div [ngClass]="'generic-taules-sub generic-taules-sub-' + structure[index]['id_functional_area']" [ngStyle]="{ 'height': 'calc(100% - ' + this.getHeightTableV + 'px - 2.6em - 3px)' }">
      <table [ngClass]="'mat-elevation-z2 mat-elevation-z3-' + structure[index]['id_functional_area']" mat-table [dataSource]="dataSource" matSort multiTemplateDataRows matTableExporter #exporter="matTableExporter">
        <ng-container *ngFor="let key of displayedColumns; let i = index" matColumnDef="{{ key }}" >
          <!-- Checkbox Column -->
          <ng-container *ngIf="key == '0000-selectorCheckbox'">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? toggleAllRows() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"
                            [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
              </mat-checkbox>
            </td>
          </ng-container>
          
          <ng-container *ngIf="key != '0000-selectorCheckbox'">
            <ng-container *ngIf="!aux[i].Sort; else elseBlock">
              <th mat-header-cell *matHeaderCellDef [matTooltip]="aux[i].Tooltipp" (click)="genericCreatorService.editOption(structure[index]['child'][aux[i].Id])" [ngStyle]="structure[index]['child'][aux[i].Id]?.['styleParsed']?.['style']" [ngClass]="{'column-only-icon': (aux[i].icon && (!structure[index]['child'][aux[i].Id]['label'] || structure[index]['child'][aux[i].Id]['label'] == null))}">
                <ng-container *ngIf="structure[index]['child'][aux[i].Id] && !structure[index]['child'][aux[i].Id]['readMode'] && aux[i].icon">
                  <button mat-button style="width: auto;">
                    <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id] && structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                  </button>
                </ng-container>
                <span *ngIf="structure[index]['child'][aux[i].Id] && this.structure[index]['child'][aux[i].Id]['label']" [innerHTML]="this.structure[index]['child'][aux[i].Id]['label'] | safeHtml"></span>
              </th>
            </ng-container>
            <ng-template #elseBlock>
              <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!aux[i].Sort" (click)="genericCreatorService.editOption(structure[index]['child'][aux[i].Id])" [matTooltip]="aux[i].Tooltipp" [ngStyle]="structure[index]['child'][aux[i].Id]?.['styleParsed']?.['style']" [ngClass]="{'column-only-icon': (aux[i].icon && (!structure[index]['child'][aux[i].Id]['label'] || structure[index]['child'][aux[i].Id]['label'] == null))}">
                <ng-container *ngIf="structure[index]['child'][aux[i].Id] && !structure[index]['child'][aux[i].Id]['readMode'] && aux[i].icon">
                  <button mat-button>
                    <i class="{{aux[i].class}}" [ngStyle]="{'width': (structure[index]['child'][aux[i].Id] && structure[index]['child'][aux[i].Id]['label'] && structure[index]['child'][aux[i].Id]['label'] !== null && structure[index]['child'][aux[i].Id]['label'] !== '' ? 'auto' : '100%')}"> {{ aux[i].icon }} </i>
                  </button>
                </ng-container>
                <span *ngIf="structure[index]['child'][aux[i].Id] && this.structure[index]['child'][aux[i].Id]['label']" [innerHTML]="this.structure[index]['child'][aux[i].Id]['label'] | safeHtml"></span>
              </th>
            </ng-template>
            <ng-container *ngIf="structure[index]['child'][aux[i].Id] && structure[index]['child'][aux[i].Id]['bd_field']">
              <td mat-cell *matCellDef="let row">
                <ng-container *ngIf="structure[index]['child'][aux[i].Id]['type'] == 'image'; else notImage">
                  <ng-container *ngIf="structure[index]['child'][aux[i].Id]['id_function']; else notFunctionImage">
                    <img class="{{ structure[index]['child'][aux[i].Id]['class_custom'] }}" [ngStyle]="structure[index]['child'][aux[i].Id]['styleParsed']['style']" [matTooltip]="row[structure[index]['child'][aux[i].Id]['description']]" mat-card-image [src]="row[key]" (error)="row[key] = this.genericService.defaultNoImage;" (load)="this.calculateHeights()"/>
                  </ng-container>
                  <ng-template #notFunctionImage>
                    <img class="{{ structure[index]['child'][aux[i].Id]['class_custom'] }}" [ngStyle]="structure[index]['child'][aux[i].Id]['styleParsed']['style']" [matTooltip]="row[structure[index]['child'][aux[i].Id]['description']]" mat-card-image [src]="row[key]" (error)="row[key] = this.genericService.defaultNoImage;" (load)="this.calculateHeights()"/>
                  </ng-template>
                </ng-container>
                <ng-template #notImage>
                  <ng-container *ngIf="checkArray(row[key])">
                    <div *ngFor="let x of row[key]">{{ x === null ? "N/A" : x }}</div>
                  </ng-container>
                  <ng-container *ngIf="!checkArray(row[key])">
                    <ng-container *ngIf="row[key] == 'isbooleantrue'">
                      <div><span class="material-icons checked-generic">check_circle</span></div>
                    </ng-container>
                    <ng-container *ngIf="row[key] == 'isbooleanfalse'">
                      <div><span class="material-icons denied-generic">cancel</span></div>
                    </ng-container>
                    <ng-container *ngIf="row[key] != 'isbooleantrue' && row[key] != 'isbooleanfalse'">
                      <div class="text-column"><span *ngIf="row[key]" [innerHTML]="row[key] | safeHtml"></span></div>
                    </ng-container>
                  </ng-container>
                </ng-template>
    
              </td>
            </ng-container>
            <ng-container *ngIf="!structure[index]['child'][aux[i].Id] || !structure[index]['child'][aux[i].Id]['bd_field']">
              <td mat-cell *matCellDef="let row; let t = dataIndex">
                <app-generic [structure]="structure[index]['child'][aux[i].Id]"></app-generic>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: sticky"></tr>
  
        <ng-container *ngIf="this.structure[this.index]['type'] != 'checkbox'">
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="genericCreatorService.editOption(structure[index]['child'][aux[i].Id]); $event.stopPropagation()"></tr>
        </ng-container>
        <ng-container *ngIf="this.structure[this.index]['type'] == 'checkbox'">
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </ng-container>

      </table>
    </div>
  </div>
</ng-container>
