import {Component, OnInit, Input} from '@angular/core';
import { GenericService } from 'app/services/generic/generic.service';

//SERVICES
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  public index;

  public formId: string;

  @Input() structure = null;
  @Input() idQuery : number;


  public formAttributes = [];

  constructor(public functionsControllerService: FunctionsControllerService,
              public genericService: GenericService) {}

  ngOnInit(): void {
    this.formId = 'form' + this.structure['id_functional_parent_initial'];
    if(this.structure["stepperId"]) {
      let idFaStepper = this.genericService.findElementWithId(this.structure["stepperId"], false, false, true);
      if(idFaStepper) idFaStepper["formStep"] = this.structure['form'];
    }
  }
}
