import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {ChatService} from "../../services/chat.service";
import {UserBody} from "../../users/objects/user-body";
import {CustomEvent} from "../../activities/objects/custom-event.component";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

export interface ChatGroupUserInfo {
  selected: boolean;
  admin: boolean;
  name: string;
}

@Component({
  selector: 'app-bottom-sheet-add-user-to-group',
  templateUrl: './bottom-sheet-add-user-to-group.component.html',
  styleUrls: ['./bottom-sheet-add-user-to-group.component.css']
})
export class BottomSheetAddUserToGroupComponent implements OnInit {

  public groupId: string;
  public name: string;
  public contacts: UserBody[];
  public selectedUsers: Map<string, ChatGroupUserInfo> = new Map<string, ChatGroupUserInfo>();
  public displayedColumns: string[] = ['photo', 'name', 'add', 'admin'];
  public dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetAddUserToGroupComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              public chatService: ChatService,) {
    this.groupId = data.groupId;
    this.name = data.name;
    this.contacts = data.contacts;
    this.dataSource = new MatTableDataSource(this.contacts);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void { }

  addUser(opType: number) {
    switch (opType) {
      case 1:
        console.log('1');
        break;
      case 2:
        console.log('2');
        console.log(this.selectedUsers);
        this.chatService.addUsersToGroup(this.selectedUsers, this.groupId, this.name);
        break;
    }
    this._bottomSheetRef.dismiss();
  }

  changeStatusUser(id: string, checked: boolean, name: string) {
    if(checked) {
      this.selectedUsers.set(id, {selected: checked, admin: false, name: name});
    }
    else {
      this.selectedUsers.delete(id);
    }
    //console.log(this.selectedUsers);
  }

  changeStatusUserAdmin(id: string, admin: boolean) {
    if(this.selectedUsers.has(id)) {
      this.selectedUsers.get(id).admin = admin;
    }
    //console.log(this.selectedUsers);
  }
}
