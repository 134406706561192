import {Component, Inject, OnInit} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from "@angular/material/bottom-sheet";
import {ChatService} from "../../services/chat.service";

@Component({
  selector: 'app-bottom-sheet-edit-user-group',
  templateUrl: './bottom-sheet-edit-user-group.component.html',
  styleUrls: ['./bottom-sheet-edit-user-group.component.css']
})
export class BottomSheetEditUserGroupComponent implements OnInit {

  public groupId: string;
  public userId: string;
  public isAdmin: boolean;
  public selfName: string;
  public otherName: string;
  constructor(private _bottomSheetRef: MatBottomSheetRef<BottomSheetEditUserGroupComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
              public chatService: ChatService,) {
    this.groupId = data.groupId;
    this.userId = data.userId.toString();
    this.isAdmin = data.isAdmin;
    this.selfName = data.selfName;
    this.otherName = data.otherName;
  }

  ngOnInit(): void {
  }

  getAction(number: number) {
    switch (number) {
      case 1:
        console.log('Voy a hacer al usuario admin del grupo');
        this.chatService.setUserAdmin(this.groupId, this.userId);
        break;
      case 2:
        console.log('Voy a quitar al usuario como admin del grupo');
        this.chatService.unsetUserAdmin(this.groupId, this.userId);
        break;
      case 3:
        console.log('Voy a eliminar al usuario del grupo');
        this.chatService.deleteGroupUser(this.groupId, this.userId, this.selfName, this.otherName);
        break;
      case 4:
        console.log('Voy a cancelar la acción');
        break;
    }
    this._bottomSheetRef.dismiss();
  }
}
