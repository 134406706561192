<span style="position: relative; width: 100%;">
  <ng-container *ngIf="show">
    <ng-container *ngFor="let e of elementsNatural | keyvalue : genericService.returnZero">
      <app-menu-generic-element [element]="elementsNatural[e.key]"></app-menu-generic-element>
    </ng-container>
  </ng-container>
    
    <ng-container *ngIf="elementsSpecials.length > 0">
        <span class="material-icons specials-icon" (click)="clickMenu(elementsSpecials[0])" *ngIf="elementsSpecials.length == 1" matTooltip="{{elementsSpecials[0].label}}">{{elementsSpecials[0].icon}}</span>
        <span class="material-icons specials-icon" [matMenuTriggerFor]="sub_tab_generic" *ngIf="elementsSpecials.length > 1">settings</span>
        <mat-menu #sub_tab_generic="matMenu" [hasBackdrop]="false">
          <ng-container *ngFor="let e of elementsSpecials | keyvalue : genericService.returnZero">
            <app-menu-generic-element [element]="elementsSpecials[e.key]"></app-menu-generic-element>
          </ng-container>
        </mat-menu>
    </ng-container>
</span>