import {Component, Inject, OnInit} from "@angular/core";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {Observable} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EndpointService} from "../../../services/generic/endpoint.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from "../../../services/auth/auth.service";
import {MessageDialogComponent} from "../../../utils/message-dialog/message-dialog.component";
import {map, startWith} from "rxjs/operators";

@Component({
  selector: 'campanya-ego-pop',
  templateUrl: './campanya-ego-pop.component.html',
  styleUrls: ['./campanya-ego-pop.component.css']
})

export class CampanyaEgoPopComponent implements OnInit {

  journeyGroup: UntypedFormGroup;
  filteredOptionsPlant: Observable<any[]>;

  journeyArray = new Array();

  public dataSource: MatTableDataSource<any>;

  public inputFilter: string;

  public idCampana;

  public active: boolean;
  public name: string;
  public actualJourney;
  public deleteJourney;

  public lastActive: boolean;
  public lastName: string;
  public lastJourney;

  displayedColumns: string[] = ['lopd', 'name', 'status'];

  constructor(public dialogRef: MatDialogRef<CampanyaEgoPopComponent>,
              private _formBuilder: UntypedFormBuilder,
              public dialog: MatDialog,
              public endpointService: EndpointService,
              private snackBar:MatSnackBar,
              private authService:AuthService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit(): void {

    this.idCampana = this.data.id;
    this.deleteJourney = new Array();

    this.endpointService.getCampanaPopupInfo().subscribe(data => {
      this.journeyArray = data['response'];
    });

    this.active = false;
    this.name = null;
    this.actualJourney = new Array();

    this.lastJourney = new Array();

    if(this.idCampana == null){
      this.lastActive = this.active;
      this.lastName = this.name;
    }else{
      this.endpointService.getCampana(this.idCampana).subscribe( data => {
        this.active = data['response'].campana[0].activo;
        this.name = data['response'].campana[0].nombre;
        this.actualJourney = data['response'].journey;

        console.log(this.actualJourney);
        this.dataSource = new MatTableDataSource(this.actualJourney);

        this.lastActive = this.active;
        this.lastName = this.name;
        for(let x = 0; x < this.actualJourney.length; x++) {

          if(this.actualJourney[x].estado == 1) this.actualJourney[x].estado = true;
          else this.actualJourney[x].estado = false;

          this.lastJourney.push({id: this.actualJourney[x].id, nombre: this.actualJourney[x].nombre, estado: this.actualJourney[x].estado});
        }
      });
    }

    this.journeyGroup = this._formBuilder.group({
      journey: ''
    });

    //console.log("Mi id: " + this.idJourney);

  }

  eliminarJourney(id: number){

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        message: '¿Estás seguro?',
        labelButton1: 'No',
        labelButton2: 'Sí'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === 2) {

        if (this.actualJourney[id].save == 1) this.deleteJourney.push(this.actualJourney[id]);

        this.actualJourney.splice(id, 1);
        this.dataSource = new MatTableDataSource(this.actualJourney);
        console.log(this.actualJourney);
        console.log(this.dataSource);
        console.log(this.deleteJourney);
      }
    });
  }

  displayFnJourney(object?: any): string {

    if(object) this.getPlantilla(object);

    this.filteredOptionsPlant = this.journeyGroup.get('journey').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre),
        map(name => name ? this._filterSoft(name) : this.journeyArray.slice()),
      );

    return object && object.nombre ? object.nombre : undefined;
  }

  private _filterSoft(name: string): any[] {
    const filterValue = name.toLowerCase();

    const softFilterd = this.journeyArray.filter(option => option.nombre.toLowerCase().includes(filterValue));

    return softFilterd;
  }

  getPlantilla(select: any){
    //console.log(select.id + " " + select.nombre + " " + select.estado);
    let save = 0;

    if(this.deleteJourney.find(p => p.id == select.id) != undefined){
      this.deleteJourney.splice(this.deleteJourney.indexOf(this.deleteJourney.find(p => p.id == select.id)), 1);
      save = 1;
    }

    if(this.actualJourney.find(p => p.id == select.id) == undefined){
      this.actualJourney.push({id: select.id, nombre: select.nombre, estado: select.activo, save: save});
      this.dataSource = new MatTableDataSource(this.actualJourney);
    }

    console.log(this.actualJourney);
    console.log(this.dataSource);

    this.journeyGroup.controls['journey'].setValue('');
  }

  guardar(){

    let text;

    if(this.idCampana != null) text = "Se aplicara los cambios a la Campaña. ¿Está seguro?";
    else text = "Se creará una nueva Campaña. ¿Está seguro?";

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        message: text,
        labelButton1: 'No',
        labelButton2: 'Sí'
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data === 2) {

        this.endpointService.editCampana(this.actualJourney, this.idCampana, this.active, this.name, this.authService.userId, this.deleteJourney).subscribe( data => {
          let text;
          this.lastJourney = new Array();

          if(this.idCampana == null) text = "Se ha creado un nuevo Journey";
          else text = "Se ha editado la configuración del Journey";

          this.snackBar.open(text, 'X', {
            duration: 4000, panelClass: ['green-snackbar']
          });

          this.idCampana = data['response'];

          this.deleteJourney = new Array();
          for(let i = 0; i < this.actualJourney.length; i++) {
            this.actualJourney[i].save = 1;
            this.lastJourney.push({id: this.actualJourney[i].id, nombre: this.actualJourney[i].nombre, estado: this.actualJourney[i].estado});
          }
          console.log(this.actualJourney);

        });
      }
    });

  }

  cancelar(){

    if(this.onChanges()){

      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: 'auto',
        height: 'auto',
        data: {
          message: 'Se ha detectado cambios en el Journey. Los datos del Journey no se guardaran. ¿Estás seguro?',
          labelButton1: 'No',
          labelButton2: 'Sí'
        }
      });

      dialogRef.afterClosed().subscribe(data => {
        if (data === 2) {
          this.dialogRef.close();
        }
      });

    }else{
      this.dialogRef.close();
    }

  }

  arrayEqual(a, b) : boolean{

    let bool = true;
    let x = 0;

    if(a.length != b.length) bool = false;

    console.log(x);
    console.log(a.length);

    while(x < a.length && bool){

      let index = b.findIndex((element, index) =>{
        if(element.estado === a[x].estado && element.nombre === a[x].nombre && element.id === a[x].id) return true;
      });

      console.log("index");
      console.log(index);

      if(index != -1) x++;
      else bool = false;
    }

    console.log(a);
    console.log(b);
    console.log(bool);

    return bool;
  }

  onChanges():boolean{
    return this.active != this.lastActive || this.name != this.lastName ||
      this.deleteJourney.length > 0 || !this.arrayEqual(this.actualJourney, this.lastJourney);
  }

}
