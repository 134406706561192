<span *ngIf="loaded" id="table-filters-generic" fxFlex="100" fxLayout="row wrap" style="padding-bottom: 3em; overflow: scroll;">
    <span fxFlex="100" style="padding: 1em 1em 0em 1em; background: rgba(var(--primary-color), 0.25);">
        <mat-form-field fxFlex="50" fxFlex.sm="100" fxFlex.xs="100" appearance="outline">
            <mat-label>Nombre filtro</mat-label>
            <input matInput [(ngModel)]="this.nameFilter">
        </mat-form-field>
    </span>
    <span fxLayout="row wrap" fxFlex="100" style="padding: 1em;">
        <span *ngFor="let field of arrayFiltersFields | keyvalue: genericService.returnZero" style="padding: 5px" fxFlex="25" fxFlex.sm="100" fxFlex.xs="100">
            <ng-container *ngIf="(arrayFiltersFields[field.key]['type'] == 9 || arrayFiltersFields[field.key]['type'] == 101) && arrayFiltersFields[field.key]['value'] && arrayFiltersFields[field.key]['value'] !== undefined && checkIsArray(arrayFiltersFields[field.key]['value']) && arrayFiltersFields[field.key]['value'].length && arrayFiltersFields[field.key]['value'].length > 0">
                <app-chip-autocomplete
                    *ngIf="arrayFiltersFields[field.key]['value'].length >= 8"
                    [filterInitial]="arrayFiltersFields[field.key]['filter']"
                    [filterIndex]="field.key"
                    [filterFields]="arrayFiltersFields[field.key]"
                    [resetFilter]="eventResetFilter.asObservable()"
                    (filterChanged)="filterChanged($event)">
                </app-chip-autocomplete>
    
                <mat-form-field *ngIf="arrayFiltersFields[field.key]['value'].length < 8" appearance="outline">
                    <mat-label style="text-transform: uppercase;">{{arrayFiltersFields[field.key]['label']}}</mat-label>
                    <mat-select multiple [(ngModel)]="arrayFiltersFields[field.key]['filter']">
                        <mat-option *ngFor="let option of arrayFiltersFields[field.key]['value'] | keyvalue: genericService.returnZero" [value]="arrayFiltersFields[field.key]['value'][option.key]">
                            {{arrayFiltersFields[field.key]['value'][option.key]}}
                        </mat-option>
                    </mat-select>
                    <span *ngIf="arrayFiltersFields[field.key]['filter'].length > 0" matSuffix aria-label="Clear" (click)="clearFieldFilter(field.key, 0); $event.stopPropagation()">
                        <mat-icon class="material-icons-outlined">close</mat-icon>
                    </span>
                </mat-form-field>
    
            </ng-container>
    
            <span fxLayout="row wrap" style="background: #ececec; padding: 3px 15px; border-radius: 3px;" *ngIf="arrayFiltersFields[field.key]['type'] == 191 && addMinMax(field.key)">
                <label style="font-weight: 500; text-align: center; padding: 5px 0; text-transform: uppercase;" fxFlex="100">{{arrayFiltersFields[field.key]['label']}}</label>
                <mat-form-field style="padding: 0 3px;" fxFlex="50" appearance="outline">
                    <mat-label>Mínimo</mat-label>
                    <input matInput type="number" [(ngModel)]="arrayFiltersFields[field.key]['filter']['min']">
                    <span *ngIf="arrayFiltersFields[field.key]['filter']['min']" matSuffix aria-label="Clear" (click)="clearFieldFilter(field.key, 1); $event.stopPropagation()">
                        <mat-icon class="material-icons-outlined">close</mat-icon>
                    </span>
                </mat-form-field>
                <mat-form-field style="padding: 0 3px;" fxFlex="50" appearance="outline">
                    <mat-label>Máximo</mat-label>
                    <input matInput type="number" [(ngModel)]="arrayFiltersFields[field.key]['filter']['max']">
                    <span *ngIf="arrayFiltersFields[field.key]['filter']['max']" matSuffix aria-label="Clear" (click)="clearFieldFilter(field.key, 2); $event.stopPropagation()">
                        <mat-icon class="material-icons-outlined">close</mat-icon>
                    </span>
                </mat-form-field>
            </span>
    
            <ng-container *ngIf="arrayFiltersFields[field.key]['type'] == 124">
                <mat-radio-group aria-labelledby="example-radio-group-label" fxFlex="100" fxLayout="row wrap" class="filters-radio-group filters-radio-button-vertical yes-no-radio" [(ngModel)]="arrayFiltersFields[field.key]['filter']">
                    <ng-container *ngFor="let option of arrayFiltersFields[field.key]['value']">
                        <mat-radio-button fxFlex="100" *ngIf="option == 1" [value]="option" class="filters-radio-group-button radio-orden-0">
                            {{arrayFiltersFields[field.key]['functionalArea']['description']}}
                        </mat-radio-button>
                        <mat-radio-button fxFlex="100" *ngIf="option == 0" [value]="option" class="filters-radio-group-button radio-orden-1">
                            {{arrayFiltersFields[field.key]['functionalArea']['label']}}
                        </mat-radio-button>
                    </ng-container>
                    <span *ngIf="arrayFiltersFields[field.key]['filter'] === 0 || arrayFiltersFields[field.key]['filter'] === 1" fxFlex="100" aria-label="Clear" (click)="clearFieldFilter(field.key, 0); $event.stopPropagation()" class="clear-options-radio"><mat-icon class="material-icons-outlined">close</mat-icon> Borrar selección</span>
                </mat-radio-group>
            </ng-container>
    
            <ng-container *ngIf="arrayFiltersFields[field.key]['type'] == 9999999">
                <mat-radio-group aria-labelledby="example-radio-group-label" fxLayout="row wrap" class="filters-radio-group yes-no-radio" [(ngModel)]="arrayFiltersFields[field.key]['filter']">
                    <mat-label fxFlex="100" style="text-transform: uppercase; font-weight: 600; margin-bottom: 3px; display: flex;">{{arrayFiltersFields[field.key]['label']}}<span *ngIf="arrayFiltersFields[field.key]['filter'] === 'isbooleanfalse' || arrayFiltersFields[field.key]['filter'] === 'isbooleantrue'" aria-label="Clear" (click)="clearFieldFilter(field.key, 0); $event.stopPropagation()" class="clear-options-radio" style="padding-top: 3px;"> (<mat-icon class="material-icons-outlined" style="margin-right: -8px;">close</mat-icon>Borrar selección)</span></mat-label>
                    <ng-container *ngFor="let option of arrayFiltersFields[field.key]['value']">
                        <mat-radio-button style="width: auto;" *ngIf="option == 'isbooleantrue'" [value]="option" class="filters-radio-group-button radio-orden-0">
                            Sí
                        </mat-radio-button>
                        <mat-radio-button style="width: auto; margin-left: 15px;" *ngIf="option == 'isbooleanfalse'" [value]="option" class="filters-radio-group-button radio-orden-1">
                            No
                        </mat-radio-button>
                    </ng-container>
                </mat-radio-group>
            </ng-container>
    
            <span fxLayout="row wrap" style="background: #ececec; padding: 3px 15px; border-radius: 3px;" *ngIf="arrayFiltersFields[field.key]['type'] == 1999999" class="dates-filter-tables-generic">
                <label style="font-weight: 500; text-align: center; padding: 5px 0; text-transform: uppercase;" fxFlex="100">{{arrayFiltersFields[field.key]['label']}}</label>
                <mat-form-field style="padding: 0 3px;" fxFlex="50" appearance="outline">
                    <mat-label>Desde</mat-label>
                    <input matInput [matDatepicker]="picker1" [(ngModel)]="arrayFiltersFields[field.key]['filter'].fechaInicio">
                    <span *ngIf="arrayFiltersFields[field.key]['filter'].fechaInicio" matSuffix aria-label="Clear" (click)="clearFieldFilter(field.key, 3); $event.stopPropagation()">
                        <mat-icon class="material-icons-outlined">close</mat-icon>
                    </span>
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
                <mat-form-field style="padding: 0 3px;" fxFlex="50" appearance="outline">
                    <mat-label>Hasta</mat-label>
                    <input matInput [matDatepicker]="picker2" [(ngModel)]="arrayFiltersFields[field.key]['filter'].fechaFin">
                    <span *ngIf="arrayFiltersFields[field.key]['filter'].fechaFin" matSuffix aria-label="Clear" (click)="clearFieldFilter(field.key, 4); $event.stopPropagation()">
                        <mat-icon class="material-icons-outlined">close</mat-icon>
                    </span>
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
            </span>
            <!-- PARA VERSIONES SUPERIORES DE MATERIAL
            <app-date-range-picker
                [filterInitial]="arrayFiltersFields[field.key]['filter']"
                [filterIndex]="field.key"
                [filterFields]="arrayFiltersFields[field.key]"
                [resetFilter]="eventResetFilter.asObservable()"
                (filterChanged)="filterChanged($event)">
            </app-date-range-picker>-->
        </span>
    </span>
</span>
<div *ngIf="!loaded" fxLayoutAlign="center center" fxFlex="100">
    <mat-spinner></mat-spinner>
</div>
<span class="buttons-table-filter">
    <span class="generic-buttons-blue" style="margin-right: 10px;" (click)="closeBottomSheet()"><button mat-button>Cerrar sin modificar filtros</button></span>
    <span class="generic-buttons-blue" style="margin-right: 10px;" (click)="resetFilterTableValues()"><button mat-button>Resetear filtros</button></span>
    <span class="generic-buttons-blue-revert" (click)="filterTableValues()"><button mat-button>Aplicar filtros</button></span>
</span>