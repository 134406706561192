<form
  [formGroup]="structure['form']"
  (ngSubmit)="functionsControllerService.getFunction(structure[index], structure['form'])"
  ngNativeValidate
  [id]="formId"
  #documentEditForm="ngForm"
  class="generic-form-component">
  <ng-container *ngIf="structure['hide_frontend'] != 1">
    <app-generic fxLayout="row wrap" [structure]="structure"></app-generic>
  </ng-container>
</form>

<!-- el disabled funciona. mirar de hacerlo en el button.generic
<button class="btn-save button primary" [attr.form]="formId" [disabled]="!documentEditForm.form.valid">
  SAVE {{formId}}
</button>
-->
