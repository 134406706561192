import { Component, Input, OnInit } from '@angular/core';
import { FunctionsService } from 'app/services/generic/functions.service';
import { EndpointService } from 'app/services/generic/endpoint.service';

@Component({
  selector: 'app-stripe-custom',
  templateUrl: './stripe-custom.component.html',
  styleUrls: ['./stripe-custom.component.sass']

})
export class StripeCustomComponent implements OnInit {
 
  @Input() structure = null;
  @Input() index: number;

  constructor(private endpointService: EndpointService,
    private functionsService: FunctionsService,
  ) {}

  ngOnInit(): void {
    //$stripe = new \Stripe\StripeClient('sk_test_51NBDXeF0nPp3VXRAIykE1V1VeIvLQ4t7cz6D39n50WROkGoObwh7J8qeQcAHBzPH6ps4wfXOQpmmjJVulV3sK2fg009bNotOFY');
    console.log("StripeCustomComponent");
    let idPayment = this.functionsService.loadPaymentData(this.structure[this.index]);
    
    this.endpointService.payGeneric(idPayment, 3).subscribe((data) => {
      if(data['response']['url'] != null) {
        window.open(data['response']['url'], "_self");
      }
    });
  }
}