import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/internal/operators";
import { Values } from "../../values/values";

@Injectable()
export class EndpointClientService {
  private URL = Values.SERVER_URL_CLIENT;

  private reqPattern = Values.API_REQ_PATTERN;

  private observable = null;
  //used for caching some backend requests

  // Joan private userInfoResponse = null;

  constructor(private http: HttpClient) {}

  public insertPedidoStaticRestaurant(arrayProductos, productsPriceCounter, idEmpresa) {
    let data = JSON.stringify({arrayProductos, productsPriceCounter, idEmpresa});

    let requestURL =
      this.URL +
      Values.API_RESOURCES.RESTAURANT +
      this.reqPattern +
      Values.RESTAURANT_REQS.INSERT_PEDIDO_STATIC_RESTAURANT;
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public mergePedidosRestaurant(arrayPedidos) {
    let data = JSON.stringify({arrayPedidos});

    let requestURL =
      this.URL +
      Values.API_RESOURCES.RESTAURANT +
      this.reqPattern +
      Values.RESTAURANT_REQS.MERGE_PEDIDOS_RESTAURANT;
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }  

  public anadirProductoDirecto(idPedido, idProducto) {
    let data = JSON.stringify({idPedido, idProducto});
    let requestURL =
      this.URL +
      Values.API_RESOURCES.RESTAURANT +
      this.reqPattern +
      Values.RESTAURANT_REQS.ANADIR_PRODUCTO_DIRECTO_RESTAURANT;
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  
  
  public productosEstadoCincoDirecto(idPedido) {
    let data = JSON.stringify({idPedido});
    let requestURL =
      this.URL +
      Values.API_RESOURCES.RESTAURANT +
      this.reqPattern +
      Values.RESTAURANT_REQS.PRODUCTOS_ESTADO_CINCO_DIRECTO;
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public movePedidoRestaurant(pedidoOrigen, mesaDestino, juntarPedido, pedidoDestino) {
    let data = JSON.stringify({pedidoOrigen, mesaDestino, juntarPedido, pedidoDestino});

    let requestURL =
      this.URL +
      Values.API_RESOURCES.RESTAURANT +
      this.reqPattern +
      Values.RESTAURANT_REQS.MOVE_PEDIDO_RESTAURANT;
      return this.http.post(requestURL, data).pipe(map((response) => response));
  }

  public getPropertyLocation(idProperty) {
    let body = JSON.stringify({ idProperty });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.INMO +
      this.reqPattern +
      "get-property-location";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public getClientLocation(idClient) {
    let body = JSON.stringify({ idClient });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.INMO +
      this.reqPattern +
      "get-client-location";
    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public updateViviendaConfigPortalesMassive(idPortal, data) { // FALTA POSAR A LA BD, però com que es del controller generic no se com posar-lo, doncs encara no hem fet cap controller de generic
    let body = JSON.stringify({ idPortal, data });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.UPDATE_VIVIENDA_CONFIG_PORTALES_MASSIVE;

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

  public sendEmailInmueble(values, userId, idCompany) { // FALTA POSAR A LA BD, però com que es del controller generic no se com posar-lo, doncs encara no hem fet cap controller de generic
    let body = JSON.stringify({ values, userId, idCompany });
    let requestURL =
      this.URL +
      Values.API_RESOURCES.GENERIC +
      this.reqPattern +
      Values.GENERIC_REQUEST.SEND_EMAIL_INMUEBLE;

    return this.http.post(requestURL, body).pipe(map((response) => response));
  }

}

export interface ResponseInterface {
  errorCode: number;
  errorMessage: string;
  response: any;
}
