import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'app/services/auth/auth.service';
import { EndpointService } from 'app/services/generic/endpoint.service';
import { FunctionsService } from 'app/services/generic/functions.service';
import { GenericService } from 'app/services/generic/generic.service';

@Component({
  selector: 'app-paycomet-custom',
  templateUrl: './paycomet-custom.component.html',
  styleUrls: ['./paycomet-custom.component.sass']

})
export class PaycometCustomComponent implements OnInit {
  constructor(private endpointService: EndpointService, 
              private authService: AuthService,
              private functionsService: FunctionsService,
              private genericService: GenericService,
              public sanitizer: DomSanitizer,
    ) {}

  @Input() structure = null;
  @Input() index: number;

  public srcPaycomet = null;
  public finishLoad = false;

  ngOnInit(): void {
    //let paymentData = {idPayment: 1, idEmpresa: this.authService.getIdCompany(), purchaseAmount: 10000, currency: "EUR", transference_id: 1};
    let idPayment = this.functionsService.loadPaymentData(this.structure[this.index]);
    this.endpointService.payGeneric(idPayment, 1).subscribe((data) => {
      if(data['errorCode'] == 0) {
        this.srcPaycomet = this.sanitizer.bypassSecurityTrustResourceUrl(data['response']['URL_REDIRECT']);
      } else {
        this.genericService.openSnackBar(data['response'], 4000, ["red-snackbar"]);
      }
      this.finishLoad = true;
    });
  }

}
export class AppComponent {
  paymentRequest: string;
  paymentResponse: any;

  constructor() { }
}
