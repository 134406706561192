import {Component, Inject, Input, OnInit, ViewChild} from "@angular/core";
import {MatIconModule} from "@angular/material/icon";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EndpointService} from "../../services/generic/endpoint.service";
import {AdviceReportService} from "../../services/advice-report/advice-report.service";
import {DomSanitizer} from "@angular/platform-browser";
import {AuthService} from "../../services/auth/auth.service";
import {MatStepper} from "@angular/material/stepper";


@Component({
  selector: 'auto-popup',
  templateUrl: './auto-popup.component.html',
  styleUrls: ['./auto-popup.component.css']
})

export class AutoPopupComponent implements OnInit{

  @ViewChild('stepper') stepper: MatStepper;

  public index;

  private iniDate;

  public containerArray = new Array();
  public stepArray = new Array();

  //public title: string;
  //public subtitle: string;

  public personalizado: boolean;
  public mensaje: string;

  public icon: string;

  public iconActive: boolean;
  public iconTooltipe: string;

  public colorTitle: string;
  public colorSubtitle: string;
  public colorIcon: string;
  public background: string;

  public colorClose: string;
  public close: boolean;

  public closeOutsideWindow: boolean;
  public stephide: boolean;
  public onlyOneHeader: boolean;
  adviceReport: any;

  constructor(public dialogRef: MatDialogRef<AutoPopupComponent>,
              private sanitizer: DomSanitizer,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private adviceReportService: AdviceReportService,
              private authService: AuthService,
              private endpointService: EndpointService) { }

  ngOnInit(): void {
    console.log(JSON.parse(JSON.stringify(this.data)))
    this.index = this.data.index

    this.iniDate = new Date();

    this.dialogRef.disableClose = !this.data.plantilla.closeOutsideWindow;

    this.personalizado = this.data.plantilla.personalizado === 1;
    this.mensaje = this.data.mensaje;

    this.icon = this.data.plantilla.icon;

    this.iconActive = this.data.plantilla.icon_active;
    this.iconTooltipe = this.data.plantilla.icon_tooltipe;

    this.colorTitle = this.data.plantilla.titleColor;
    this.colorSubtitle = this.data.plantilla.subtitleColor;
    this.colorIcon = this.data.plantilla.icon_color;
    this.background = this.data.plantilla.backgroundColor;

    this.colorClose = this.data.plantilla.colorCerrar;
    this.close = this.data.plantilla.cerrar;

    this.stepArray = this.data.step;
    console.log(this.stepArray)
    this.videoSanitzer();

    //this.closeOutsideWindow = this.data.plantilla.closeOutsideWindow;
    this.stephide = this.data.plantilla.hideStepHeader;
    this.onlyOneHeader = this.data.plantilla.soloStepActual;

    this.dialogRef.backdropClick().subscribe(() => {
      // Close the dialog
      //if(this.closeOutsideWindow) this.closePopup()
      let noti = this.adviceReportService.notifications.find(sup => sup.id_plantilla_ego === this.index)
      if(this.data.plantilla.closeOutsideWindow) this.adviceReportService.clickMonitoreoEgo(this.data.plantilla.id, null, null, this.iniDate, this.authService.userId);
      this.endpointService.updateEgoNotificationFinalHour(noti !== undefined ? noti.id_not : null, true).subscribe(data => {
        console.log('SE HA ACTUALIZADO CORRECTAMENTE LA HORA FINAL DE LA NOTIFICACIÓN');
        if(noti !== undefined) this.adviceReportService.notifications = this.adviceReportService.notifications.filter(sup => sup.id_plantilla_ego !== this.index)
      });
    });
  }

  /*onCancel(){
    this.dialogRef.close();
  }*/

  closePopup() {
    console.log("Cerrando Pop-Up")
    console.log(JSON.parse(JSON.stringify(this.adviceReportService.notifications)))
    if(!this.data.demo) {
      this.adviceReportService.clickMonitoreoEgo(this.data.plantilla.id, null, null, this.iniDate, this.authService.userId);
    }
    let noti = this.adviceReportService.notifications.find(sup => sup.id_plantilla_ego === this.index)
    this.dialogRef.close();
    console.log(noti)
    this.endpointService.updateEgoNotificationFinalHour(noti !== undefined ? noti.id_not : null, true).subscribe(data => {
      console.log('SE HA ACTUALIZADO CORRECTAMENTE LA HORA FINAL DE LA NOTIFICACIÓN');
      //if(noti !== undefined) this.adviceReportService.notifications.splice(this.adviceReportService.notifications.find(sup => sup.id_plantilla_ego === this.index, 1))
      if(noti !== undefined) this.adviceReportService.notifications = this.adviceReportService.notifications.filter(sup => sup.id_plantilla_ego !== this.index)
      this.adviceReportService.deleteNotification(this.index);
    });
  }


  buttonAction(i, s) {

    if (!this.data.demo) {
      this.adviceReportService.clickMonitoreoEgo(this.data.plantilla.id, this.stepArray[s].id, this.stepArray[s].buttons[i].id_button, this.iniDate, this.authService.userId);
    }
    console.log("i: ", i)
    console.log("s: ", s)
    console.log("s: ", this.stepArray[s].buttons[i], this.stepArray)
    if(!this.data.demo) {

      let redirect = [];
      let externActions = [];
      for (let x = 0; x < this.stepArray[s].buttons[i].actionArray.length; x++) {
        console.log(this.stepArray[s].buttons[i].actionArray[x])
        if (this.stepArray[s].buttons[i].actionArray[x].action == 4) {
          console.log("holaaaaaa");
          console.log(this.index);
          console.log(JSON.parse(JSON.stringify(this.adviceReportService.notifications)))

          this.closePopup();
        }
        else if (this.stepArray[s].buttons[i].actionArray[x].action == 22) this.stepper.next();
        else if (this.stepArray[s].buttons[i].actionArray[x].action == 23) this.stepper.previous();
        else if (this.stepArray[s].buttons[i].actionArray[x].action == 2 ||
          this.stepArray[s].buttons[i].actionArray[x].action == 24 ||
          this.stepArray[s].buttons[i].actionArray[x].action == 25) redirect.push(this.stepArray[s].buttons[i].actionArray[x]);
        else {
          console.log("Añado a extern actions")
          externActions.push(this.stepArray[s].buttons[i].actionArray[x]);
          if (this.stepArray[s].buttons[i].actionArray[x].action == 6 ||
            this.stepArray[s].buttons[i].actionArray[x].action == 7) externActions[externActions.length - 1].id = this.data.usuario_secundario;
        }
      }


      this.adviceReportService.tratarAcciones(externActions, redirect);
    }

  }

  ejecuteButtonAction(buttonAction){

    let dato = null;

    if(!this.data.demo){
          if (buttonAction.action == 1) dato = buttonAction.text;
          else if (buttonAction.action == 2) dato = buttonAction.id;
          else if (buttonAction.action == 5 || buttonAction.action == 26) dato = buttonAction.id;
          else if (buttonAction.action === 6 || buttonAction.action === 7) dato = this.data.usuario_secundario;
          else if (buttonAction.action === 8) dato = this.authService.inmoId;
          else if (buttonAction.action === 9) dato = this.authService.userId;
          else if (buttonAction.action == 24) dato = buttonAction.id;
          else if (buttonAction.action == 25) dato = buttonAction.id;
          else if (buttonAction.action == 28) dato = buttonAction.id;


          if(buttonAction.action != 4 && buttonAction.action != 22 && buttonAction.action != 23){

            if(buttonAction.action == 24){
              if(!this.data.demo) {
                this.adviceReportService.clickMonitoreoEgo(this.data.plantilla.id, null, null, this.iniDate, this.authService.userId);
              }
              if(this.adviceReportService.notifications.find(sup => sup.id_plantilla_ego === this.index) !== undefined) this.adviceReportService.notifications = this.adviceReportService.notifications.filter(sup => sup.id_plantilla_ego !== this.index)
              this.dialogRef.close();
              this.endpointService.updateEgoNotificationFinalHour(this.data.id_not, true).subscribe(data => {
                console.log('SE HA ACTUALIZADO CORRECTAMENTE LA HORA FINAL DE LA NOTIFICACIÓN');
                this.adviceReportService.pulsado(buttonAction.action, dato);
              });

            }else{
              this.adviceReportService.pulsado(buttonAction.action, dato);
            }
            //this.onCancel();
          }else if(buttonAction.action === 22) this.stepper.next();
          else if(buttonAction.action === 23) this.stepper.previous();
          //else this.onCancel();

          if(buttonAction.action === 4) {
            console.log("holaaaaaa");
            console.log(this.index);
            if(this.adviceReport.notifications.find(sup => sup.id_plantilla_ego === this.index) === undefined) {
              this.adviceReportService.deleteNotification(this.index);
            }
            this.closePopup();
          }
    }
  }

  videoSanitzer(){
    for(let i = 0; i < this.stepArray.length; i++){
      for(let j = 0; j < this.stepArray[i].container.length; j++){
        if(this.stepArray[i].container[j].idType === 5 || this.stepArray[i].container[j].idType === 6){
          this.stepArray[i].container[j].container = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.stepArray[i].container[j].container);
        }
      }
    }
  }
}
