import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {EndpointService} from "../../../services/generic/endpoint.service";
import {MatDialog} from "@angular/material/dialog";
import {JourneyEgoPopComponent} from "../../popup/journey_ego_pop/journey-ego-pop.component";
import {CampanyaEgoPopComponent} from "../../popup/campaña_ego_pop/campanya-ego-pop.component";

@Component({
  selector: 'campanya-ego',
  templateUrl: './campanya-ego.component.html',
  styleUrls: ['./campanya-ego.component.css']
})

export class CampanyaEgoComponent implements OnInit {

  @Input() dataSourceCampana;
  public eventStatusList = Array();

  public inputFilter: string;

  @Input() selectedTab;

  @Input() campanaArray;
  @Output() updateCampana = new EventEmitter();
  @Output() iniCampana = new EventEmitter();
  @Output() filterJourney = new EventEmitter();
  @Output() quitFilterJourney = new EventEmitter();

  displayedColumns: string[] = ['operations', 'name', 'status',  'creator', 'date'];

  constructor( public endpointService: EndpointService,
               private dialog: MatDialog) { }

  ngOnInit(): void {

    this.eventStatusList.push(
      'Parado', 'Activo'
    );

    this.iniCampana.emit();

  }

  campanaPopup(id: number){

    const dialogRef = this.dialog.open(CampanyaEgoPopComponent,{
      width: 'auto',
      height: 'auto',
      data:{id: id}
    });

    dialogRef.afterClosed().subscribe( data=>{
      this.updateCampana.emit();
    });

  }

  public applyFilter(filterValue: string): void {
    this.dataSourceCampana.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceCampana.paginator) {
      this.dataSourceCampana.paginator.firstPage();
    }
  }

  selectCheckBox(id){
    this.quitFilterJourney.emit();
    let index = this.campanaArray.findIndex((element, index) =>{
      if(element.id == id) return true;
    });
    this.campanaArray[index].checkbox = true;
    this.filterJourney.emit();
  }

}
