import {Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";
import {MatIconModule} from '@angular/material/icon'
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {MatTableDataSource} from "@angular/material/table";
import {PeriodicElement} from "../../../bill/bill.component";
import {EndpointService, ResponseInterface} from "../../../services/generic/endpoint.service";
import {IconScreenComponent} from "./iconscreen/icon-screen.component";
import {MatDialog} from "@angular/material/dialog";
import {SelectionModel} from "@angular/cdk/collections";
import {WorkflowEventBody} from "../../objects/workflow-event-body";
import {forkJoin, Observable} from "rxjs";
import {AuthService} from "../../../services/auth/auth.service";
import {ModulConditionContainer} from "../../objects/workflow-event-condition-modul-container";
import {EventConditionBody} from "../../objects/workflow-event-condition-body";
import {EMPTY as empty} from "rxjs/internal/observable/empty";
import {DatePipe} from '@angular/common';
import {AngularEditorComponent, AngularEditorConfig} from "@kolkov/angular-editor";
import {Values} from "../../../../values/values";
import {Strings} from "../../../../strings/strings";
import {map, startWith} from "rxjs/operators";
import {BottomSheetImageSelector} from "../../../utils/bottom-sheet-image-selector/bottom-sheet-image-selector";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessageDialogComponent} from "../../../utils/message-dialog/message-dialog.component";
import {InputDialogComponent} from "../../../input-dialog/input-dialog.component";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";

export interface ModuleList {
  id: number;
  name: string;
}

@Component({
  selector: 'dynamic-reportdetails',
  templateUrl: './dynamic-reportdetails.component.html',
  styleUrls: ['./dynamic-reportdetails.component.css']
})


export class DynamicReportDetailsComponent implements OnInit{

  nStep;

  filteredOptionsPlantilla: Observable<any[]>;
  filteredOptionsPlantillaSuperior: Observable<any[]>;
  filteredOptionsPlantillaSuperiorHome: Observable<any[]>;
  filteredOptionsMarketplace: Observable<any[]>;
  filteredOptionsKnowledge: Observable<any[]>;
  filteredOptionsFunctionalArea: Observable<any[]>;

  plantilla: UntypedFormGroup;

  functionalArea: UntypedFormGroup;

  journeyGroup: UntypedFormGroup;
  filteredOptionsJourney: Observable<any[]>;

  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef;

  public numbersLists: any;
  public stringsLists: any;
  public datesLists: any;

  public type = new Array();
  public screenArray = new Array();
  public container = new Array();
  public actionArray = new Array();
  public buttonActionArray = new Array();
  public buttonActionArrayActive = new Array();
  public moduloRModuloList: any = null;
  public moduloList: any = null;
  public templatesNamesList: any = null;
  public canAddConditionContainer = false;
  public functionsToExecute: any = null;
  public fieldsList: any = null;
  public dataLoaded = false;
  public posibleModulesForActions: Array<number>;
  public typesList: any = null;
  public operatorsList: any = null;
  public enumsLists: any;
  public loadingExternOptions = false;
  public externOptionsListsList: Array<Array<any>>;
  public externList: any = null;
  public creatorName: string;
  public executeOverList: any = null;
  public marketArray = new Array();
  public knowledgeArray = new Array();

  //public selectedEvent = new WorkflowEventBody();

  public whenExecuteList: any = null;

  //public horaEjecucion: Date;
  //public fechaEjecucion: Date;

  public daysOfWeek: Array<Object>;

  public listaModulos: ModuleList[];

  public iseditingView = true;
  public htmlContent = '';
  editorConfig: AngularEditorConfig = Values.EDITOR_CONFIG;

  public stringsMultiselectorOptions = Strings.MULTISELECTORVALUES;

  public modulos = [];
  public prevModulo = 1;
  public newTemplateType: string;
  public backendRequest = Values.SERVER_URL + 'ego-notifications&req=insert-ego-image';
  public images: Array<any> = null;
  public fields: Array<any> = [];
  public fieldsToShow: Array<any> = []; //array de los fields que se mostraran por pantalla en el body del email
  public filesToAttach: Array<any> = [];
  public filesToAttachArrayWithURL: Array<any> = [];
  public pdfTemplates: Array<any> = [];
  public pdfTemplatesForModulo: Array<any> = [];
  public functionalAreas = [];

  /*public name: string;
  public idType: number;
  public snackTime: number;
  public screen: number;
  public title: string;
  public titleColor = "#000000";
  public subtitle: string;
  public subtitleColor = "#000000";
  public icon: string;
  public iconColor = "#FF0000";
  public cerrar = false;*/

  //public containerArray : {idType: number, container: any, color: string} [] = new Array();
  //public buttonArray: {active: boolean, name: string, text: string, action: number, colorText: string, icon: string} [] = new Array();

  @Input() selectedID;
  @Input() idEvent;
  @Input() containerArray;
  @Input() buttonArray;
  @Input() bodyReport;
  @Input() edit;
  @Input() dropContainerArray;
  @Input() dropStepArray;
  @Input() selectedEvent;
  @Input() plantillasArray;
  @Input() stepArray;
  @Input() iconArray;
  @Input() notification;
  @Input() notificationSuperior;
  @Input() notificationSuperiorHome;
  @Input() journeyArray;


  headModules = ['select', 'nombre'];
  dataSourceModules  = new MatTableDataSource<PeriodicElement>(this.buttonActionArray);
  selection = new SelectionModel<PeriodicElement>(true, []);

  constructor(private _formBuilder: UntypedFormBuilder,
              public material: MatIconModule,
              public dialog: MatDialog,
              public endpointService: EndpointService,
              public authService: AuthService,
              private _bottomSheet: MatBottomSheet,
              private snackBar: MatSnackBar,
              public datepipe: DatePipe) { }

  ngOnInit(): void {

    this.plantilla = this._formBuilder.group({
      plant: '',
    });

    this.functionalArea = this._formBuilder.group({
      fa:'',
    });

    this.journeyGroup = this._formBuilder.group({
      journey: '',
    });

    this.nStep = this.stepArray.length;

    this.initEmptyLists();

    this.images = [];

    const observersArray = [];
    observersArray.push(this.endpointService.getAllTargetLists(this.authService.inmoId, 1));
    observersArray.push(this.endpointService.getConditionsSelectorsValues(2, this.authService.inmoId, false));
    forkJoin(observersArray).subscribe(dataa => {
      const data = dataa as Array<ResponseInterface>;
      this.handleSelectorsStructure(data[1]);
      this.endpointService.eventsGetCampaignsEmails(this.authService.inmoId).subscribe(dat => {
        if (this.idEvent >= 0) {
          this.endpointService.getEvent(this.idEvent).subscribe(
            eventdata => {
              this.handleEventData(eventdata);
              this.endpointService.getUsers(this.authService.inmoId).subscribe(
                data2 => {
                  const usersList = [];
                  for (let i = 0; i < data2['response'].length; i++) {
                    usersList[data2['response'][i].id] = data2['response'][i].nombre;
                  }
                  this.creatorName = usersList[this.selectedEvent.creator];
                }
              );
              this.selectedEvent.conditions.forEach(
                element => this.posibleModulesForActions.push(element.conditionModulo)
              );
              this.endpointService.getAllTemplatesNamesByModule(this.authService.inmoId, this.selectedEvent.modulo).subscribe(result => {
                this.templatesNamesList = result['response'];
                this.canAddConditionContainer = this.selectedEvent.conditions.length === this.getCompatibleModuloList().length;
                this.dataLoaded = true;
              });
            }
          );
        } else {
          this.selectedEvent = new WorkflowEventBody();
          this.selectedEvent.init();
          this.selectedEvent.creator = this.authService.getLoggedInUserId();
          this.selectedEvent.id_inmobiliaria = this.authService.inmoId;
          this.creatorName = this.authService.userFullName;
          this.dataLoaded = true;
          this.canAddConditionContainer = this.selectedEvent.conditions.length === this.getCompatibleModuloList().length;
        }
      });
    });




    this.endpointService.getDynamicReportTyper().subscribe( data =>{

      this.type = data['response'].report;
      this.screenArray = data['response'].screen;
      this.functionalAreas = data['response'].functional_areas;
      this.container = data['response'].container;
      this.actionArray = data['response'].action;
      this.marketArray = data['response'].market;
      this.knowledgeArray = data['response'].knowledge;

    });

    this.endpointService.getButtonActionReport().subscribe( data =>{
      this.buttonActionArray = data['response'];
      this.dataSourceModules = new MatTableDataSource<PeriodicElement>(this.buttonActionArray);
    });

    if(this.edit == true){
      this.endpointService.getAllButtonActionReportActive(this.selectedID).subscribe( data =>{
        this.buttonActionArrayActive = data['response'];
        for(let i = 0; i < this.buttonActionArrayActive.length; i++) this.bodyReport.buttonActionAsignats.push(this.buttonActionArrayActive[i].id);
      });
    }

    // EDITOR

    AngularEditorComponent.prototype.onTextAreaFocus = function (event) {
      if (this.focused && event) {  // note the "&& event" here
        event.stopPropagation();
        return;
      }
      this.focused = true;
      this.focusEvent.emit(event);
    };
    //

    this.fieldsToShow = [];

    this.htmlContent = this.bodyReport.mensaje;
    this.endpointService.getConditionsSelectorsValues(1, this.authService.inmoId, false).subscribe(
      data => {
        this.modulos = data['response'].modulo;
        this.endpointService.getFieldMRMValues().subscribe(dataa => {
          this.fields = dataa['response'];
          for (let i = 0; i < this.fields.length; i++) {
            const field = this.fields[i];
            if (field.id_modulo_primario === this.bodyReport.mensaje_id_modulo) {
              //this.addCanvasField(field);
              this.fieldsToShow.push(field);
            }
          }
          this.transformFieldTagsFromBackend();
        });
      });

    this.loadImages();
  }

  dropActionNoStep(ib, event: CdkDragDrop<string[]>) {
    moveItemInArray(this.buttonArray[ib].actionArray, event.previousIndex, event.currentIndex);
  }

  dropActionStep(is, ib, event: CdkDragDrop<string[]>) {
    moveItemInArray(this.stepArray[is].buttons[ib].actionArray, event.previousIndex, event.currentIndex);
  }

  drop(index, event: CdkDragDrop<string[]>) {
    moveItemInArray(this.stepArray[index].container, event.previousIndex, event.currentIndex);
  }

  dropStep(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.stepArray, event.previousIndex, event.currentIndex);
  }

  deleteContainer(indexStep, indexCont){
    if(this.stepArray[indexStep].container[indexCont].id != 0) this.dropContainerArray.push(this.stepArray[indexStep].container[indexCont].id);
    this.stepArray[indexStep].container.splice(indexCont, 1);
  }

  addContainer(index){

    let container = new Array();
    container.push({idType: 1, container: null, color: "#000000", id: 0});

    this.stepArray[index].container.push(container[0]);
  }

  deleteStep(indexStep){
    if(this.stepArray.length > 1){

      const dialogRef = this.dialog.open(MessageDialogComponent, {
        width: 'auto',
        height: 'auto',
        data: {
          message: '¿Seguro que quiere eliminar este stepper?',
          labelButton1: 'Cancelar',
          labelButton2: 'Eliminar'
        }
      });

      dialogRef.afterClosed().subscribe(data => {

        if (data === 2) {

          for (let x = 0; x < this.stepArray[indexStep].container.length; x++) this.deleteContainer(indexStep, x);
          if (this.stepArray[indexStep].id != 0) this.dropStepArray.push(this.stepArray[indexStep].id);
          this.stepArray.splice(indexStep, 1);

        }
      });
    }
    else{
      this.snackBar.open('No se puede eliminar más steppers','X',{
        duration: 7000, panelClass: ['red-snackbar']
      });
    }
  }

  editNameStep(indexStep){

    const dialogRef = this.dialog.open(InputDialogComponent, {
      width: 'auto',
      height: 'auto',
      data: {
        message: 'Introduce un nombre'
      }
    });

    dialogRef.afterClosed().subscribe(data => {

      if(data != null) this.stepArray[indexStep].name = data;
    });

  }

  addStep(){

    this.nStep += 1;

    let x = 0;

    let buttonArray = [];

    while (x < 3){

      let n = x +1;

      buttonArray.push({active: true, name: "Botón " + n + ": ", text: null, tooltip: null, action: null, colorText: "#000000",
        colorBackground: "#000000", colorEdge: "#000000", icon: "event", icon_color: "#000000", icon_type: 1,
        icon_tooltipe: null, icon_active: true, link: null, pantalla: 1, noti: null, order: n});

      x++;
    }

    let iconArray = [];
    iconArray.push({icon: "event", icon_color: "#000000", icon_type: 1, icon_tooltipe: null, icon_active: true});

    this.stepArray.push({container: [], buttons: buttonArray, name: "Step " + this.nStep, id: 0, icon: iconArray});
  }

  public iconoStep(indexStep, indexButton){
    let iconI;
    let icon_colorI;
    let icon_typeI;
    let icon_tooltipeI;
    let icon_activeI;

    if(indexButton != 0) {
      iconI = this.stepArray[indexStep].buttons[indexButton - 1].icon;
      icon_colorI = this.stepArray[indexStep].buttons[indexButton - 1].icon_color;
      icon_typeI = this.stepArray[indexStep].buttons[indexButton - 1].icon_type;
      icon_tooltipeI = this.stepArray[indexStep].buttons[indexButton - 1].icon_tooltipe;
      icon_activeI = this.stepArray[indexStep].buttons[indexButton - 1].icon_active;
    }else{
      iconI = this.stepArray[indexStep].icon[indexButton].icon;
      icon_colorI = this.stepArray[indexStep].icon[indexButton].icon_color;
      icon_typeI = this.stepArray[indexStep].icon[indexButton].icon_type;
      icon_tooltipeI = this.stepArray[indexStep].icon[indexButton].icon_tooltipe;
      icon_activeI = this.stepArray[indexStep].icon[indexButton].icon_active;
    }

    const dialogRef = this.dialog.open(IconScreenComponent,{
      width: 'auto',
      height: 'auto',
      data:{
        icon: iconI,
        icon_color: icon_colorI,
        icon_type: icon_typeI,
        icon_tooltipe: icon_tooltipeI,
        icon_active: icon_activeI
      }
    });

    dialogRef.afterClosed().subscribe( data=>{

      if(data != null){
        if(indexButton != 0){
          this.stepArray[indexStep].buttons[indexButton-1].icon = data[0].icon;
          this.stepArray[indexStep].buttons[indexButton-1].icon_color = data[0].color;
          this.stepArray[indexStep].buttons[indexButton-1].icon_type = data[0].type;
          this.stepArray[indexStep].buttons[indexButton-1].icon_tooltipe = data[0].tooltipe;
          this.stepArray[indexStep].buttons[indexButton-1].icon_active = data[0].active;
        }else{
          this.stepArray[indexStep].icon[indexButton].icon = data[0].icon;
          this.stepArray[indexStep].icon[indexButton].icon_color = data[0].color;
          this.stepArray[indexStep].icon[indexButton].icon_type = data[0].type;
          this.stepArray[indexStep].icon[indexButton].icon_tooltipe = data[0].tooltipe;
          this.stepArray[indexStep].icon[indexButton].icon_active = data[0].active;
        }
      }
    });

  }

  public icono(i){

    let iconI;
    let icon_colorI;
    let icon_typeI;
    let icon_tooltipeI;
    let icon_activeI;

    if(i == 0){

      iconI = this.bodyReport.icon;
      icon_colorI = this.bodyReport.icon_color;
      icon_typeI = this.bodyReport.icon_type;
      icon_tooltipeI = this.bodyReport.icon_tooltipe;
      icon_activeI = this.bodyReport.icon_active;

    }else{

      iconI = this.buttonArray[i-1].icon;
      icon_colorI = this.buttonArray[i-1].icon_color;
      icon_typeI = this.buttonArray[i-1].icon_type;
      icon_tooltipeI = this.buttonArray[i-1].icon_tooltipe;
      icon_activeI = this.buttonArray[i-1].icon_active;

    }

    const dialogRef = this.dialog.open(IconScreenComponent,{
      width: 'auto',
      height: 'auto',
      data:{
        icon: iconI,
        icon_color: icon_colorI,
        icon_type: icon_typeI,
        icon_tooltipe: icon_tooltipeI,
        icon_active: icon_activeI
      }
    });

    dialogRef.afterClosed().subscribe( data=>{

      if(data != null){
        if(i == 0){
          this.bodyReport.icon = data[0].icon;
          this.bodyReport.icon_color = data[0].color;
          this.bodyReport.icon_type = data[0].type;
          this.bodyReport.icon_tooltipe = data[0].tooltipe;
          this.bodyReport.icon_active = data[0].active;
        }else{

          this.buttonArray[i-1].icon = data[0].icon;
          this.buttonArray[i-1].icon_color = data[0].color;
          this.buttonArray[i-1].icon_type = data[0].type;
          this.buttonArray[i-1].icon_tooltipe = data[0].tooltipe;
          this.buttonArray[i-1].icon_active = data[0].active;
        }
      }
    });
  }

  public addImage(i) {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {

      const file = fileUpload.files[0];
      let reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = (fileList) => {

        const formData = new FormData();
        formData.append('file', file.data);
        file.inProgress = true;

        let url = reader.result;

        this.containerArray[i].container = {file: file, url: url, formData: formData};

      }

    };
    fileUpload.click();
  }

  private handleSelectorsStructure(data: ResponseInterface) {
    // this.moduloList = data['response'].modulo;
    this.moduloList = data['response'].modulo;
    //Nota: Hacerlo más dinámico

    this.endpointService.getModuleForEgo().subscribe(data =>{
      this.listaModulos = data['response'];
    });

    /*this.listaModulos = [
      { id: 2, name: 'Inmuebles' },
      { id: 5, name: 'Ofertas' },
      { id: 7, name: 'Usuarios' },
      { id: 11, name: 'Usuarios administradores'},
      { id: 13, name: 'Colaboraciones Inmos'},
      { id: 15, name: 'Inmobiliaria grupo'},
      { id: 16, name: 'Grupo inmobiliarias'},
      { id: 18, name: 'Follows'},
    ];*/

    this.selectedEvent.modulo = 7;
    this.fieldsList = data['response'].fields;
    this.numbersLists = [];
    this.stringsLists = [];
    this.datesLists = [];
    this.enumsLists = [];
    // this.functionsToExecute = [];
    // this.functionsToExecute = data['response'].functions_to_execute;
    this.externList = data['response'].extern;
    Object.keys(data['response'].enumsValues).forEach(list => {
      const tmplist = data['response'].enumsValues[list];
      this.enumsLists[list] = new Array();
      tmplist.forEach(element => {
        this.enumsLists[list].push({
          id: element.valor,
          name: element.nombre_frontend
        });
      });
    });
    this.moduloRModuloList = new Array();
    Object.keys(this.fieldsList).forEach(j => {
      this.moduloRModuloList[j] = [];
      this.numbersLists[j] = {
        items: new Array(),
        id: this.fieldsList[j].id_mod_prim
      };
      this.stringsLists[j] = {
        items: new Array(),
        id: this.fieldsList[j].id_mod_prim
      };
      this.datesLists[j] = {
        items: new Array(),
        id: this.fieldsList[j].id_mod_prim
      };
      /*
      this.enumsLists[j] = {
        items: new Array(),
        id: this.fieldsList[j].id_mod_prim
      };
      */
      Object.keys(this.fieldsList[j].mod_prim_list).forEach(i => {
        this.moduloRModuloList[j].push(this.fieldsList[j].mod_prim_list[i].id_mod_secund);
        this.numbersLists[j].items[i] = {
          items: new Array(),
          id: this.fieldsList[j].mod_prim_list[i].id_mod_secund
        };
        this.stringsLists[j].items[i] = {
          items: new Array(),
          id: this.fieldsList[j].mod_prim_list[i].id_mod_secund
        };
        this.datesLists[j].items[i] = {
          items: new Array(),
          id: this.fieldsList[j].mod_prim_list[i].id_mod_secund
        };
        /*
        this.enumsLists[j].items[i] = {
          items: new Array(),
          id: this.fieldsList[j].mod_prim_list[i].id_mod_secund
        };
        */
        this.datesLists[j].items[i].items.push({
          id: -1,
          nombre_backend: 'ahora',
          nombre_frontend: 'Ahora',
          tipo: 'string'
        });
        this.datesLists[j].items[i].items.push({
          id: -2,
          nombre_backend: 'hoy',
          nombre_frontend: 'Hoy',
          tipo: 'string'
        });
        Object.keys(this.fieldsList[j].mod_prim_list[i].mod_secund_list).forEach(k => {
          const field = this.fieldsList[j].mod_prim_list[i].mod_secund_list[k];
          switch (field.tipo) {
            case 'int':
            case 'double':
            case 'bit':
              this.numbersLists[j].items[i].items[field.id] = field;
              break;
            case 'string':
              this.stringsLists[j].items[i].items[field.id] = field;
              break;
            case 'date':
              this.datesLists[j].items[i].items[field.id] = field;
              break;
            case 'enum':
              /*
                this.enumsLists[j].items[i].items[field.id] = field;
              */
              break;
            default:
              break;
          }
        });
      });
    });
    this.operatorsList = data['response'].operators;
    this.typesList = data['response'].types;
    this.whenExecuteList = data['response'].when_execute;
    this.executeOverList = data['response'].execute_over;
  }

  getTime(hour: Date) {
    // this.horaEjecucion.hours = hour.getHours();
    // this.horaEjecucion.minutes = hour.getMinutes();
    this.bodyReport.horaEjecucion = hour;
    this.selectedEvent.hour = {
      hour: hour.getHours(),
      minute: hour.getMinutes(),
      second: 0
    };
  }


  getFinalTime(hour: Date) {
    // this.horaEjecucion.hours = hour.getHours();
    // this.horaEjecucion.minutes = hour.getMinutes();
    this.bodyReport.horaFinal = hour;
    this.selectedEvent.final_hour = {
      hour: hour.getHours(),
      minute: hour.getMinutes(),
      second: 0
    };
    let auxDate = this.selectedEvent.fecha_final_date;
    auxDate.setHours(hour.getHours());
    auxDate.setMinutes(hour.getMinutes());
    auxDate.setSeconds(0);
    this.selectedEvent.fecha_final = this.datepipe.transform(auxDate, 'y-MM-dd HH:mm:ss');
  }

  getDate(executionDate: Date) {
    this.bodyReport.fechaEjecucion = executionDate;
    this.selectedEvent.executionDate = {
      year: executionDate.getFullYear(),
      month: executionDate.getMonth(),
      day: executionDate.getDate()
    };
  }

  getFinalDate(executionDate: Date) {
    this.selectedEvent.fecha_final_date = executionDate;
    let auxDate = executionDate;
    auxDate.setHours(executionDate.getHours());
    auxDate.setMinutes(executionDate.getMinutes());
    auxDate.setSeconds(executionDate.getSeconds());
    this.selectedEvent.fecha_final = this.datepipe.transform(auxDate, 'y-MM-dd HH:mm:ss');
  }

  private initEmptyLists() {
    this.initDaysOfWeek();
    this.posibleModulesForActions = [];
    this.externOptionsListsList = [];
  }

  private initDaysOfWeek() {
    this.daysOfWeek = [];
    this.daysOfWeek.push({
      name: 'Lunes',
      id: 0
    });
    this.daysOfWeek.push({
      name: 'Martes',
      id: 1
    });
    this.daysOfWeek.push({
      name: 'Miércoles',
      id: 2
    });
    this.daysOfWeek.push({
      name: 'Jueves',
      id: 3
    });
    this.daysOfWeek.push({
      name: 'Viernes',
      id: 4
    });
    this.daysOfWeek.push({
      name: 'Sábado',
      id: 5
    });
    this.daysOfWeek.push({
      name: 'Domingo',
      id: 6
    });
  }

  public getCompatibleModuloList() {
    return this.moduloList.filter(i => {
      if (this.moduloRModuloList[this.selectedEvent.modulo] !== undefined) {
        return -1 !== this.moduloRModuloList[this.selectedEvent.modulo].indexOf(i.id);
      } else {
        return false;
      }
    });
  }

  public onMainModuloChange() {
    this.selectedEvent.conditions = [];
    this.selectedEvent.actions = [];
    this.templatesNamesList = [];
    this.canAddConditionContainer = this.selectedEvent.conditions.length === this.getCompatibleModuloList().length;

    const observersArray = [];
    observersArray.push(this.endpointService.getFunctionsToExecuteByModule(this.selectedEvent.modulo));
    observersArray.push(this.endpointService.getAllTemplatesNamesByModule(this.authService.inmoId, this.selectedEvent.modulo));

    forkJoin(observersArray).subscribe(
      result => {
        this.functionsToExecute = result[0]['response'];
        this.templatesNamesList = result[1]['response'];
      });
  }

  public addNewWorkflowEventConditionContainer() {
    if (!(this.dataLoaded && this.getCompatibleModuloList().length === 0)) {
      const ContainerCondition = new ModulConditionContainer(this.moduloRModuloList[this.selectedEvent.modulo][0]);
      const Condition = new EventConditionBody();
      Condition.clearBody();
      const obj = this.fieldsList[this.selectedEvent.modulo.toString()].mod_prim_list[ContainerCondition.conditionModulo.toString()].mod_secund_list;
      Condition.field = obj[Object.keys(obj)[0]].id;
      this.selectedEvent.conditions.push(ContainerCondition);
      ContainerCondition.containerConditions.push(Condition);
      this.posibleModulesForActions.push(ContainerCondition.conditionModulo);
      this.canAddConditionContainer = this.selectedEvent.conditions.length === this.getCompatibleModuloList().length;
    }
  }

  public deleteWorkflowEventContainer(condition: ModulConditionContainer) {
    const index = this.selectedEvent.conditions.indexOf(condition);
    const indexmodulo = this.posibleModulesForActions.indexOf(condition.conditionModulo);
    if (index > -1) {
      this.selectedEvent.conditions.splice(index, 1);
    }
    if (indexmodulo > -1) {
      this.posibleModulesForActions.splice(indexmodulo, 1);
    }
    this.canAddConditionContainer = this.selectedEvent.conditions.length === this.getCompatibleModuloList().length;
  }

  public onChangeModuloFromCondition(condition: ModulConditionContainer) {
    const field = this.getFieldList(this.selectedEvent.modulo, condition.conditionModulo)[0].id;
    condition.containerConditions.forEach(
      element => {
        element.field = field;
        element.operator = 1;
        element.type = 1;
      });
    condition.stringFieldsInConditions = this.getStrings(condition.conditionModulo);
    condition.dateFieldsInConditions = this.getDates(condition.conditionModulo);
    condition.numberFieldsInConditions = this.getNumbers(condition.conditionModulo);
  }

  public getFieldList(primaryModuloId, secondaryModuloId) {
    const list = new Array();
    if (this.fieldsList[primaryModuloId.toString()] !== undefined &&
      this.fieldsList[primaryModuloId.toString()].mod_prim_list[secondaryModuloId.toString()] !== undefined) {
      Object.keys(this.fieldsList[primaryModuloId.toString()].mod_prim_list[secondaryModuloId.toString()].mod_secund_list).forEach(
        element => {
          list.push(this.fieldsList[primaryModuloId.toString()].mod_prim_list[secondaryModuloId.toString()].mod_secund_list[element]);
        }
      );
      return list;
    } else {
      return [];
    }
  }

  public getNumbers(modulo) {
    /*return this.numbersLists[this.selectedEvent.modulo].items[modulo].items.filter(
      i => {
        return i != null || i !== undefined || i !== empty;
      });*/

    let auxArrayNumber = [];
    let newModule = {moduleName: '', items: []};
    this.numbersLists[this.selectedEvent.modulo].items[modulo].items.filter(i => {
      return i !== null || true || i !== empty;
    }).map(elem => {
      newModule.items.push(elem);
    });
    newModule.moduleName = this.moduloList.filter(e => e.id === modulo)[0].modul_name;
    auxArrayNumber.push(newModule);

    if (this.selectedEvent.modulo !== modulo) {
      newModule = {moduleName: '', items: []};
      this.numbersLists[this.selectedEvent.modulo].items[this.selectedEvent.modulo].items.filter(i => {
        return i !== null || true || i !== empty;
      }).map(elem => {
        newModule.items.push(elem);
      });
      newModule.moduleName = this.moduloList.filter(e => e.id === this.selectedEvent.modulo)[0].modul_name;
      auxArrayNumber.push(newModule);
    }
    return auxArrayNumber;
  }

  public getDates(modulo) {
    let auxArrayDates = [];
    let newModule = {moduleName: '', items: []};
    this.datesLists[this.selectedEvent.modulo].items[modulo].items.filter(i => {
      return i !== null || true || i !== empty;
    }).map(elem => {
      newModule.items.push(elem);
    });
    newModule.moduleName = this.moduloList.filter(e => e.id === modulo)[0].modul_name;
    auxArrayDates.push(newModule);
    if (this.selectedEvent.modulo !== modulo) {
      newModule = {moduleName: '', items: []};
      this.datesLists[this.selectedEvent.modulo].items[this.selectedEvent.modulo].items.filter(i => {
        return i !== null || true || i !== empty;
      }).map(elem => {
        newModule.items.push(elem);
      });
      newModule.moduleName = this.moduloList.filter(e => e.id === this.selectedEvent.modulo)[0].modul_name;
      auxArrayDates.push(newModule);
    }

    /*return this.datesLists[this.selectedEvent.modulo].items[modulo].items.filter(
      i => {
        return i != null || i !== undefined || i !== empty;
      });*/
    return auxArrayDates;
  }

  public getStrings(modulo) {
    let auxArrayString = [];
    let newModule = {moduleName: '', items: []};
    this.stringsLists[this.selectedEvent.modulo].items[modulo].items.filter(i => {
      return i != null || i !== undefined;
    }).map(elem => {
      newModule.items.push(elem);
    });
    newModule.moduleName = this.moduloList.filter(e => e.id === modulo)[0].modul_name;
    auxArrayString.push(newModule);

    if (this.selectedEvent.modulo !== modulo) {
      newModule = {moduleName: '', items: []};
      this.stringsLists[this.selectedEvent.modulo].items[this.selectedEvent.modulo].items.filter(i => {
        return i != null || i !== undefined;
      }).map(elem => {
        newModule.items.push(elem);
      });
      newModule.moduleName = this.moduloList.filter(e => e.id === this.selectedEvent.modulo)[0].modul_name;
      auxArrayString.push(newModule);
    }
    return auxArrayString;
  }

  public onChangeField(condition) {
    condition.type = this.typesList[0].id;
    condition.valueList = [];
  }

  public getOperatorList(field, modulo) {
    const Field = this.getFieldByModulosAndId(this.selectedEvent.modulo, modulo, field);
    if (Field != undefined) {
      switch (Field.tipo) {
        case 'date':
        case 'int':
        case 'double':
        case 'bit':
          return this.operatorsList;
        case 'enum':
        case 'string':
          const arr = new Array();
          arr.push(this.operatorsList[0]);
          arr.push(this.operatorsList[5]);
          return arr;
        case 'extern':
          const arrr = new Array();
          arrr.push(this.operatorsList[0]);
          return arrr;
        default:
          return new Array();
      }
    }
    return [];
  }

  public getFieldByModulosAndId(primaryModuloId, secondaryModuloId, fieldId) {
    return this.fieldsList[primaryModuloId?.toString()].mod_prim_list[secondaryModuloId?.toString()].mod_secund_list[fieldId?.toString()];
  }

  public getTypesForField(conditionField) {
    const auxTypes = new Array();
    if (conditionField != undefined) {
      if (conditionField.tipo === 'enum') {
        auxTypes.push(this.typesList[0]);
        auxTypes.push(this.typesList[2]);
      } else if (conditionField.tipo === 'extern') {
        auxTypes.push(this.typesList[0]);
      } else {
        auxTypes.push(this.typesList[0]);
        auxTypes.push(this.typesList[1]);
      }
    }
    return auxTypes;
  }

  public deleteWorkflowEventContainerCondition(container: ModulConditionContainer, condition: EventConditionBody) {
    const index = container.containerConditions.indexOf(condition);
    if (index > -1) {
      container.containerConditions.splice(index, 1);
    }
  }

  public getFieldTypeByModulosAndId(primaryModuloId, secondaryModuloId, fieldId) {
    if (this.fieldsList[primaryModuloId?.toString()].mod_prim_list[secondaryModuloId?.toString()].mod_secund_list[fieldId?.toString()] !== undefined) {
      return this.fieldsList[primaryModuloId?.toString()].mod_prim_list[secondaryModuloId?.toString()].mod_secund_list[fieldId?.toString()]['tipo'];
    }

    return 'none';
  }

  public getOptionsForEnum(modulo: number, fieldd) {
    const field = this.getFieldByModulosAndId(this.selectedEvent.modulo, modulo, fieldd);
    if (field != undefined) {
      const l = this.enumsLists[field.nombre_backend];
      return l;
    }
    return [];
  }

  public getExternOptions(modulo: number, field: number) {
    if (!this.loadingExternOptions) {
/*      if (this.externOptionsListsList[modulo] === null || this.externOptionsListsList[modulo] === undefined) {
        this.externOptionsListsList[modulo] = this.externOptionsListsList[modulo] === null
        || this.externOptionsListsList[modulo] === undefined ? [] : this.externOptionsListsList[modulo];
      }*/
      this.externOptionsListsList[modulo] = (this.externOptionsListsList[modulo] === null
      || this.externOptionsListsList[modulo] === undefined) ? [] : this.externOptionsListsList[modulo];
      if (this.externOptionsListsList[modulo][field] === null) {
        this.loadingExternOptions = true;
        this.endpointService.getListExternField(this.externList[modulo][field]).subscribe(
          data => {
            this.externOptionsListsList[modulo][field] = data['response'];
            this.loadingExternOptions = false;
          }
        );
      } else {
        return this.externOptionsListsList[modulo][field];
      }
    }
    return 0;
  }

  public addNewWorkflowEventCondition(container: ModulConditionContainer) {
    const Condition = new EventConditionBody();
    Condition.clearBody();
    const obj = this.fieldsList[this.selectedEvent.modulo.toString()].mod_prim_list[container.conditionModulo.toString()].mod_secund_list;
    Condition.field = obj[Object.keys(obj)[0]].id;
    container.containerConditions.push(Condition);
  }

  private handleEventData(eventdata) {
    this.selectedEvent.handleResponse(eventdata['response'].body);
    this.selectedEvent.handleDaysOfRepetitionResponse(eventdata['response'].daysOfRepetition);
    this.selectedEvent.handleResponseConditions(eventdata['response'].conditions);

    this.inicializaCondiciones(this.selectedEvent.conditions);
    this.selectedEvent.handleResponseActions(eventdata['response'].actions);
    this.bodyReport.horaEjecucion = this.setTime(this.selectedEvent.hour);
    this.bodyReport.horaFinal = this.setTime(this.selectedEvent.final_hour);
    this.bodyReport.fechaEjecucion = this.setDate(this.selectedEvent.executionDate);
    this.endpointService.getFunctionsToExecuteByModule(this.selectedEvent.modulo).subscribe(result => {
      this.functionsToExecute = result['response'];
    });
  }

  private inicializaCondiciones(conditions: Array<ModulConditionContainer>) {
    // Inicializo los posibles campos con los que puedo comparar en condiciones
    conditions.forEach(condition => {
      condition.stringFieldsInConditions = this.getStrings(condition.conditionModulo);
      condition.dateFieldsInConditions = this.getDates(condition.conditionModulo);
      condition.numberFieldsInConditions = this.getNumbers(condition.conditionModulo);
    })
  }

  setTime(hour: any) {
    let d1 = new Date();
    d1.setHours(hour.hour);
    d1.setMinutes(hour.minute);
    d1.setSeconds(hour.second);
    return d1;
  }

  setDate(date: any) {
    let d1 = new Date();
    d1.setFullYear(date.year);
    d1.setMonth(date.month);
    d1.setDate(date.day);
    return d1;
  }

  public createEvent(id) {

    this.selectedEvent.insertEgo(id, this.bodyReport.active , this.bodyReport.name + "_event");
    this.selectedEvent.consulta_estatica = this.selectedEvent.consulta_estatica ? 1 : 0;
    this.selectedEvent.status = this.bodyReport.active ? 1 : 0;
    this.selectedEvent.frontendStatus = this.bodyReport.activeFrontend ? 1 : 0;
    this.endpointService.insertEvent(this.selectedEvent, 2).subscribe(data => {
      this.endpointService.updateReportEvent(id, data['response'].id).subscribe( ()=>{});
    });

  }

  public updateEvent() {

    this.selectedEvent.updateEgo(this.bodyReport.active , this.bodyReport.name + "_event");
    this.selectedEvent.consulta_estatica = this.selectedEvent.consulta_estatica ? 1 : 0;
    this.selectedEvent.status = this.bodyReport.active ? 1 : 0;
    this.selectedEvent.frontendStatus = this.bodyReport.activeFrontend ? 1 : 0;
    this.endpointService.updateEvent(this.selectedEvent).subscribe(data => {

    });

  }

  isHome(){
    if(this.bodyReport.idType === 3){
      this.bodyReport.myScreen  = new Array();
      this.bodyReport.myScreen.push(1);
    }

    if(this.bodyReport.idType === 6){
      this.bodyReport.useMode = 0;
    }
  }

  radioUseMode(){
    if(this.bodyReport.useMode === 0) this.bodyReport.personalizado = false;
  }

  radioInteractiveMode(){
    if(this.bodyReport.interactive === 0) this.bodyReport.num_veces = 0;
  }


  // EDITOR

  public editorDataChanged(editor){
    // this.cursorPosition = editor;
  }

  public onReady( editor ) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  displayFnType(object?: any): string | undefined {
    return object ? object.tipo : undefined;
  }

  displayFn(object?: any): string | undefined {
    return object ? object.name : undefined;
  }

  public testRegex() {
    // https://regex101.com/
    const splitted = this.bodyReport.mensaje.split('<img id="campo');
    let stringForBackend = splitted[0];
    for (let i = 1; i < splitted.length; i++) {
      stringForBackend += '---' + splitted[i].split('"')[0] + '---' + splitted[i].split('/>')[1];
    }
  }

  // public createTemplate() {
  //
  //   let valor: any[];
  //   this.emailpatternDetails.contents[this.emailpatternDetails.language - 1] = this.htmlContent;
  //   valor = this.transformFieldTagsForBackend();
  //   this.htmlContent = valor[this.emailpatternDetails.language - 1];
  //   this.emailpatternDetails.cabeceras[this.emailpatternDetails.language - 1] = this.headerHtmlContent;
  //   this.emailpatternDetails.contents[this.emailpatternDetails.language - 1] = this.htmlContent;
  //   this.emailpatternDetails.pies_de_pagina[this.emailpatternDetails.language - 1] = this.footerHtmlContent;
  //   this.endpointService.insertTemplate(this.emailpatternDetails, this.authService.inmoId).subscribe(
  //     data => {
  //       this.onCreateEmitter.emit();
  //       this.router.navigate([this.routes.WORKFLOW]);
  //     }
  //   );
  // }

  // public updateTemplate() {
  //   const date = new Date();
  //   this.emailpatternDetails.fecha_modificacion = (date.getDate()) + '-' +
  //     (date.getMonth() + 1) + '-' +
  //     (date.getFullYear());
  //   let valor: any[];
  //   this.emailpatternDetails.contents[this.emailpatternDetails.language - 1] = this.htmlContent;
  //   valor = this.transformFieldTagsForBackend();
  //   this.htmlContent = valor[this.emailpatternDetails.language - 1];
  //   this.emailpatternDetails.cabeceras[this.emailpatternDetails.language - 1] = this.headerHtmlContent;
  //   this.emailpatternDetails.contents[this.emailpatternDetails.language - 1] = this.htmlContent;
  //   this.emailpatternDetails.pies_de_pagina[this.emailpatternDetails.language - 1] = this.footerHtmlContent;
  //   // this.emailpatternDetails.contents = valor;
  //   this.addTrackingLink();
  //   this.endpointService.updateTemplate(this.emailpatternDetails).subscribe(
  //     data => {
  //       this.onUpdateEmitter.emit();
  //       this.router.navigate([this.routes.WORKFLOW]);
  //     }
  //   );
  // }

  public onModuloChange() {
    //this.clearCanvasFields();
    this.fieldsToShow = [];
    for (let i = 0; i < this.fields.length; i++) {
      const field = this.fields[i];
      if (field.id_modulo_primario === this.bodyReport.mensaje_id_modulo) {
        //this.addCanvasField(field);
        this.fieldsToShow.push(field);
      }
    }
    this.prevModulo = this.bodyReport.mensaje_id_modulo;
  }


  private clearCanvasFields() {
    for (let i = 0; i < this.fields.length; i++) {
      const field = this.fields[i];
      if (field.id_modulo_primario === this.prevModulo) {
        const element = document.getElementById('canvasContainer' + field['nombre_unico']);
        element.parentNode.removeChild(element);
      }
    }
  }

  private transformFieldTagsFromBackend() {
      if (this.bodyReport.mensaje !== null && this.bodyReport.mensaje !== undefined) {

        const splittedText = this.bodyReport.mensaje.split('---');
        let test = '';
        let i = 0;
        for (; i < splittedText.length - 1; i += 2) {
          test += splittedText[i];
          test += '<mark id="chip">[' + splittedText[i + 1] + ']'+ '</mark>';
        }
        if (i === splittedText.length - 1) {
          test += splittedText[i];
        }
        this.bodyReport.mensaje = test;
      } else {
        this.bodyReport.mensaje = "";
      }
  }

  fnSetPlaceHolder(_value: string) {
    let sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      let range = sel.getRangeAt(0);
      range.insertNode(document.createTextNode(''));
    }
  }

  public func (event){
    const start = event.target.selectionStart;
    const end = event.target.selectionEnd;
  }

  public selectedField(fieldName) {
    let textarea = document.getElementById('txtArea');

    let sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      let range = sel.getRangeAt(0);
      let value = document.createElement("span");
      //value.setAttribute("id", fieldName['nombre_backend']);
      //value.innerHTML = '[' + fieldName['nombre_frontend'] + ']';
      value.innerHTML = '<mark id="chip">' + '[' + fieldName['nombre_unico'] + ']' + '</mark>&nbsp;';
      range.insertNode(document.body.appendChild(value));
      this.bodyReport.mensaje = String(document.getElementsByClassName("angular-editor-textarea")[1].innerHTML);
      value.remove();
    }
  }

  public addCanvasField(fieldName: any) {

    const div = document.createElement('div') as HTMLDivElement;
    div.className = 'col-sm-6';
    div.id = 'canvasContainer' + (fieldName['nombre_unico']);
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    canvas.id = 'canvas' + fieldName['id_modulo_secundario'] + '-' + fieldName['nombre_unico'];
    canvas.width = 100;
    canvas.height = 20;
    canvas.draggable = true;
    canvas.style.position = 'relative';
    canvas.style.border = '1px solid';

    canvas.addEventListener('click',
      (event) => {
        this.bodyReport.mensaje += '<img id="' + 'campo' + fieldName['id_modulo_secundario'] + '-' + fieldName['nombre_unico'] + '" src="' + canvas.toDataURL() + '" />' + ' ';
      });

    /*const body = document.getElementById('canvasRows');
		body.appendChild(div);
		div.appendChild(canvas);*/

    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'grey';
    ctx.fillRect(0, 0, 200, 20);
    ctx.fillStyle = 'black';
    ctx.font = '14px Arial';
    ctx.textAlign = 'center';
    ctx.fillText(fieldName['nombre_frontend'], canvas.width / 2, canvas.height * 3 / 5);
  }

  public onClickReturnButton() {
    // this.onClickReturn.emit();
  }

  public change(){
  }

  getHTMLContent(event)
  {
    if (!this.iseditingView) {
      this.htmlContent = event.target.innerText;
    }
  }
  onViewMode(event) {
    this.iseditingView = event;
  }

  public updateMensaje() {
    return this.htmlContent;
  }

  public loadImages() {
    this.endpointService.getAllEgoImages().subscribe(
      data => {
        const tmpl = data['response'];
        if (this.images.length !== tmpl.length) {
          this.images = [];
          if (tmpl != null && tmpl !== undefined) {
            tmpl.forEach(element => {
              const subs = element.split('vhosts/');
              const subsubs = subs[1].split('movin.cloud/');
              this.images.push({
                src: Values.ROOT_URL + '/' + subsubs[2],
                selected: false
              });
            });
          }
        }
      }
    );
  }

  public showImageSelector(indexStep, indexCont) {
    const bottomSheet = this._bottomSheet.open(BottomSheetImageSelector, {
      data : {imageType : 2, images : this.images}
    });

    bottomSheet.afterDismissed().subscribe(data => {
      this.loadImages();
      let i = 0;
      while (this.images[i].selected != true && i < this.images.length){
        i++
      }
      if(this.images[i].selected == true) this.stepArray[indexStep].container[indexCont].container = this.images[i].src;

    });
  }

  displayFnPlantilla(object?: any): string {

    this.filteredOptionsPlantilla = this.plantilla.get('plant').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre),
        map(name => name ? this._filterPlantilla(name) : this.notification.slice())
      );

    return object && object.nombre ? object.nombre : undefined;
  }

  displayFnFunctionalArea(object?: any): string {

    this.filteredOptionsFunctionalArea = this.functionalArea.get('fa').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre),
        map(name => name ? this._filterFunctionalArea(name) : this.functionalAreas.slice())
      );

    return object && object.nombre ? object.nombre : undefined;
  }


  private _filterPlantilla(name: string): any[] {
    const filterValue = name.toLowerCase();

    const inmoFilterd = this.notification.filter(option => option.nombre != null && option.nombre.toLowerCase().includes(filterValue));

    return inmoFilterd;
  }

  private _filterFunctionalArea(name: string): any[] {
    const filterValue = name.toLowerCase();

    const inmoFilterd = this.functionalAreas.filter(option => option.nombre != null && option.nombre.toLowerCase().includes(filterValue));

    return inmoFilterd;
  }

  getIdPlantilla(select: any, indexButton: number, indexStepper: number, ia: number){
    console.log("select:", select)
    console.log("indexButton: ", indexButton)
    console.log("indexStepper: ", indexStepper)
    console.log("ia: ", ia)
    this.stepArray[indexStepper].buttons[indexButton].actionArray[ia].id = select.id;
    this.plantilla.controls['plant'].setValue('');
  }

  getIdPlantillaNostep(select: any, indexButton: number, ia: number){
    console.log("select:", select)
    console.log("indexButton: ", indexButton)
    console.log("ia: ", ia)
    this.buttonArray[indexButton].actionArray[ia].id = select.id;
    this.plantilla.controls['plant'].setValue('');
  }

  getNamePlantilla(ib, is, ia){
    return this.notification.find(p => p.id == this.stepArray[is].buttons[ib].actionArray[ia].id).nombre;
  }

  getNamePlantillaNostep(ib, ia){
    return this.notification.find(p => p.id == this.buttonArray[ib].actionArray[ia].id).nombre;
  }

  getIdFunctionalArea(select: any, indexButton: number, indexStepper: number, ia: number){
    this.stepArray[indexStepper].buttons[indexButton].actionArray[ia].id = select.id;
    this.functionalArea.controls['fa'].setValue('');
  }

  getIdFunctionalAreaNostep(select: any, indexButton: number, ia: number){
    this.buttonArray[indexButton].actionArray[ia].id = select.id;
    this.functionalArea.controls['a'].setValue('');
  }

  getNameFunctionalArea(ib, is, ia){
    return this.functionalAreas.find(p => p.id == this.stepArray[is].buttons[ib].actionArray[ia].id).nombre;
  }

  getNameFunctionalAreaNostep(ib, ia){
    return this.functionalAreas.find(p => p.id == this.buttonArray[ib].actionArray[ia].id).nombre;
  }


  displayFnPlantillaSuperior(object?: any): string {

    this.filteredOptionsPlantillaSuperior = this.plantilla.get('plant').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre),
        map(name => name ? this._filterPlantillaSuperior(name) : this.notificationSuperior.slice())
      );

    return object && object.nombre ? object.nombre : undefined;
  }

  private _filterPlantillaSuperior(name: string): any[] {
    const filterValue = name.toLowerCase();

    const inmoFilterd = this.notificationSuperior.filter(option => option.nombre.toLowerCase().includes(filterValue));

    return inmoFilterd;
  }

  getIdPlantillaSuperior(select: any, indexButton: number, indexStepper: number, ia: number){
    this.stepArray[indexStepper].buttons[indexButton].actionArray[ia].id = select.id;
    this.plantilla.controls['plant'].setValue('');
  }

  getIdPlantillaSuperiorNostep(select: any, indexButton: number, ia){
    this.buttonArray[indexButton].actionArray[ia].id = select.id;
    this.plantilla.controls['plant'].setValue('');
  }

  getNamePlantillaSuperior(ib, is, ia){
    return this.notificationSuperior.find(p => p.id == this.stepArray[is].buttons[ib].actionArray[ia].id).nombre;
  }

  getNamePlantillaSuperiorNostep(ib, ia){
    return this.notificationSuperior.find(p => p.id == this.buttonArray[ib].actionArray[ia].id).nombre;
  }

  displayFnPlantillaSuperiorHome(object?: any): string {

    this.filteredOptionsPlantillaSuperiorHome = this.plantilla.get('plant').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre),
        map(name => name ? this._filterPlantillaSuperiorHome(name) : this.notificationSuperiorHome.slice())
      );

    return object && object.nombre ? object.nombre : undefined;
  }

  private _filterPlantillaSuperiorHome(name: string): any[] {
    const filterValue = name.toLowerCase();

    const inmoFilterd = this.notificationSuperiorHome.filter(option => option.nombre.toLowerCase().includes(filterValue));

    return inmoFilterd;
  }

  getIdPlantillaSuperiorHome(select: any, indexButton: number, indexStepper: number, ia: number){
    this.stepArray[indexStepper].buttons[indexButton].actionArray[ia].id = select.id;
    this.plantilla.controls['plant'].setValue('');
  }

  getIdPlantillaSuperiorHomeNostep(select: any, indexButton: number, ia){
    this.buttonArray[indexButton].actionArray[ia].id = select.id;
    this.plantilla.controls['plant'].setValue('');
  }

  getNamePlantillaSuperiorHome(ib, is, ia){
    return this.notificationSuperiorHome.find(p => p.id == this.stepArray[is].buttons[ib].actionArray[ia].id).nombre;
  }

  getNamePlantillaSuperiorHomeNostep(ib, ia){
    return this.notificationSuperiorHome.find(p => p.id == this.buttonArray[ib].actionArray[ia].id).nombre;
  }


  displayFnMarketplace(object?: any): string {

    this.filteredOptionsMarketplace = this.plantilla.get('plant').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre),
        map(name => name ? this._filterMarketplace(name) : this.marketArray.slice())
      );

    return object && object.nombre ? object.nombre : undefined;
  }

  private _filterMarketplace(name: string): any[] {
    const filterValue = name.toLowerCase();

    const inmoFilterd = this.marketArray.filter(option => option.nombre.toLowerCase().includes(filterValue));

    return inmoFilterd;
  }

  getIdMarketplace(select: any, indexButton: number, indexStepper: number){
    this.stepArray[indexStepper].buttons[indexButton].noti = select.id;
    this.plantilla.controls['plant'].setValue('');
  }

  getNameMarketplace(ib, is, ia){
    return this.marketArray.find(p => p.id == this.stepArray[is].buttons[ib].actionArray[ia].id).nombre;
  }

  getNameMarketplaceNostepe(ib, ia){
    return this.marketArray.find(p => p.id == this.buttonArray[ib].actionArray[ia].id).nombre;
  }

  displayFnKnowledgeplace(object?: any): string {

    this.filteredOptionsKnowledge = this.plantilla.get('plant').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre),
        map(name => name ? this._filterKnowledgeplace(name) : this.knowledgeArray.slice())
      );

    return object && object.nombre ? object.nombre : undefined;
  }

  private _filterKnowledgeplace(name: string): any[] {
    const filterValue = name.toLowerCase();

    const inmoFilterd = this.knowledgeArray.filter(option => option.nombre.toLowerCase().includes(filterValue));

    return inmoFilterd;
  }

  getIdKnowledge(select: any, indexButton: number, indexStepper: number){
    this.stepArray[indexStepper].buttons[indexButton].noti = select.id;
    this.plantilla.controls['plant'].setValue('');
  }

  getNameKnowledge(ib, is, ia){
    return this.knowledgeArray.find(p => p.id == this.stepArray[is].buttons[ib].actionArray[ia].id).nombre;
  }

  getNameKnowledgeNostep(ib, ia){
    return this.knowledgeArray.find(p => p.id == this.buttonArray[ib].actionArray[ia].id).nombre;
  }

  actionChangeNoStep(ib, ia){

    this.buttonArray[ib].actionArray[ia].id = null;
    this.plantilla.controls['plant'].setValue('');

  }

  actionChange(ib, is, ia){

    this.stepArray[is].buttons[ib].actionArray[ia].id = null;
    this.plantilla.controls['plant'].setValue('');

  }

  displayFnJourney(object?: any): string {

    if(object) this.bodyReport.journey = object.id;

    this.filteredOptionsJourney = this.journeyGroup.get('journey').valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.nombre),
        map(name => name ? this._filterJourney(name) : this.journeyArray.slice()),
      );

    return object && object.nombre ? object.nombre : undefined;
  }

  private _filterJourney(name: string): any[] {
    const filterValue = name.toLowerCase();

    const softFilterd = this.journeyArray.filter(option => option.nombre.toLowerCase().includes(filterValue));

    return softFilterd;
  }

  getNameJourney(id){
    return this.journeyArray.find(p => p.id == id).nombre;
  }

  addActionStep(ib, is){
    this.stepArray[is].buttons[ib].actionArray.push({action: 1, text: null, id: null});
  }

  addActionNoStep(ib){
    this.buttonArray[ib].actionArray.push({action: 1, text: null, id: null});
  }

  deleteActionStep(ib, is, ia){
    this.stepArray[is].buttons[ib].actionArray.splice(ia, 1);
  }

  deleteActionNoStep(ib, ia){
    this.buttonArray[ib].actionArray.splice(ia, 1);
  }

  // updateMensaje = new Promise( ((resolve, reject) => {
  //   this.bodyReport.mensaje = this.htmlContent;
  //   this.authService.isUserSessionActive().
  //   resolve();
  // }));
}
