import {Component, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { DeviceDetectorService } from "ngx-device-detector";

import { SetPasswordComponent } from './set-password/set-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { EndpointService } from '../services/generic/endpoint.service';
import { AuthService } from '../services/auth/auth.service';

import { Strings } from '../../strings/strings';
import { Values } from '../../values/values';
import { ON_OFF_ANIMATION } from '../animations/on-off.animation';
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {CdkTextareaAutosize} from "@angular/cdk/text-field";
import {ChatService} from "../services/chat.service";
import {GenericService} from "../services/generic/generic.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: [ON_OFF_ANIMATION]
})

export class LoginComponent implements OnInit {

  public hide: boolean; // Mostrar o no el password
  public user: string;
  public password: string;
  private routes: any;
  public strings: any;
  public first = false;
  form: UntypedFormGroup;
  wrongMail = false;
  wrongName = false;
  wrongInmoName = false;
  wrongComment = false;
  wrongForm = false;
  public name: string = null;
  public inmoName: string = null;
  public phone: string = null;
  public mail: string = null;
  public privacidad = false;
  public newsletter = false;
  public comentario: string = null;

  public validate: boolean;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  @Output() onLogin2Emitter = new EventEmitter();

  constructor(private endpointService: EndpointService,
              public authService: AuthService,
              public snackBar: MatSnackBar,
              private router: Router,
              public dialog: MatDialog,
              private fb: UntypedFormBuilder,
              public deviceService: DeviceDetectorService,
              private _bottomSheet: MatBottomSheet,
              //public dialogRef: MatDialogRef<LoginComponent>,
              public chatService: ChatService,
              private genericService : GenericService)
  {
    this.hide = true;
    this.user = null;
    this.password = null;
    this.routes = Values.ROUTES;
    this.strings = Strings.LOGIN;
  }

  ngOnInit() {
    this.validate = false;

    if (this.authService.isUserSessionActive()) {
      // Joan: Ho descomento...
      console.log('routing');
      //this.router.navigate([this.routes.LANDING]);
    } else {
      this.form = this.fb.group({
        mail: [this.mail],
        name: [this.name],
        phone: [this.phone],
        inmoName: [this.phone],
        comentario: [this.comentario],
        privacidad: [this.privacidad],
        newsletter: [this.newsletter]
      });
    }
  }

  public validateEmail(): void{
    this.endpointService.validateUser(this.user).subscribe(data => {
      if(data['errorCode'] === 0){
        if (data['response'][0].primer_log === 1) {
          //TODO IBRA CHAT: Registrar el usuario en la bd de firebase
          this.setPassword(data['response'][0]);
        }
        else this.validate = true;
      }
      else {
        if(data['errorCode'] == -1){
          this.snackBar.open('Debes introducir tu email de acceso', 'X', {
            duration: 6000, panelClass: ['red-snackbar']
          });
        }
        if(data['errorCode'] == -3){
          this.snackBar.open('Parece que este usuario no existe', 'X', {
            duration: 6000, panelClass: ['red-snackbar']
          });
        }
      }
    });
  }

  public changeEmail(): void{
    this.validate = false;
    this.user = null;
  }

  public loginUser(): void {
    // TODO JOAN: Treure funció loginUserOld i el login user treure del backend el que no cal doncs només ha de fer el control d'errors de si ha pogut loguejar
    this.endpointService.loginUser(this.user, this.password).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.authService.finishToLogued = false;
        this.authService.userId = data['response'][0].id;
        localStorage.setItem('_i_', data['response'][0].id);
        localStorage.setItem('_itk_', data['response'][0].usuario_token);
        localStorage.removeItem('_in_');
        this.genericService.getUserInfo(1);
        /* Joan: Mogut a getUserInfo
        this.authService.setExpirationDate();
        this.authService.loadAdminArea();
        */
      }
      if (data['errorCode'] === -3) {
        this.snackBar.open('Credenciales Incorrectas', 'X',{
          duration: 6000, panelClass: ['red-snackbar']
        });
      }
    });
  }

  public recoverPasswordClick(): void {
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      width: 'auto',
      data: {email: this.user, edit: true}
    });

    dialogRef.afterClosed().subscribe(data => {});
  }

  public setPassword(userInfo): void {
    const dialogRef = this.dialog.open(SetPasswordComponent, {
      width: 'auto',
      data: {user: this.user, type : 1}
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data?.change_successfully === true) {
        this.password = data.pass;
        this.loginUser()
      }
    });
  }

  checkForm(mail, name, inmoName, comment): void {
    this.wrongMail = mail === null;
    this.wrongName = name === null;
    this.wrongInmoName = inmoName === null;
    this.wrongComment = comment === null;
    if (!this.wrongMail) {
      for (let i = 0; i < mail.length; i++) {
        if (mail[i] === '@' && i > 0) {
          this.wrongMail = false;
          break;
        } else {
          this.wrongMail = true;
        }
      }
    }

    this.wrongForm = !!this.wrongMail;

    if (this.wrongMail || this.wrongName || this.wrongInmoName || this.wrongComment) {
      this.wrongForm = true;
    }

    if (!this.privacidad) {
      this.wrongForm = true;
    }

    console.log(this.privacidad);
    if (!this.wrongForm) {
      console.log('ddd');
      this.submitForm();
    }
  }

  submitForm() {
    this.endpointService.ContactForm(this.name, this.mail, this.inmoName, this.phone, this.comentario).subscribe(data => {});
    this.endpointService.InsertContactInfo(this.name, this.mail, this.inmoName, this.phone, this.comentario, (this.privacidad ? 1 : 0), (this.newsletter ? 1 : 0)).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.name = '';
        this.mail = '';
        this.comentario = '';
        this.phone = '';
        this.inmoName = '';
        this.comentario = '';
        this.privacidad = false;
        this.newsletter = false;
        this.snackBar.open('Su solicitud de contacto se ha realizado correctamente!', 'X', {
          duration: 6000,
          panelClass: ['green-snackbar']
        });
      }
    });
  }

  readPrivacity() {
    const dialogRef = this.dialog.open(PrivacyDialog);
    dialogRef.afterClosed().subscribe(result => {});
  }

  registryInmo(){
    this.router.navigate([Values.ROUTES.REGISTRY_INMO_GROUP]);
  }

}


@Component({
  selector: 'privacy-dialog',
  templateUrl: 'privacy-dialog.html',
})
export class PrivacyDialog {}
