import { EventEmitter, Injectable, Output } from "@angular/core";

// Serivce
import { RoutingService } from "../services/routing/routing.service";
import { EndpointClientService } from "./endpoint-client.service";
import { TemplateService } from "app/services/generic/custom/template-element";
import { JourneyService } from 'app/services/journey/journey.service';
import { RedsysService } from 'app/services/redsys.service';
import { PaycometService } from 'app/services/paycomet.service';
import { PrintTicketService } from 'app/services/generic/custom/print-ticket.service';

// Material
import { MatDialog } from "@angular/material/dialog";

// Component
import { GenericService } from "../services/generic/generic.service";
import { AuthService } from "../services/auth/auth.service";
import { SideNavService } from "app/utils/SideNavService";

import { UserFunctionsService } from "../services/functions/user-functions.service";
import { DemoDialogComponent } from "app/demo-dialog/demo-dialog.component";
import { FunctionsService } from "app/services/generic/functions.service";

import { EMPTY } from "rxjs";


@Injectable({
  providedIn: "root",
})
export class FunctionsClientService {

  @Output() update_category: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() openNoti: EventEmitter<any> = new EventEmitter();
  //@ViewChild('AutoPlantillaComponent') public AutoPlantillaComponent: AutoPlantillaComponent;

  constructor(
    private routingService: RoutingService,
    private endpointClientService: EndpointClientService,
    private genericService: GenericService,
    private authService: AuthService,
    private sidenavService: SideNavService,
    private UserFunctionsService: UserFunctionsService,
    private functionsService: FunctionsService,
    public templateService: TemplateService,
    public journeyService: JourneyService,
    public redsysService: RedsysService,
    public paycometService: PaycometService,
    public printTicketService: PrintTicketService
  ) { }

  public executeFunction(id, structure, param, finished, isFromField) {
    let response = null;
    switch (id) {
      case 1:
        if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
          if (!structure["no_save_params"]) this.functionsService.saveParams(structure, param, structure["id_functional_parent_initial"]);
          this.functionsService.route = structure["internal_routing"];
          this.functionsService.id_route = structure["internal_routing_id_functional_area"];
          this.routingService.previousModule = structure["id_functional_parent_initial"];
          if(structure['isPressingControl']) {
            this.functionsService.openInExteranlURL(this.functionsService.route);
          } else {
            this.functionsService.goRouting(this.functionsService.route, true);
          }
        }
        this.genericService.finishFunction(finished);
        break;
      case 2:
        if (structure && structure["id_functional_parent_initial_dsb"] == 0 && !structure["paramsAdded"]) {
          this.genericService.getInternParam(structure, param);
        }
        this.functionsService.openDialog(structure, 1);
        this.genericService.finishFunction(finished);
        break;
      case 3:
        // Cerrar generic dialog
        this.functionsService.dialogRef.close(structure, param);
        this.genericService.finishFunction(finished);
        break;
      case 4:
        let validReq = this.functionsService.formsArray(structure, finished);
        if (validReq) {
          this.functionsService.duplicateElementUnified(structure, finished, true, 1);
          this.functionsService.dialogRef.close(structure, finished);
        }
        this.genericService.finishFunction(finished);
        break;
      case 5:
        if (structure["id_functional_parent_initial_dsb"] == 0 && !structure["paramsAdded"]) {
          this.genericService.getInternParam(structure, param);
        }
        this.functionsService.openDialog(structure, 2);
        break;
      case 6:
        this.functionsService.generateNewOutputParam(structure);
        this.genericService.updateParamControl();
        this.functionsService.route = structure["internal_routing"];
        this.functionsService.id_route = structure["internal_routing_id_functional_area"];

        this.routingService.previousModule =
          structure["id_functional_parent_initial"];
        if (param && param["tmp_param"]) {
          this.routingService.routeParam = null;
        }
        this.functionsService.goRouting(this.functionsService.route, true);
        this.genericService.finishFunction(finished);
        break;
      case 7:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 0, true, false);
        break;
      case 8:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 1, false, false);
        break;
      case 9:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 0, false, false);
        break;
      case 10:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 0, false, true, 1);
        break;
      case 11:
        this.functionsService.duplicateElementUnified(structure, finished);
        this.genericService.finishFunction(finished);
        break;
      case 12:
        this.functionsService.openDeferContainer(param, structure);
        this.genericService.finishFunction(finished);
        break;
      case 13:
        this.sidenavService.open(0);
        this.genericService.finishFunction(finished);
        break;
      case 14:
        this.sidenavService.close(0);
        this.genericService.finishFunction(finished);
        break;
      case 15:
        this.functionsService.revertValues(structure);
        break;
      case 16:
        if (this.genericService.paramControlVariables[structure["id_functional_parent_initial"]]) {
          let par = this.genericService.paramControlVariables[structure["id_functional_parent_initial"]]
          for (let aux in param["tmp_param"]) {
            let found = false;
            for (let out of par["params"][par["indexParam"]]["output"]) {
              if (out["bd_field"] == aux) {
                found = true;
                out["value"] = param["tmp_param"][aux];
              }
            }
            if (!found) {
              par["params"][par["indexParam"]]["output"].push({
                id_db: structure["id_db"],
                bd_table: structure["bd_table"],
                bd_field: aux,
                value: param["tmp_param"][aux],
              });
            }
          }
        }
        this.genericService.updateParamControl();
        this.functionsService.route = structure["internal_routing"];
        this.functionsService.id_route = structure["internal_routing_id_functional_area"];
        this.routingService.previousModule =
          structure["id_functional_parent_initial"];
        if (param && param["tmp_param"]) {
          this.routingService.routeParam = param["tmp_param"];
        }
        this.functionsService.goRouting(this.functionsService.route, true);
        break;
      case 17:
        this.functionsService.deleteFunctionalArea(structure);
        this.genericService.finishFunction(finished);
        break;
      case 18:
        //Chat
        this.routingService.shouldOpenChat = true;
        this.genericService.finishFunction(finished);
        break;
      case 19:
        //Help
        this.routingService.shouldOpenHelp = true;
        this.genericService.finishFunction(finished);
        break;
      case 20:
        //External Window
        let paramsExternalWindowOld = this.functionsService.cloneVariable(this.routingService.paramsExternalWindow);
        if (structure["customParams"] === undefined) {
          this.routingService.paramsExternalWindow = this.genericService.getInternParam(structure, param);
          this.genericService.fetchStructureExternalWindow(structure["internal_routing_id_functional_area"]);
          if (this.routingService.paramsExternalWindow != paramsExternalWindowOld) this.routingService.shouldOpenExternalWindow = true;
          this.genericService.finishFunction(finished);
        } else {
          this.routingService.paramsExternalWindow = structure["customParams"];
          this.genericService.fetchStructureExternalWindow(structure["internal_routing_id_functional_area"]);
          if (this.routingService.paramsExternalWindow != paramsExternalWindowOld) this.routingService.shouldOpenExternalWindow = true;
          this.genericService.finishFunction(finished);
        }
        break;
      case 21:
        this.genericService.finishFunction(finished);
        break;
      case 22:
        this.routingService.openButtonAutoPlantilla.next();
        this.genericService.finishFunction(finished);
        break;
      case 23:
        this.genericService.openAccountMode();
        this.genericService.finishFunction(finished);
        break;
      case 24:
        this.functionsService.openColorPickerDialog(param);
        this.genericService.finishFunction(finished);
        break;
      case 25:
        this.functionsService.duplicateFunctionalAreaDB(structure, param);
        break;
      case 26:
        this.functionsService.openExternalUrl(structure);
        break;
      case 27:
        setTimeout(() => {
          this.functionsService.reloadQueries(structure, param, finished);
        });
        break;
      case 33:
        response = this.functionsService.deleteDataDialog(structure, 0, finished);
        break;
      case 34:
        this.functionsService.openBottomSheet(structure);
        this.genericService.finishFunction(finished);
        break;
      case 35:
        if (structure["id_functional_parent_initial_dsb"] == 0) {
          this.genericService.getInternParam(structure, param);
          if(structure["massive_action"]) {
            this.genericService.getInternParam(structure, null, 1);
          }
        }
        this.functionsService.openDialog(structure, 3);
        break;
      case 36:
        this.functionsService.openFile(structure, param, false);
        this.genericService.finishFunction(finished);
        break;
      case 37:
        this.functionsService.openFile(structure, param, true);
        this.genericService.finishFunction(finished);
        break;
      case 38:
        this.functionsService.closeBottomSheet(structure);
        this.genericService.finishFunction(finished);
        break;
      case 39:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 0, false, true, 2);
        break;
      case 40:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 0, false, true, 3);
        break;
      case 41:
        response = this.functionsService.deleteDataDialog(structure, 1, finished);
        break;
      case 42:
        response = this.functionsService.deleteDataDialog(structure, 2, finished);
        break;
      case 43:
        response = this.functionsService.deleteDataDialog(structure, 3, finished);
        break;
      case 44:
        this.functionsService.openDialog(structure, 4, this.functionsService.getInternParamDialog(structure, param));
        break;
      case 45:
        this.functionsService.openDialog(structure, 5);
        break;
      case 46:
        this.functionsService.openDialog(structure, 6, this.functionsService.getInternParamDialog(structure, param));
        break;
      case 47:
        let from = 0;
        if (structure["id_functional_parent_initial"] == this.genericService.headerId || structure["id_functional_parent_initial"] == this.genericService.headerNoLoguedId) from = 1;
        if (structure["id_functional_parent_initial"] == this.genericService.footerId || structure["id_functional_parent_initial"] == this.genericService.footerNoLoguedId) from = 2;

        if (structure["id_functional_type"] != 3) {
          let struct = this.genericService.findElementWithId(finished, false, false, true);
          if (!struct) break;
          struct["expandedElement"] = struct["expandedElement"] === param ? null : param; // Si son iguales significa que hay que cerrar el expansion
        } else {
          structure["expandedElement"] = structure["expandedElement"] === param ? null : param; // Si son iguales significa que hay que cerrar el expansion
        }

        this.genericService.loadExpansion(param, from);
        break;
      case 48:
        this.genericService.fillExpansion(param, { 0: structure });
        let selBox = document.createElement("textarea");
        selBox.style.position = "fixed";
        selBox.style.left = "0";
        selBox.style.top = "0";
        selBox.style.opacity = "0";
        selBox.value = structure["description"];
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand("copy");
        document.body.removeChild(selBox);
        this.genericService.openSnackBar(structure["text"], 30000, ["lightgreen-snackbar"]);
        break;
      case 49:
        /* VENTA */
        let productVenta = this.functionsService.findProductFromParams(structure, param);
        this.functionsService.productEgoStart(1, productVenta);
        this.genericService.finishFunction(finished);
        break;
      case 50:
        /* TUTORIAL */
        let productTutorial = this.functionsService.findProductFromParams(structure, param);
        this.functionsService.productEgoStart(2, productTutorial);
        this.genericService.finishFunction(finished);
        break;
      case 51:
        //Guardar vivienda como borrador
        response = this.functionsService.insertUpdateForm(structure, param, finished, 0, true, false, null, 0);
        break;
      case 52:
        //Publicar vivienda
        response = this.functionsService.insertUpdateForm(structure, param, finished, 0, true, false, null, 1);
        break;
      case 53:
        response = this.functionsService.deleteDataDialog(structure, 4, finished);
        break;
      case 54:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 2, false, false);
        break;
      case 55:
        this.functionsService.copyTableField(structure, param);
        break;
      case 56:
        this.functionCase56(structure, param, finished);
        break;
      case 57:
        this.functionsService.productEgoStart(1, structure["internal_routing"]);
        this.genericService.finishFunction(finished);
        break;
      case 58:
        this.functionsService.productEgoStart(2, structure["internal_routing"]);
        this.genericService.finishFunction(finished);
        break;
      case 59: // Hauria de ser generic??
        this.functionCase59();
        break;
      case 60: // Hauria de ser generic??
        this.functionCase60();
        break;
      case 61:
        this.functionCase61(structure, param, finished);
        break;
      case 62:
        this.functionsService.sendWhatsAppClienteContactar(structure, param, finished); // Com fer-lo? La funció original s'aprofita a varis llocs
        break;
      case 63:
        this.functionsService.openFichaInmueble(structure, param, finished); // Tmb té una funció que la criden diverses funcions
        break;
      case 64:
        this.functionsService.getTextInmueble(structure, param, finished, 1); // whatsapp empresa
        break;
      case 65:
        this.functionsService.getTextInmueble(structure, param, finished, 2); // contenido email empresa
        break;
      case 66:
        this.functionsService.getTextInmueble(structure, param, finished, 3); //  asunto empresa
        break;
      case 67:
        this.functionsService.updateImagePropertyCustom(finished);
        break;
      case 68:
        this.functionsService.vincularGoogleMail(structure, param, finished);
        break;
      case 69:
        this.functionsService.desvincularGoogleMail(structure, param, finished);
        break;
      case 70:
        this.functionsService.updateConditionsCustom(finished, structure);
        break;
      case 71:
        this.functionsService.showStaticReport(structure, param, finished);
        break;
      case 72:
        this.functionsService.updatePlantillaElementsCustom(structure, param, finished);
        break;
      case 73:
        this.genericService.finishFunction(finished);
        break;
      case 74:
        break;
      case 75:
        break;
      case 76:
        break;
      case 77:
        break;
      case 78:
        break;
      case 79:
        break;
      case 80:
        break;
      case 81:
        break;
      case 82:
        this.functionsService.duplicarInmueble(structure, param, finished);
        break;
      case 83:
        this.functionsService.validateEmailBackend(structure, param, finished);
        break;
      case 84:
        this.functionsService.sendWhatsAppClienteContactar(structure, param, finished);
        break;
      case 85:
        this.functionsService.getTextInmueble(structure, param, finished, 4); // whatsapp inmueble
        break;
      case 86:
        this.functionsService.getTextInmueble(structure, param, finished, 5); // contenido email inmueble
        break;
      case 87:
        this.functionsService.getTextInmueble(structure, param, finished, 6); //  asunto inmueble
        break;
      case 88:
        this.functionsService.saveTextInmueble(structure, param, finished, 1); //  mensaje whatsapp inmueble
        break;
      case 89:
        this.functionsService.saveTextInmueble(structure, param, finished, 2); //  mensaje email inmueble
        break;
      case 90:
        this.functionsService.saveTextInmueble(structure, param, finished, 3); //  asunto email inmueble
        break;
      case 91:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 0, false, false, null, null, true);
        break;
      case 92:
        this.functionsService.demoInmo(param);
        this.genericService.finishFunction(finished);
        break;
      case 93:
        this.functionsService.loadClientsFromFile(param, finished, structure);
        break;
      case 94:
        this.functionsService.duplicatePlantillaEmail(param, finished, structure);
        break;
      case 95:
        this.functionsService.duplicatePlantillaEmail(param, finished, structure, 1);
        break;
      case 96:
        this.functionsService.openFichaPropietario(structure, param, finished);
        break;
      case 97:
        this.functionsService.verForm(structure, param, finished);
        break;
      case 98:
        this.functionsService.selectImageAndAddInEventsTemplateCustom(structure, param, finished);
        break;
      case 99:
        this.functionsService.updateJourneyCustom(finished);
        break;
      case 100:
        this.functionsService.archiveAllStatusDemandaFromViviendaCustom(finished, structure, param);
        break;
      case 101:
        this.genericService.finishFunction(finished);
        this.genericService.refreshStructure(0);
        break;
      case 102:
        this.genericService.finishFunction(finished);
        this.genericService.refreshStructure(1);
        break;
      case 103:
        this.genericService.finishFunction(finished);
        this.genericService.refreshStructure(2);
        break;
      case 104:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateNewImport(structure, param);
        });
        break;
      case 105:
        this.genericService.finishFunction(finished);
        this.functionsService.startCheckOutReservaHotel(structure, param);
        break;
      case 106:
        this.functionsService.addProductsToNotes(structure, param);
        this.genericService.finishFunction(finished);
        break;
      case 107:
        this.genericService.finishFunction(finished);
        this.functionsService.updateProducts(finished);
        break;
      case 108:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.selectRooms(structure);
        });
        break;

      case 109:
        if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
          let structureClone = this.functionsService.cloneVariable(structure);
          structureClone['id_function'] = 2;
          response = {structure: structureClone, param, finished, getFunction: true};
        } else {
          this.genericService.finishFunction(finished);
        }
        break;
      case 110:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateNewImportTicket(structure, param);
        });
        break;
      case 111:
        this.genericService.finishFunction(finished);
        break;
      case 112:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.openInfoDieta(structure, param);
        });
        break;
      case 113:
        this.functionsService.importDeportActivityAssign(structure, param, finished)
        break;
      case 114:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateNewImportTicketActLudicas(structure, param);
        });
        break;
      case 115:
        if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
          setTimeout(() => {
            this.functionsService.newPeriodoDietaSelected(structure, param);
          });
          this.genericService.finishFunction(finished);
        } else {
          this.genericService.finishFunction(finished);
        }
        break;
      case 116:
        if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
          setTimeout(() => {
            this.functionsService.cleanDieta(structure, param);
          });
          this.genericService.finishFunction(finished);
        } else {
          this.genericService.finishFunction(finished);
        }
        break;
      case 117:
        if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
          setTimeout(() => {
            this.functionsService.calculateImporteIVAEncargo(structure, param);
          });
          this.genericService.finishFunction(finished);
        } else {
          this.genericService.finishFunction(finished);
        }
        break;
      case 118:
        setTimeout(() => {
          this.functionsService.calculateImporteManualTotalRest(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 119:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.openIncidenciaReserva(structure, param);
        });
        break;
      case 120: // Pago-aceptado
        this.genericService.finishFunction(finished);
        this.functionsService.updatePaymentMethod(structure, 1);
        break;
      case 121: // Pago-rechazado
        this.genericService.finishFunction(finished);
        this.functionsService.updatePaymentMethod(structure, 0);
        break;
      case 122: // Volver a la página de pago
        this.genericService.finishFunction(finished);
        this.functionsService.getPaymentPage(structure);
        break;
      case 123:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, false, false, 18);
        this.genericService.finishFunction(finished);
        break;
      case 124:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateNewImportDiferencia(structure, param);
        });
        break;
      case 125:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.setFieldsIDClienteEmpresa(structure, param);
        });
        break;
      case 126:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.cargarImportePendiente(structure, param);
        });
        break;
      case 127: //Realizar Pago
        this.genericService.finishFunction(finished);
        this.functionsService.realizarPago(structure, param, finished, false, false);
        break;
      case 128:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.updatePagoTotal(structure, param);
        });
        break;
      case 129:
        setTimeout(() => {
          this.functionsService.relacionaCampoIdCliente(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 130:
        setTimeout(() => {
          this.functionsService.sumaHoraFecha(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 131:
        this.endpointClientService.insertPedidoStaticRestaurant(this.authService.arrayProductos, this.authService.productsPriceCounter, this.authService.empresaId).subscribe((data) => {
          let id_ticket = data['response']['id_ticket'];
          let idLanguage = this.authService.labelLanguage;
          let idEmpresa = this.authService.getIdEmpresa();
          let nombreEmpresa = this.authService.companyGenericName;
    
          this.authService.arrayProductos = [];
          this.authService.productsCounter = 0;
          this.authService.productsPriceCounter = 0;
          this.authService.productsPriceCounterLabel = "0.00";
    
          localStorage.setItem("arrayProductos", JSON.stringify(this.authService.arrayProductos));
          localStorage.setItem("productsCounter", JSON.stringify(this.authService.productsCounter));
          localStorage.setItem("productsPriceCounter", JSON.stringify(this.authService.productsPriceCounter));
          localStorage.setItem("productsPriceCounterLabel", JSON.stringify(this.authService.productsPriceCounterLabel));
    
          this.functionsService.route = structure["internal_routing"];
          structure["internal_routing"] = "../" + idLanguage + "/" + nombreEmpresa + "/pedido-cliente-restaurante" + "/sth/" + idEmpresa + "-0&&" + id_ticket;
          if (data['response']['id_mesa'] == -1) {
            this.functionsService.openExternalUrl(structure);
    
          }
          else {
            this.genericService.openSnackBar(
              "Tu pedido se ha realizado correctamente!",
              7000,
              ["green-snackbar"]
            );
          }
          this.functionsService.dialogRef.close(structure, []);
          this.genericService.finishFunction(true);
        });
        break;
      case 132:
        setTimeout(() => {
          this.functionsService.removeTableRelationWhenValue(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 133:
        setTimeout(() => {
          this.functionsService.toAllDayDate(structure);
        });
        this.genericService.finishFunction(finished);
        break;
      case 134:
        setTimeout(() => {
          this.functionsService.changeHideButtonId33028(structure);
        });
        this.genericService.finishFunction(finished);
        break;
      case 135:
        setTimeout(() => {
          this.functionsService.fechaFinActividad(structure);
        });
        this.genericService.finishFunction(finished);
        break;
      case 136:
        setTimeout(() => {
          this.functionsService.changeHideButtonId33149(structure);
        });
        this.genericService.finishFunction(finished);
        break;
      case 137:
        setTimeout(() => {
          this.functionsService.agendaEstadoInitUpdate(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 138:
        let x = { id_function: 35, internal_routing_id_functional_area: 33949, refresh_params: 1 };
        const now = new Date();
        this.functionsService.addWindowParam(this.functionsService.formatDate(now, true), 1, "actividades_calendario", "fecha_inicio", 89); // Hay que poner en el header porque el Dialog esta en el Header
        x['refresh_params'] = 2;
        this.genericService.finishFunction(finished);
        response = {structure: x, param: null, finished, getFunction: true};
        break;
      case 139:
        setTimeout(() => {
          this.functionsService.saveTakeAwayRestaurant(structure, param, finished);
        });
        this.genericService.finishFunction(finished);
        break;
      case 140:
        setTimeout(() => {
          this.functionsService.changeHideButtonId34505(structure);
        });
        this.genericService.finishFunction(finished);
        break;
      case 141:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.assignPaymentImport(structure);
        });
        break;
      case 142:
        setTimeout(() => {
          this.functionsService.insertTicketNoPagadoTPV(structure, param, finished);
        });
        break;
      case 143:
        this.functionsService.saveInsertedValuesAndRouting(1, structure, param, finished);
        this.genericService.finishFunction(finished);
        break;
      case 144:
        this.functionCase144(structure);
        break;
      case 145:
        this.functionCase145(structure);
        break;
      case 146:
        this.functionsService.saveInsertedValuesAndRouting(2, structure, param, finished);
        this.genericService.finishFunction(finished);
        break;
      case 147:
        this.functionsService.printTicketRest(structure, param);
        this.genericService.finishFunction(finished);
        break;
      case 148:
        this.functionCase148(structure, param);
        this.genericService.finishFunction(finished);
        break;
      case 149:
        setTimeout(() => {
          response = this.functionCase149(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 150:
        this.functionsService.addToCheckoutRestaurant(structure, param);
        this.genericService.finishFunction(finished);
        break;
      case 151:
        this.functionsService.pasarInmueble(structure, param);
        this.genericService.finishFunction(finished);
        break;
      case 152:
        this.genericService.finishFunction(finished);
        this.functionsService.dialog.closeAll();
        this.genericService.updateResults(this.genericService.getInternParam(structure, param), structure['id_functional_parent_initial']);
        break;
      case 153:
        this.functionsService.duplicarMatching(structure, param, finished);
        break;
      case 154:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calcNaveSuperficies(structure, param);
        });
        break;
      case 155:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateImportSaldoInicial(structure, param);
        });
        break;
      case 156:
        setTimeout(() => {
          // response = this.functionsService.insertUpdateForm(structure, param, finished, 1, false, false);
          this.functionsService.reloadTable(structure, param, finished);
        });
        break;
      case 157:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateImportesGestorHipotecario(structure, param);
        }, 200);
        break;
      case 162:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateNewImportTicketActLudic(structure, param);
        });
        break;
      case 163:
        setTimeout(() => {
          this.functionsService.insertTicketNoPagadoActLudic(structure, param, finished);
        });
        this.genericService.finishFunction(finished);
        break;
      case 164:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateNewImportActLudic(structure, param);
        });
        break;
      case 165:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateNewImportDiferenciaActLudic(structure, param);
        });
        break;
      case 166:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.cargarImportePendienteActLudic(structure, param);
        });
        break;
      case 167:
        setTimeout(() => {
          this.functionsService.changeHideButtonId42364(structure);
        });
        this.genericService.finishFunction(finished);
        break;
      case 168:
        setTimeout(() => {
          this.functionsService.refreshCheckOutReservaHotel(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 169:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateImportPorParticipante(structure, param);
        });
        break;
      case 170:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateImportSaldoInicialActLudic(structure, param);
        });
        break;
      case 171:
        setTimeout(() => {
          this.functionsService.calculateNewImportReservaHotel(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 173:
        setTimeout(() => {
          this.functionsService.calculateImporteManualTotalActLudic(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 174:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 0, true, false);
        this.functionsService.endIncidencia();
        this.genericService.finishFunction(finished);
        break;
      case 175:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 3, false, false);
        break;
      case 176:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.openContratoReserva(structure, param);
        });
        break;
      case 177:
        setTimeout(() => {
          this.functionsService.changeHideButtonId44329(structure);
        });
        this.genericService.finishFunction(finished);
        break;
      case 178:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.openContratoReserva(structure, param, 2);
        });
        break;
      case 179:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.openContratoReserva(structure, param, 3);
        });
        break;
      case 180:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.construirDireccion(structure, param);
        });
        break;
      case 181:
        if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
          setTimeout(() => {
            this.functionsService.reloadQueries(structure, param, finished, 1);
          });
        } else {
          this.genericService.finishFunction(finished);
        }
        break;
      case 182:
        this.functionsService.cambiarEmpresa(structure, param, finished);
        break;
      case 183:
        if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
          var internal_fas = structure["internal_routing_id_functional_area"].replace(/\s+/g, "").split(",");
          setTimeout(() => {
            for (let idFA of internal_fas) {
              let fake = { 'child': this.genericService.getAllStructures() };
              let copy_fields_idFA = this.functionsService.findFunctionalArea(fake, idFA);
              this.functionsService.assignValueFunctionalArea(copy_fields_idFA, structure['tmp_value']);
            }
          });
        } else {
          this.genericService.finishFunction(finished);
        }
        break;
      case 185:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateIngresoTotalGestorHipotecario(structure, param);
        }, 200);
        break;
      case 186:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 4);
        this.genericService.finishFunction(finished);
        break;
      case 187:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateOperacionGestorHipotecario(structure, param);
        }, 200);
        break;
      case 188:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 5);
        this.genericService.finishFunction(finished);
        break;
      case 189:
        setTimeout(() => {
          this.functionsService.maxDateNow(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 190:
        if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
          this.genericService.finishFunction(finished);
          setTimeout(() => {
            this.functionsService.SobranteAPropinas(structure, param);
          });
        } else {
          this.genericService.finishFunction(finished);
        }
        break;
      case 191:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 6);
        this.genericService.finishFunction(finished);
        break;
      case 192:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 7);
        this.genericService.finishFunction(finished);
        break;
      case 193:
        this.functionsService.openCashRest();
        this.genericService.finishFunction(finished);
        break;
      case 194:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.ImporteAImporteFinal(structure, param);
        });
        break;
      case 195:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 8);
        this.genericService.finishFunction(finished);
        break;
      case 196:
        this.functionsService.marcharTodos(structure, param);
        break;
      case 197:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.sumarNuevoImporte(structure, param);
        });
        break;
      case 198:
        this.functionsService.printMovementRest(structure, param);
        break;
      case 199:
        const dt = new Date(param["date"]);
        return (dt.getFullYear() + "-" + (dt.getMonth() + 1 < 10 ? "0" : "") + (dt.getMonth() + 1) + "-" + (dt.getDate() < 10 ? "0" : "") + dt.getDate());
        break;
      case 200:
        this.functionsService.printCierre(structure, param);
        break;
      case 201:
        this.functionsService.printSnapshot(structure, param);
        break;
      case 202:
        setTimeout(() => {
          this.functionsService.changeHideButtonId31875(structure);
        }, 200);
        this.genericService.finishFunction(finished);
        break;
      case 203:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 9);
        this.genericService.finishFunction(finished);
        break;
      case 204:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 10);
        this.genericService.finishFunction(finished);
        break;
      case 205:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 11);
        this.genericService.finishFunction(finished);
        break;
      case 206:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 12);
        this.genericService.finishFunction(finished);
        break;
      case 207:
        setTimeout(() => {
          response = this.functionsService.insertUpdateForm(structure, param, finished, 0, true, false);
        }, 1000);
        break;
      case 208:
        setTimeout(() => {
          response = this.functionsService.insertUpdateForm(structure, param, finished, 0, true, false);
        }, 1000);
        break;
      case 209:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 13);
        this.genericService.finishFunction(finished);
        break;
      case 210:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 14);
        this.genericService.finishFunction(finished);
        break;
      case 212:
        response = this.functionCase212(structure, param, finished);
        break;
      case 213:
        response = this.functionCase213(structure, param, finished);
        break;
      case 214:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 15);
        this.genericService.finishFunction(finished);
        break;
      case 215:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, true, false, 16);
        this.genericService.finishFunction(finished);
        break;
      case 216:
        this.functionsService.deleteAllPictures(structure, param);
        this.genericService.finishFunction(finished);
        break;
      case 217:
        setTimeout(() => {
          this.functionsService.imprimirPedido(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 218:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.verCobro(structure, param);
        });
        break;
      case 219:
        this.functionsService.deleteAllDocs(structure, param);
        this.genericService.finishFunction(finished);
        break;
      case 220:
        this.functionsService.downloadAllPictures(structure, param);
        this.genericService.finishFunction(finished);
        break;
      case 222:
        if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
          this.genericService.finishFunction(finished);
          setTimeout(() => {
            this.functionsService.calculateImportePrecioInmueble(structure, param);
          });
        } else {
          this.genericService.finishFunction(finished);
        }
        break;
      case 224:
        if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
          setTimeout(() => {
            this.functionsService.setPrecioNocheEstanciaVacio(structure, param);
          });
          this.genericService.finishFunction(finished);
        } else {
          this.genericService.finishFunction(finished);
        }
        break;
      case 225:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.openMovimientosPersonal(structure, param);
        });
        break;
      case 226:
        setTimeout(() => {
          this.functionsService.setNumNoches(structure, param, finished);
        })
        this.genericService.finishFunction(finished);
        break;
      case 227:
        this.functionsService.checkPinUsuario(structure, param, finished);
        this.genericService.finishFunction(finished);
        break;
      case 228:
        this.functionsService.oneStepPayment(structure, param, finished, 6);
        this.genericService.finishFunction(finished);
        break;
      case 229:
        this.functionsService.oneStepPayment(structure, param, finished);
        this.genericService.finishFunction(finished);
        break;
      case 230:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateIVA(structure, param);
        });
        break;
      case 231:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.setPrecioBase(structure, param);
        });
        break;
      case 232:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.setPrecioTotal(structure, param);
        });
        break;
      case 233:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.sumarImporteLinea(structure, param);
        });
        break;
      case 234:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.openReportInfluencers(structure, param);
        });
        break;
      case 235:
        this.functionsService.deleteAllPictures(structure, param, 1);
        this.genericService.finishFunction(finished);
        break;
      case 236:
        setTimeout(() => {
          this.functionsService.fillEmailContent(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 237:
        setTimeout(() => {
          this.functionsService.calcularPropina(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 238:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.openReportDiasPorSemana(structure, param);
        });
        break;
      case 239:
        this.functionsService.copyLinkForm(structure, param);
        break;
      case 240:
        this.functionsService.copyLinkForm(structure, param, 1);
        break;
      case 241:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.openReportDescuentos(structure, param);
        });
        break;
      case 242:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.openReportActividadComercial(structure, param);
        });
        break;
      case 243:
        this.functionsService.compartirInmueble(structure, param, finished);
        break;
      case 244:
        this.functionsService.compartirInmueble(structure, param, finished, 1);
        break;
      case 245:
        setTimeout(() => {
          this.functionsService.calcularTotalProducto(structure, param);
        });
        break;
      case 246:
        this.functionsService.showReportHojaVisita(structure, finished);
        break;
      case 247:
        this.genericService.finishFunction(finished);
        this.functionsService.copyServicePrice(structure, param);
        break;
      case 248:
        this.functionsService.sendWhatsAppClienteContactar(structure, param, finished, 1);
        break;
      case 249:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.openHistoricoPorCamarero(structure, param);
        });
        break;
      case 250:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.addFieldToAnotherField(structure);
        });
        break;
      case 251:
        let fas = structure['internal_routing'].split(",")
        for (let i in fas) {
          this.genericService.finishFunction(finished);
          let splited = fas[i].split(".");
          console.log(splited[1], splited[0]);
          this.genericService.findElementWithIdAndSetStatus(splited[1], splited[0]);
        }
        break;
      case 252:
        setTimeout(() => {
          this.functionsService.calcPrecioTotalLineaCotizacion(structure, param);
        });
        this.genericService.finishFunction(finished);

        break;
      case 253:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calcTotalesCotizacion(structure, param);
        });
        break;
      case 254:
        this.functionsService.openFichaCotizacion(structure, param, finished);
        break;
      case 255:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.genericFunctionRoutingStatic(structure, param);
        });
        break;
      case 257:
        this.functionsService.saveParamsClientOnly(structure, structure["id_functional_parent_initial"]);
        this.functionsService.route = structure["internal_routing"];
        this.functionsService.id_route = structure["internal_routing_id_functional_area"];
        this.routingService.previousModule = structure["id_functional_parent_initial"];
        this.functionsService.goRouting(this.functionsService.route, true);
        this.genericService.finishFunction(finished);
        break;
      case 262:
        response = this.functionCase262(structure, param, finished);
        break;
      case 267:
        this.UserFunctionsService.vincularGoogle();
        //this.genericService.finishFunction(finished);
        break;
      case 268:
        this.UserFunctionsService.desvincularGoogle();
        //this.genericService.finishFunction(finished);
        break;
      case 269:
        this.UserFunctionsService.vincularMicrosoft();
        break;
      case 270:
        this.UserFunctionsService.desvincularMicrosoft();
        break;
      case 271:
        setTimeout(() => {
          this.functionsService.addFieldToAnotherField(structure);
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            this.functionsService.reloadQueries(structure, param, finished, 1);
          }
        });
        this.genericService.finishFunction(finished);
        break;
      case 272:
        this.functionsService.duplicarEstancia(structure, param, finished);
        break;
      case 273:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.openInformacionReservas(structure, param);
        });
        break;
      case 274:
        setTimeout(() => {
          this.functionsService.removeTableRelationWhenValue(structure, param);
          this.functionsService.reloadQueries(structure, param, finished);
        });
        break;
      case 275:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.openOcupacionEstancias(structure, param);
        });
        break;
      case 276:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 99, false, false, 17);
        break;
      case 277:
        this.genericService.finishFunction(finished);
        this.functionsService.checkOcupacionReservasRestaurante(structure, param, finished);
        break;
      case 278:
        setTimeout(() => {
          this.functionsService.calculateAndChangeTicketPrice(structure, param);
        });
        this.genericService.finishFunction(finished);
        break;
      case 279:
        if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
          setTimeout(() => {
            this.functionsService.checkCantidadDisponibleEstancias(structure, param);
          })
          this.genericService.finishFunction(finished);
        }
        break;
      case 280:
        response = this.functionsService.deleteDataDialog(structure, 5, finished);
        break;
      case 281:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.aplicarDescuento(structure, param);
        });
        break;
      case 282:
        setTimeout(() => {
          this.functionsService.calculateImportAndSetField(structure);
        });
        this.genericService.finishFunction(finished);
        break;
      case 283:
        setTimeout(() => {
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            this.functionsService.calculateImportAndSetField(structure);
          }
        });
        this.genericService.finishFunction(finished);
        break;
      case 284:
        setTimeout(() => {
          this.functionsService.sumAllFieldsAndSetField(structure);
        });
        this.genericService.finishFunction(finished);
        break;
      case 285:
        setTimeout(() => {
          if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
            this.functionsService.sumAllFieldsAndSetField(structure);
          }
        });
        this.genericService.finishFunction(finished);
        break;
      case 286:
        if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
          let structureClone = this.functionsService.cloneVariable(structure);
          structureClone['id_function'] = 5;
          response = {structure: structureClone, param, finished, getFunction: true};
        } else {
          this.genericService.finishFunction(finished);
        }
        break;
      case 287:
        setTimeout(() => {
          this.functionsService.recalcularImporteTarifaActLudic(structure);
        });
        this.genericService.finishFunction(finished);
        break;
      case 288:
        //Help
        this.routingService.shouldOpenSearch = true;
        this.genericService.finishFunction(finished);
        break;
      case 289:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.selectTarjetaRegalo(structure);
        });
        break;
      case 290:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateNewImportTicketServicios(structure, param);
        });
        break;
      case 291:
        setTimeout(() => {
          this.functionsService.insertTicketNoPagadoActividadesServicios(structure, param, finished);
        });
        this.genericService.finishFunction(finished);
        break;
      case 292:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.sumarNuevoImporteServicios(structure, param);
        });
        break;
      case 293:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.calculateNewImportDiferenciaServicios(structure, param);
        });
        break;
      case 294:
        setTimeout(() => {
          this.functionsService.changeHideButtonId56976(structure);
        });
        this.genericService.finishFunction(finished);
        break;
      case 295:
        if (structure["form_field"] != 1 || !structure["autoExecuteFunction"]) {
          this.genericService.finishFunction(finished);
          setTimeout(() => {
            this.functionsService.SobranteAPropinasServicios(structure, param);
          });
        } else {
          this.genericService.finishFunction(finished);
        }
        break;
      case 296:
        this.functionCase296(structure, param, finished);
        break;
      case 297:
        this.functionCase297(structure, param, finished);
        break;
      case 298:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.concatFields(structure);
        });
        break;
      case 299:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.genericFunctionRoutingStatic(structure, param, false, false, true);
        });
        break;
      case 300: // Com podran editar el printTicketService??
        this.functionsService.printTicketActLudic(structure, param);
        this.genericService.finishFunction(finished);
        break;
      case 301:
        response = this.functionCase301(structure, param, finished);
        break;
      case 302:
        // Menu
        this.routingService.shouldOpenMenu = true;
        this.genericService.finishFunction(finished);
        break;
      case 303:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 0, false, false, 1, null, true);
        break;
      case 304:
        this.functionCase304(structure, param, finished);
        break;
      case 305:
        if (this.functionsService.formsArray(structure, finished)) {      
          let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
          for (let i in values) {
            if (values[i]["database"] == 1 && values[i]["table"] == "usuarios" && values[i]["field"] == "id" && values[i]["value"] > 0) {
              localStorage.setItem('_i_', values[i]["value"]);
              localStorage.setItem('_in_dev_', "1");
              this.genericService.refreshStructure(2);
              break;
            }
          }
        }
        this.genericService.finishFunction(finished);
        break;
      case 306:
        response = this.functionsService.insertUpdateForm(structure, param, finished, 4, false, false, null, null, true);
        break;
      case 307:
        this.genericService.finishFunction(finished);
        setTimeout(() => {
          this.functionsService.runClientScriptCron(structure);
        });
        break; 
      case 308:
        let splited0Bar = structure["internal_routing_id_functional_area"].split("/");
        // El primer valor de la barra "/" es el campo que se busca para rellenar
        // El segundo valor de la barra "/" es el campo donde se guarda el valor en los params
        if (splited0Bar[1] !== undefined) {
          let splited0 = splited0Bar[0].split(".");
          let actualNgForId = this.functionsService.getNgForId(structure);
          let value = this.genericService.findFAWithTableField(splited0[0], splited0[1], splited0[2], structure["id_functional_parent_initial_dsb"], actualNgForId, false)['tmp_value'];
          let splited1 = splited0Bar[1].split(".");
          this.functionsService.addWindowParam(value, splited1[0], splited1[1], splited1[2],structure['id_functional_parent_initial'], true, "output");
          this.functionsService.route = structure["internal_routing"];
          this.functionsService.id_route = this.functionsService.cloneVariable(value);
          this.routingService.previousModule = structure["id_functional_parent_initial"];
          this.functionsService.goRouting(structure["internal_routing"], true);
        }
        break;
      case 782:
        let panelClass = [];
        if (this.routingService.shouldOpenExternalWindow)
          panelClass.push("overAllDialog");
        this.functionsService.dialog.open(DemoDialogComponent, {
          panelClass: panelClass,
        });
        break;
      case 783:
        // Se llama estática desde el código de notis
        if (param && param !== null && param.actividad && param.actividad[0] && param.actividad[0].id && param.actividad[0].id > 0) {
          this.functionsService.addWindowParam(param.actividad[0].id, 1, "actividades_calendario", "id", 89);
          let x = { id_function: 5, internal_routing_id_functional_area: 34400, refresh_params: 2 };
          response = {structure: x, param: null, finished, getFunction: true};
        } else {
          this.genericService.openSnackBar("Actividad no encontrada", 5000, ["red-snackbar",]);
          this.genericService.finishFunction(finished);
        }
        break;
    }
    if(response) return response;
    return null;
  }

  functionCase213(structure, param, finished) {
    let intern_data = this.genericService.getInternParam(structure, param);
    let idPedido = null;

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_pedido" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idPedido = intern_data[i]["value"];
      }
    }

    if (idPedido && idPedido != null && idPedido > 0) {
      this.endpointClientService.productosEstadoCincoDirecto(idPedido).subscribe((data) => {
        let struct2 = this.functionsService.cloneVariable(structure);
        struct2['id_function'] = 5;
        this.genericService.updateResults(this.genericService.getInternParam(structure, param), structure['id_functional_parent_initial']);
        this.genericService.finishFunction(finished);
        return {structure: struct2, param, finished, getFunction: true}
      });
    }
    else this.genericService.openSnackBar("No se ha encontrado ningún pedido", 4000, ["lightred-snackbar"]);
  }

  functionCase262(structure, param, finished) {
    let paramIntern = [];
    let intern_data = this.genericService.getInternParam(structure, paramIntern);

    let email = null;
    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "usuarios" && intern_data[i]["bd_field"] == "email") {
        email = intern_data[i]["value"];
        break;
      }
    }
    this.UserFunctionsService.setPassword(null, email);
  }

  functionCase212(structure, param, finished) {
    let intern_data = this.genericService.getInternParam(structure, param);
    let idPedido = null;
    let idProducto = null;
    let idOpcion = null;

    for (let i in intern_data) {
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_pedidos" && intern_data[i]["bd_field"] == "id_pedido" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idPedido = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_productos" && intern_data[i]["bd_field"] == "id_producto" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idProducto = intern_data[i]["value"];
      }
      if (intern_data[i]["id_db"] == 1 && intern_data[i]["bd_table"] == "rest_productos" && intern_data[i]["bd_field"] == "id_opcion_comodin" && intern_data[i]["value"] && intern_data[i]["value"] > 0) {
        idOpcion = intern_data[i]["value"];
      }
    }

    if (idOpcion && idOpcion != null && idOpcion > 0) {
      structure['id_function'] = 5;
      return {structure: structure, param, finished, getFunction: true};
    } else {
      if (idPedido && idPedido != null && idPedido > 0) {
        if (idProducto && idProducto != null && idProducto > 0) {
          this.endpointClientService.anadirProductoDirecto(idPedido, idProducto).subscribe((data) => {
            this.genericService.updateResults(this.genericService.getInternParam(structure, param), structure['id_functional_parent_initial']);
            this.printTicketService.printCommandRestaurant(idPedido);
          });
        } else this.genericService.openSnackBar("No se ha encontrado ningún producto", 4000, ["lightred-snackbar"]);
      }
      else this.genericService.openSnackBar("No se ha encontrado ningún pedido", 4000, ["lightred-snackbar"]);
    }
  }

  functionCase148(structure, param) {
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let pedidos = null;
      for (let i in values) {
        if (
          values[i]["database"] == 1 &&
          values[i]["table"] == "rest_pedidos" &&
          values[i]["field"] == "comodin_pedidos"
        ) {
          pedidos = values[i]["value"];
          break;
        }
      }

      if (pedidos != null && pedidos.length > 1) {
        this.endpointClientService.mergePedidosRestaurant(pedidos).subscribe((data) => { });
        this.genericService.openSnackBar("Pedidos juntados correctamente", 3500, ["lightgreen-snackbar",]);
        this.genericService.refreshStructure(0);
      }
      else {
        this.genericService.openSnackBar("Elige com a mínimo dos pedidos", 3500, ["lightred-snackbar",]);
      }
    }
  }

  functionCase149(structure, param) {
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let pedidoOrigen = null;
      let juntarPedido = null;
      let mesaDestino = null;
      let pedidoDestino = null;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos" && values[i]["field"] == "id_pedido") {
          pedidoOrigen = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "rest_mesas" && values[i]["field"] == "id_mesa") {
          mesaDestino = values[i]["value"];
        }
        else if (values[i]["database"] == 1 && values[i]["table"] == "comodin_mesas" && values[i]["field"] == "comodin_juntar_pedidos") {
          juntarPedido = values[i]["value"];
        }
        else if (values[i]["database"] == 1 && values[i]["table"] == "rest_pedidos" && values[i]["field"] == "comodin_id_pedido") {
          pedidoDestino = values[i]["value"];
        }
      }
      let res = null;
      this.endpointClientService.movePedidoRestaurant(pedidoOrigen, mesaDestino, juntarPedido, pedidoDestino).subscribe((data) => {
        res = data["response"];
        if (res == 1) {
          this.genericService.refreshStructure(0);
        }
        else {
          this.functionsService.goPrev(true, null);
        }

      });
      this.genericService.refreshStructure(0);
    }
  }

  functionCase301(structure, param, finished) {
    let FA = this.genericService.findElementWithId(structure["internal_routing_id_functional_area"], false, false, true);
        console.log("FAAAA", FA);
        FA['showSub'] = true;
        if (FA['id_functional_status_general'] != 2) FA['id_functional_status_general_statusInitUpdate'] = this.functionsService.cloneVariable(FA['id_functional_status_general']);
        FA['id_functional_status_general'] = 2;
        this.functionsService.fecha_inicio_reservas = null;
        this.functionsService.fecha_fin_reservas = null;
        this.functionsService.hora_inicio_reservas = null;
        this.functionsService.hora_fin_reservas = null;
        let params = {intern: []};
        this.genericService.findInterns(structure["idFAParamsInterns"], params, null, structure["id_functional_parent_initial_dsb"]);
        if(params && params['intern'] && params['intern'].length > 0) {
          let params2 = params['intern'];
          for(let i in params2) {
            if(params2[i]['id_db'] == 1 && params2[i]['bd_table'] == 'comodin_fecha_reserva' && params2[i]['bd_field'] == 'comodin_fecha_inicio_reserva' && params2[i]['value']) {
              this.functionsService.fecha_inicio_reservas = params2[i]['value'];
            }
            if(params2[i]['id_db'] == 1 && params2[i]['bd_table'] == 'comodin_fecha_reserva' && params2[i]['bd_field'] == 'comodin_hora_inicio_reserva' && params2[i]['value']) {
              this.functionsService.hora_inicio_reservas = params2[i]['value'];
            }
            if(params2[i]['id_db'] == 1 && params2[i]['bd_table'] == 'comodin_fecha_reserva' && params2[i]['bd_field'] == 'comodin_fecha_fin_reserva' && params2[i]['value']) {
              this.functionsService.fecha_fin_reservas = params2[i]['value'];
            }
            if(params2[i]['id_db'] == 1 && params2[i]['bd_table'] == 'comodin_fecha_reserva' && params2[i]['bd_field'] == 'comodin_hora_fin_reserva' && params2[i]['value']) {
              this.functionsService.hora_fin_reservas = params2[i]['value'];
            }
          }
          console.log(params2, "sdfsdfsdf", this.functionsService.fecha_inicio_reservas, this.functionsService.fecha_fin_reservas, this.functionsService.hora_inicio_reservas, this.functionsService.hora_fin_reservas);
        }
        setTimeout(() => {
          FA['id_functional_status_general'] = FA['id_functional_status_general_statusInitUpdate'];
          FA['wasInitialized'] = false;
          FA['showSub'] = false;
        }, 0);
        this.genericService.finishFunction(finished);
  }

  public functionCase144(structure) {
    let id_inmueble_idFA = this.genericService.findFAWithTableField(1, "actividades_viviendas", "id_vivienda", structure["id_functional_parent_initial_dsb"]);
    if (id_inmueble_idFA && id_inmueble_idFA['tmp_value']) {
      let id_inmueble = this.functionsService.cloneVariable(id_inmueble_idFA['tmp_value'][0]);
      this.endpointClientService.getPropertyLocation(id_inmueble).subscribe((data) => {
        if (data['response'] && data['response'][0] && data['response'][0]['direccion']) {
          let idFA = this.genericService.findFAWithTableField(1, "actividades_calendario", "localizacion", structure["id_functional_parent_initial_dsb"]);
          this.functionsService.assignValueFunctionalArea(idFA, data['response'][0]['direccion']);
        }
        else {
          this.genericService.openSnackBar("Este inmueble no tiene dirección", 5000, [
            "red-snackbar",
          ]);
        }
      });
    }
  }

  public functionCase145(structure) {
    let id_cliente_idFA = this.genericService.findFAWithTableField(1, "actividades_clientes", "id_cliente", structure["id_functional_parent_initial_dsb"]);
    if (id_cliente_idFA && id_cliente_idFA['tmp_value']) {
      let id_cliente = this.functionsService.cloneVariable(id_cliente_idFA['tmp_value'][0]);
      this.endpointClientService.getClientLocation(id_cliente).subscribe((data) => {
        if (data['response'] && data['response'][0] && data['response'][0]['direccion']) {
          let idFA = this.genericService.findFAWithTableField(1, "actividades_calendario", "localizacion", structure["id_functional_parent_initial_dsb"]);
          this.functionsService.assignValueFunctionalArea(idFA, data['response'][0]['direccion']);
        }
        else {
          this.genericService.openSnackBar("Este cliente no tiene dirección", 5000, [
            "red-snackbar",
          ]);
        }
      });
    }
  }

  public functionCase56(structure, param, finished, refresh = 0, type = null, publicar = null) {
    const v = this.functionsService.insertFormData(structure, param, finished, type, publicar);
    if (v != null) {
      v.subscribe((data) => {
        switch (data["errorCode"]) {
          case 0:
            let idPortal = null;
            let dataAcc = this.genericService.getDataAccount();
            for (let t in this.functionsService.tmpArray) if (this.functionsService.tmpArray[t]["database"] == 1 && this.functionsService.tmpArray[t]["table"] == "portales" && this.functionsService.tmpArray[t]["field"] == "id") idPortal = this.functionsService.tmpArray[t]["value"];
            if (idPortal !== null) {
              this.endpointClientService.updateViviendaConfigPortalesMassive(idPortal, dataAcc).subscribe((data2) => {
                if (data2["response"] == 1) {
                  if (data2["errorCode"] == 0) {
                    this.genericService.openSnackBar("Los datos se han guardado correctamente y la publicación en el portal se ha actualizado.\n Revisa y gestiona desde esta pantalla la publicación del portal.", 12000, ["green-snackbar"]);
                    this.functionsService.portalConfigured = idPortal;
                    this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
                    this.functionsService.dialog.closeAll();
                    this.genericService.finishFunction(finished);
                    this.functionsService.goRouting("gestionar-inmuebles-en-portales", false);
                  } else {
                    if (data2["errorMessage"] !== null && data2["errorMessage"] !== "") {
                      this.genericService.openSnackBar(
                        "Los datos se han guardado correctamente pero ha habido el siguiente error al actualizar alguno de los inmuebles: " +
                        data2["errorMessage"] +
                        ".\n Soluciona el error y actualízalos manualmente. Si persiste el error, ponte en contacto con nosotros en el chat de soporte.",
                        20000,
                        ["red-snackbar"]
                      );
                      this.functionsService.portalConfigured = idPortal;
                      this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
                      this.functionsService.dialog.closeAll();
                      this.genericService.finishFunction(finished);
                      this.functionsService.goRouting("gestionar-inmuebles-en-portales", false);
                    }
                  }
                } else {
                  if (data2["errorCode"] == 0) {
                    this.genericService.openSnackBar(
                      "Los datos se han guardado correctamente y se han despublicado los inmuebles del portal.",
                      6000,
                      ["green-snackbar"]
                    );
                    this.genericService.formsChanged[this.genericService.currentInitialArea["key"]][this.genericService.paramControlVariables[this.genericService.currentInitialArea["key"]]['indexParam']] = new Map();
                    this.functionsService.dialog.closeAll();
                    this.genericService.finishFunction(finished);
                  }
                }
              });
            }
            break;
          case 1:
            this.genericService.finishFunction(finished);
            break;
        }
      });
    }
  }

  public functionCase59() {
    this.functionsService.downloadPDF(1);
  }

  public functionCase60() {
    this.functionsService.downloadPDF(0);
  }

  public functionCase61(structure, param, finished) {
    let validReq = this.functionsService.formsArray(structure, param);
    if (validReq) {
      let values = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      this.endpointClientService
        .sendEmailInmueble(
          values,
          this.authService.userId,
          this.authService.getIdEmpresa()
        )
        .subscribe((data) => { });
    } else {
      this.genericService.finishFunction(finished);
      // Joan: Warning després de l'upgrade de 9 a 14. Ho canvio
      //return new EmptyObservable<Response>();
      return EMPTY;
    }
  }

  public functionCase304(structure, param, finished) {
    this.genericService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importeTotal = 0;
      let meses = 0;
      let importeRenta = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "gest_alquiler_reservas" && values[i]["field"] == "renta_total" && values[i]["value"] && values[i]["value"] > 0) {
          importeRenta = values[i]["value"];
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "gest_alquiler_reservas" && values[i]["field"] == "meses_alquiler_comodin" && values[i]["value"] && values[i]["value"] > 0) {
          meses = values[i]["value"];
        }
      }
  
      if (!isNaN(importeRenta) && isFinite(importeRenta)) {
        let porcentaje = 100;
        if (meses == 1 || meses == 2) porcentaje = 60;
        if (meses == 3 || meses == 4) porcentaje = 80;
        if (meses > 5) porcentaje = 120;
        importeTotal = Math.round((+importeRenta) * porcentaje / 100);
        let importeTotalDecimal = importeTotal.toString().replace('.', ',');
        let idFA = this.genericService.findFAWithTableField(1, "gest_alquiler_reservas", "honorarios", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
  }

  public functionCase296(structure, param, finished) {
    this.genericService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importePendiente = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_diferencia" && values[i]["field"] == "comodin_diferencia_importe" && values[i]["value"]) {
          importePendiente = +values[i]["value"];
        }
      }
  
      if (!isNaN(importePendiente) && isFinite(importePendiente)) {
        importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
        let idFA = this.genericService.findFAWithTableField(1, "actividades_calendario_tickets_payment_methods", "importe", structure["id_functional_parent_initial_dsb"], structure['duplicate_id_functional_area']);
        let val = this.functionsService.cloneVariable(idFA["tmp_value"]).replace(',', '.')
        if (val) importePendiente = +importePendiente + +val;
        importePendiente = Math.round((+importePendiente + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importePendiente.toString().replace('.', ',');
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      }
    });
  }

  public functionCase297(structure, param, finished) {
    this.genericService.finishFunction(finished);
    setTimeout(() => {
      let validReq = this.functionsService.formsArray(structure, param, false);
      let valuesOriginal = JSON.parse(JSON.stringify(this.functionsService.tmpArray));
      let values = this.functionsService.cloneVariable(valuesOriginal);
      let importe5c = 0;
      let importe10c = 0;
      let importe20c = 0;
      let importe50c = 0;
      let importe1e = 0;
      let importe2e = 0;
      let importe5e = 0;
      let importe10e = 0;
      let importe20e = 0;
      let importe50e = 0;
      let importe100e = 0;
      let importe200e = 0;
      let importe500e = 0;
      let importe_saldo_inicial = 0;
      for (let i in values) {
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe5c = values[i]["value"] * 0.05;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe10c = values[i]["value"] * 0.10;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe20c = values[i]["value"] * 0.20;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_centimos" && values[i]["value"] && values[i]["value"] > 0) {
          importe50c = values[i]["value"] * 0.50;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_1_euro" && values[i]["value"] && values[i]["value"] > 0) {
          importe1e = values[i]["value"] * 1;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_2_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe2e = values[i]["value"] * 2;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_5_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe5e = values[i]["value"] * 5;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_10_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe10e = values[i]["value"] * 10;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_20_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe20e = values[i]["value"] * 20;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_50_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe50e = values[i]["value"] * 50;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_100_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe100e = values[i]["value"] * 100;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_200_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe200e = values[i]["value"] * 200;
        }
        if (values[i]["database"] == 1 && values[i]["table"] == "comodin_rest_pedidos" && values[i]["field"] == "comodin_500_euros" && values[i]["value"] && values[i]["value"] > 0) {
          importe500e = values[i]["value"] * 500;
        }
      }
      console.log("DANI 00: ", importe5c, importe10c, importe20c, importe50c, importe1e, importe2e, importe5e, importe10e, importe20e, importe50e, importe100e, importe200e, importe500e);
      importe_saldo_inicial = importe5c + importe10c + importe20c + importe50c + importe1e + importe2e + importe5e + importe10e + importe20e + importe50e + importe100e + importe200e + importe500e;
      if (!isNaN(importe_saldo_inicial) && isFinite(importe_saldo_inicial)) {
        importe_saldo_inicial = Math.round((+importe_saldo_inicial + Number.EPSILON) * 100) / 100;
        let importeTotalDecimal = importe_saldo_inicial.toString().replace('.', ',');
        let idFA = this.genericService.findFAWithTableField(1, "actividades_calendario_cierres_caja", "importe_inicial", structure["id_functional_parent_initial_dsb"]);
        this.functionsService.assignValueFunctionalArea(idFA, importeTotalDecimal);
      } else {
        console.log("El importe total no es un número válido.");
      }
    });
  }
}
