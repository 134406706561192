import {Component, Input, OnInit} from '@angular/core';

//SERVICES
import { FunctionsControllerService } from '../../../services/generic/functions-controller.service';
import { GenericService } from '../../../services/generic/generic.service';

@Component({
  selector: 'app-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.css']
})
export class BoxComponent implements OnInit {

  @Input() structure = null;
  @Input() param : any = null;

  public idTable = null;
  public idRow = null;

  constructor(public functionsControllerService: FunctionsControllerService,
    public genericService: GenericService) {
    }

  ngOnInit(): void {
    if(this.param !== null) {
      let aux = this.param.split('.')
      if(aux.length == 2) {
        this.idTable = aux[0]
        this.idRow = aux[1]
      }
      else if(aux.length == 3) {
        this.idTable = aux[0] + '.' + aux[1]
        this.idRow = aux[2]
      }
    }
  }

  onClickEvent(event) {
    this.structure['isPressingControl'] = event !== undefined && (event.ctrlKey || event.metaKey);
    if (this.structure && this.structure["id_function"]) {
      //this.functionsControllerService.getFunction(eventTrigger['structure']);
      if(this.idTable == null && this.idRow == null) {
        let finished = {done: true};
        this.functionsControllerService.getFunction(this.structure, null, finished);
      } else {
        this.genericService.tablesActualRows.push({"id_functional_area": this.idTable, "rowID": this.idRow});
        this.functionsControllerService.getFunction(this.structure, this.genericService.tableData[this.idTable]['data'][this.idRow], this.idTable)
      }
    }
  }
}
