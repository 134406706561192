import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs-compat';
import { EndpointService } from '../generic/endpoint.service';
import { GenericService } from '../generic/generic.service';

@Injectable({
  providedIn: 'root'
})
export class JourneyService {
  emailTemplates: Observable<any[]>;
  journeyAction: Observable<any[]>;
  journeys: Observable<any[]>;
  smsTemplates: Observable<any[]>;
  tagsTemplates: Observable<any[]>;
  whatappTemplates: Observable<any[]>;
  actionTypes: Observable<any[]>;
  triggerTypes: Observable<any[]>;
  journeyActionDefault: Observable<any[]>;
  temporalities: Observable<any[]>;
  campaigns: Observable<any[]>;
  emailConfig: Observable<any[]>;
  emailConfigArray = [];
  public actualElement: any;
  public optionAutocompleteSelected = false;
  public optionAutocompleteSelected2 = false;
  public idJourney = 0;
  public applyChanges = true;
  public isLastElement = false;
  public copyElement: Observable<any[]>;
  public elementsJourney = [];

  constructor( public genericService: GenericService,
    public formBuilder: FormBuilder,
    public endpointService: EndpointService) {
  }

  formBuilderCustom(element) {
    console.log('=====', element)
    element = this.genericService.cloneVariable(element);
    this.adaptAutocompletes(element);
    return this.formBuilder.group({
      name: [element.name, Validators.required],
      notes: [element.notes],
      id_type_action: [element.id_type_action, Validators.required],
      id_template: [element.id_template],
      id_temporality: [element.id_temporality],
      num_temporality: [element.num_temporality],
      id_tag: [element.id_tag],
      id_action_new: [element.id_action_new],
      id_campaign: [element.id_campaign],
      id_template_sent: [element.id_template_sent],
      id_empresa_email_config: [element.id_empresa_email_config],
    })
  }

  adaptAutocompletes(value) {
    if(value['id_type_action'] == 1) {
      for(let i in this.emailTemplates) {
        if(this.emailTemplates[i]['id'] == value['id_template']) {
          value['id_template'] = this.emailTemplates[i]['nombre'];
          break;
        }
      }
      for(let i in this.emailConfig) {
        if(this.emailConfig[i]['id'] == value['id_empresa_email_config']) {
          value['id_empresa_email_config'] = this.emailConfig[i]['email'];
          break;
        }
      }
    } else if(value['id_type_action'] == 2) {
      for(let i in this.whatappTemplates) {
        if(this.whatappTemplates[i]['id'] == value['id_template']) {
          value['id_template'] = this.whatappTemplates[i]['nombre'];
          break;
        }
      }
    } else if(value['id_type_action'] == 3) {
      for(let i in this.smsTemplates) {
        if(this.smsTemplates[i]['id'] == value['id_template']) {
          value['id_template'] = this.smsTemplates[i]['nombre'];
          break;
        }
      }
    } else if(value['id_type_action'] == 4 || value['id_type_action'] == 8 || value['id_type_action'] == 9) {
      for(let i in this.tagsTemplates) {
        if(this.tagsTemplates[i]['id'] == value['id_tag']) {
          value['id_tag'] = this.tagsTemplates[i]['nombre'];
          break;
        }
      }
    } else if(value['id_type_action'] == 6) {
      for(let i in this.journeys) {
        if(this.journeys[i]['id'] == value['id_action_new']) {
          value['id_action_new'] = this.journeys[i]['name'];
          break;
        }
      }
    } else if(value['id_type_action'] == 7) {
      for(let i in this.journeyAction) {
        if(this.journeyAction[i]['id'] == value['id_action_new']) {
          value['id_action_new'] = this.journeyAction[i]['name'];
          break;
        }
      }
    } else if(value['id_type_action'] == 17) {
      for(let i in this.campaigns) {
        if(this.campaigns[i]['id_campaign'] == value['id_campaign']) {
          value['id_campaign'] = this.campaigns[i]['name'];
          break;
        }
      }
    } else if(value['id_type_action'] == 18) {
      for(let i in this.emailTemplates) {
        if(this.emailTemplates[i]['id'] == value['id_template_sent']) {
          value['id_template_sent'] = this.emailTemplates[i]['nombre'];
          break;
        }
      }
    } else if(value['id_type_action'] == 19) {
      for(let i in this.emailTemplates) {
        if(this.emailTemplates[i]['id'] == value['id_template_sent']) {
          value['id_template_sent'] = this.emailTemplates[i]['nombre'];
          break;
        }
      }
    }
  }

  _filterEmailTemplates(value: any): any {
    const filterValue = value.nombre ? value.nombre.toLowerCase() : value.toLowerCase();
    return this.emailTemplates.filter((option: any) => option.nombre.toLowerCase().includes(filterValue));
  }

  _filterJourneyAction(value: any): any {
    const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();
    return this.journeyAction.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  _filterJourneys(value: any): any {
    const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();
    return this.journeys.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  _filterSmsTemplates(value: any): any {
    const filterValue = value.nombre ? value.nombre.toLowerCase() : value.toLowerCase();
    return this.smsTemplates.filter((option: any) => option.nombre.toLowerCase().includes(filterValue));
  }

  _filterTagsTemplates(value: any): any {
    const filterValue = value.nombre ? value.nombre.toLowerCase() : value.toLowerCase();
    return this.tagsTemplates.filter((option: any) => option.nombre.toLowerCase().includes(filterValue));
  }

  _filterWhatappTemplates(value: any): any {
    const filterValue = value.nombre ? value.nombre.toLowerCase() : value.toLowerCase();
    return this.whatappTemplates.filter((option: any) => option.nombre.toLowerCase().includes(filterValue));
  }

  _filterCampaigns(value: any): any {
    const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();
    return this.campaigns.filter((option: any) => option.name.toLowerCase().includes(filterValue));
  }

  _filterEmailConfig(value: any): any {
    const filterValue = value.email ? value.email.toLowerCase() : value.toLowerCase();
    return this.emailConfig.filter((option: any) => option.email.toLowerCase().includes(filterValue));
  }
}
