import {
  AfterViewChecked, AfterViewInit, ChangeDetectorRef,
  Component,
  ElementRef, OnDestroy,
  OnInit, QueryList,
  ViewChild, ViewChildren, Input, EventEmitter
} from '@angular/core';
import {ChatService} from "../services/chat.service";
import {AuthService} from "../services/auth/auth.service";
import {DatePipe, KeyValue} from "@angular/common";
import {EndpointService} from "../services/generic/endpoint.service";
import {MatDialog} from "@angular/material/dialog";
import {SelectGroupUsersComponent} from "./select-group-users/select-group-users.component";
import {UserBody} from "../users/objects/user-body";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {BottomSheetNewChatContactComponent} from "./bottom-sheet-new-chat-contact/bottom-sheet-new-chat-contact.component";
import {RoomChat} from "./models/room-chat";
import {GroupRoomChat} from "./models/group-room-chat";
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  // host: {'class': 'nb-theme-corporate'}
})


export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {

  private _name = {};
  @Input()
  get name(): {} { return this._name; }
  set name(name: {}) {
    /* COMENTADO FALLA --> SOUFIANE
    if(name.key){
      this.chatService.selectedChat.id = name.key;
      this.chatService.selectedChat.chatType = name.groupType;
      this.getRoomInfo(name.key, name.groupType, name.isPublic);
    }
    */
  }

  selectedUser: any;
  // selectedChat: SelectedChatInfo = {id: '-1', chatType: -1};

  chatItem: any;
  agent: any;

  newChatComment: string = '';
  // scrollChat: ElementRef;

  searchText: string;
  @ViewChildren('scrollMe') public myScrollContainer: QueryList<any>;

  public chatContacts: UserBody[];
  private inmoId: number;
  public loaded: boolean;
  public groupDetails: boolean;
  public addContact: boolean;
  public searchChat: boolean;
  public userId;
  public prueba: any;
  subscription: any;
  typing: boolean = false;
  timeout: any;
  selectedChat: any;
  lastSelectedChat: any;
  groupData: any = {};
  invitation_loaded: boolean;
  userBelongsToGroup= new Map();
  public_info_loaded: boolean = false;
  invitation_link = "";
  constructor(
    public chatServiceOLD: ChatService,
    public authService: AuthService,
    private endpointService: EndpointService,
    public dialog: MatDialog,
    public datepipe: DatePipe,
    private _bottomSheet: MatBottomSheet,
    public chatService: ChatService,
    public cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.chatService.initIoConnection();
    this.chatContacts = [];
    //ODk4XzIwMjEtMDQtMTMgMTA6MTg6NDY= 
    //ODk4XzIwMjEtMDQtMTMgMTA6MTg6NDY=
    this.subscription = this.chatService.scroll()
      .subscribe(res => {
        console.log("Test ngOnInit");
        console.log(res);
        this.scrollChat(this.myScrollContainer, res);
      });
  }

  ngOnDestroy() {
    // Guardar la fecha en la que se ha salido del chat
    // set offline? 5UM7CUALAZQN24
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.userId = this.authService.userId;
      this.loaded = true;
      this.groupDetails = false;
      this.addContact = false;
      this.searchChatOut();
      this.getChatContacts();
      this.chatService.getPublicGroups();

      if (this.chatService.selectedChat.id !== '-1') {
        this.getRoomInfo(this.chatService.selectedChat.id, this.chatService.selectedChat.chatType);
      }
      this.cdRef.detectChanges();
    }, 0);
  }
  

  getChatContacts() {
    this.endpointService.getChatContacts(this.authService.inmoId, this.authService.user_token).subscribe(data => {
      console.log('chat', data);
      data['response'].forEach(user => {
        let us = new UserBody();
        us.handleGetUserResponse(user);
        ///console.log(us);
        this.chatContacts.push(us);
      });
      //console.log(this.chatContacts);
    });
  }

  searchChatOut() {
    this.searchChat = false;
    this.searchText = '';
  }

  openCreateGroupDialog(): void {
    // Joan: Ho comento (m'ho ha dit el Jan) perquè crida a un dialog que també crida l'agenda compartida, que estic modificant. Caldria revisar-ho en algun moment
    /*
    this.addContact = false;
    this.searchChatOut();
    // ERROR! no se ha importado SelectGroupUsersComponent en app.module
    const dialogRef = this.dialog.open(SelectGroupUsersComponent, {
      width: 'auto',
      height: 'auto',
      data: {users: this.chatContacts, opType: 1, myToken: this.authService.user_token},
      autoFocus: true
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('chat', result, this.chatService.PrivateChatRoomsMap);
    });
    */
  }

  processMessageChat(message: string) {
    return new Promise((resolve, reject) => {
      let i = 0;
      while (i < message.length &&
      ( message.charAt(i) === ' '
        || message.charAt(i) === '\t'
        || message.charAt(i) === '\n'
        || message.charAt(i) === '\r'
        || message.charAt(i) === '\r\n'
      )) {
        //console.log(newChatComment.charAt(i));
        i++;
      }
      // console.log(i);
      resolve(message.slice(i));
    });
  }

  sendMessage(newChatComment: string, selectedChat: string, event: any) {
    event.preventDefault();
    this.processMessageChat(newChatComment).then(async (message: string) => {
      newChatComment = message;
      if (newChatComment.length > 0) {
        this.chatService.sendMessage(this.authService.user_token, 1, this.newChatComment, selectedChat);
        this.newChatComment = '';
        this.scrollChat(this.myScrollContainer);
      } else {
        this.newChatComment = '';
      }
    })
  }

  asIsOrder(a, b) {
    return b[1] - a[1];
  }

  valueAscOrder = (a: KeyValue<string, RoomChat | GroupRoomChat | InmoGroupRoom>,
                   b: KeyValue<string, RoomChat | GroupRoomChat | InmoGroupRoom>) => {

    if(a.value.belongs && ! b.value.belongs) {
      return -1;
    } else if(! a.value.belongs && b.value.belongs) {
      return 1;
    } else {
      return (new Date(a.value.lastMessage) > new Date(b.value.lastMessage)) ? -1 : 
      (new Date(b.value.lastMessage) > new Date(a.value.lastMessage) ? 1 : 0);
    }
      
  }
  accept(groupId, userId){
    console.log('accepting', groupId, userId);
    this.chatService.acceptGroupInvitation(groupId, userId).subscribe(data => {
      this.chatService.userGroup[groupId][userId]['accepted'] = true;
    });
  }
  reject(groupId, userId){
    console.log('rejecting', groupId, userId);
    this.chatService.rejectGroupInvitation(groupId, userId).subscribe(data => {
      this.chatService.userGroup[groupId][userId]['reject'] = true;
    });
  }

  joinPublicGroup(roomId){
    this.chatService.joinPublicGroup(roomId).subscribe(data => {
      if(!this.chatService.userGroup[roomId][this.authService.user_token]){
        this.chatService.userGroup[roomId][this.authService.user_token] = {}
      }
      this.chatService.userGroup[roomId][this.authService.user_token]['left'] = false;
      console.log(this.chatService.userGroup);
      this.public_info_loaded = true;
    });
  }

  leaveGroup(roomId){
    this.chatService.leaveGroup(roomId).subscribe(data => {
      if(!this.chatService.userGroup[roomId][this.authService.user_token]){
        this.chatService.userGroup[roomId][this.authService.user_token] = {}
      }
      this.chatService.userGroup[roomId][this.authService.user_token]['left'] = true;
      if(roomId < 12){
        console.log('test');
      }
    });
  }

  privateGroupAccepted(){
    return this.chatService.userGroup[this.chatService.selectedChat.id] && 
    this.chatService.userGroup[this.chatService.selectedChat.id][this.authService.user_token]['accepted'];
  }

  privateGroupRejected(){
    return this.chatService.userGroup[this.chatService.selectedChat.id] && 
    this.chatService.userGroup[this.chatService.selectedChat.id][this.authService.user_token]['rejected'];
  }

  belongsToPublicGroup() {
    return this.chatService.userGroup[this.chatService.selectedChat.id] && 
    this.chatService.userGroup[this.chatService.selectedChat.id][this.authService.user_token] &&
    this.chatService.userGroup[this.chatService.selectedChat.id][this.authService.user_token]['left'] == false;
  }

  generateInvitationLink(roomId){
    this.chatService.generateLink(roomId).subscribe(data => {
      console.log(data);
    });
  }

  getInvitationLink(roomId){
    this.chatService.getInvitationLink(roomId).subscribe(data => {
      this.invitation_link = data[0].invitation_token;
    });
  }
  

  getRoomInfo(key: string, roomTypeId: number, isPublicGroup?) {
    if(key){
    console.log('VOY A OBTENER INFO!!!', key, roomTypeId);
    let auxPreviousRoomId = this.chatService.selectedChat.id;
    this.groupDetails = false;
    this.addContact = false;
    this.searchChatOut();
    this.invitation_loaded = true;
    
    if(roomTypeId == 2 ){ // Mostrar menu de invitación a grupo => cargar info de las invitaciones grupo.
 
      isPublicGroup = this.chatService.PrivateChatRoomsMap.get(key).isPublic == 1; // isPublic == 2 => privado

      if(isPublicGroup){
        this.chatService.userBelongsToGroup(key).subscribe((data)=>{
          if(data.length == 0) {
            this.userBelongsToGroup.set(key, false);
          } else{
            this.userBelongsToGroup.set(key, true);
          }

          this.public_info_loaded = true;
          
        });
      }
    }
    
    if(auxPreviousRoomId !== key) {
      this.loaded = false;
      if(auxPreviousRoomId !== '-1') {

        let l = this.chatService.PrivateChatRoomsMap.get(auxPreviousRoomId).messages.length;
        for(let i = l-1;i>=0;i--){
          if(this.chatService.PrivateChatRoomsMap.get(auxPreviousRoomId).messages[i].new == false) break;
          else this.chatService.PrivateChatRoomsMap.get(auxPreviousRoomId).messages[i].new = false;
        }

        if(this.chatService.PrivateChatRoomsMap.get(auxPreviousRoomId).nonReadMessages > 0){
          if(this.chatService.non_read > 0) this.chatService.non_read -= 1;
        }
        this.chatService.PrivateChatRoomsMap.get(auxPreviousRoomId).nonReadMessages = 0;
      }
      if (!this.chatService.PrivateChatRoomsMap.get(key).infoCargada) {

        this.chatService.onEnteredChat(key);
        this.chatService.getMessages(this.authService.user_token, key);
        this.setCurrentChat(key, roomTypeId);
      } else {
        if(this.chatService.PrivateChatRoomsMap.get(key).nonReadMessages > 0){
          if(this.chatService.non_read > 0) this.chatService.non_read -= 1;
        }
        this.chatService.onEnteredChat(key);
        this.setCurrentChat(key, roomTypeId);
      }
      this.scrollChat(this.myScrollContainer);
      this.loaded = true;
      //this.chatService.setMensajesLeidos(key, this.authService.user_token);
      
      for(let i=0;i < this.chatService.PrivateChatRoomsMap.get(key).newmessages.length;++i){
        this.chatService.PrivateChatRoomsMap.get(key).messages.push(this.chatService.PrivateChatRoomsMap.get(key).newmessages[i]);
      }

      this.chatService.PrivateChatRoomsMap.get(key).newmessages = [];
      
    }
    else {
      /*if (this.myScrollContainer) {
        this.scrollBottom(this.scrollChat);
      }*/
      this.scrollChat(this.myScrollContainer);
      this.chatService.PrivateChatRoomsMap.get(key).infoCargada = true;
      console.log('no has cambiado de chat');
    }
  }

  }

  setCurrentChat(key, roomTypeId) {
    this.chatService.selectedChat.id = key;
    this.chatService.selectedChat.chatType = roomTypeId;
    /*if (this.myScrollContainer) {
      this.scrollBottom(this.scrollChat);
    }*/
  }

  scrollChat(scrollMe: QueryList<any>, op?: number) {
    // console.log(scrollMe);
    setTimeout(() => {
      let operation = op ? op : 0
      // console.log(scrollMe.first.nativeElement.scrollTop);
      // console.log(scrollMe.first.nativeElement.scrollHeight);
      // console.log(operation);
      if (operation === 0) { // Scroll bottom
        scrollMe.first.nativeElement.scrollTop = scrollMe.first.nativeElement.scrollHeight;
      }
      else if (operation === 1) { // Scroll top
        scrollMe.first.nativeElement.scrollTo(0, 0);
      }
    }, 0);
  }

  openGroupInfo(selectedChat: string) {
    // console.log(this.chatService.PrivateChatRoomsMap.get(selectedChat));
    /*
    const dialogRef = this.dialog.open(ChatGroupInfoComponent, {
      width: 'auto',
      height: '70vh',
      data: {group: this.chatService.PrivateChatRoomsMap.get(selectedChat)},
      autoFocus: true
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('DIALOG WAS CLOSED!!!');
    });
   */
  }

  openAddContactDialog() {
    const bottomSheet = this._bottomSheet.open(BottomSheetNewChatContactComponent, {
      data : {myId: this.authService.user_token}
    });

    bottomSheet.afterDismissed().subscribe( data => { });
  }

  openChatConversation(user: UserBody) {
    this.addContact = false;
    this.searchChatOut();
    console.log('Voy a crear una conversación con este usuario');
    console.log(user);

    this.chatService.createGroup(1, this.authService.user_token, [{userId: user.userToken, isAdmin: true, canTalk: true}],
      undefined, undefined,
      undefined, true,
      true);

    /*this.chatService.requestChatWithUser(user.userToken, this.authService.user_token).then(res => {
      if (res['responseType'] === 1) {
        if (this.chatService.PrivateChatRoomsMap.has(res['roomId'])) {
          this.getRoomInfo(res['roomId'], res['roomType']);
        }
      }
      else if (res['responseType'] === 2) {
        if (this.chatService.PrivateChatRoomsMap.has(res['roomId'])) {
          this.getRoomInfo(res['roomId'], res['roomType']);
        }
      }
    });*/
  }

  onKeydown(event: any) {
    event.preventDefault();
  }

  onTyping(event: any, selectedChat: any){
    event.preventDefault();
    this.selectedChat = selectedChat;
    let _this = this;

    if(this.typing == false) {
      this.typing = true
      this.chatService.onTyping(selectedChat);
      this.timeout = setTimeout(function() {
        _this.typing = false;
        _this.chatService.onNoLongerTyping(_this.selectedChat);
      }, 3000);
    } else {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function() {
        _this.typing = false;
        _this.chatService.onNoLongerTyping(_this.selectedChat);
      }, 3000);
    }
  }

  paginate() {
    this.chatService.paginate(this.chatService.selectedChat.id, this.authService.user_token).then(res => {
      this.scrollChat(this.myScrollContainer, 1);
    });
  }
}

import {InmoGroupRoom} from "./models/inmo-group-room";

export class SelectedChatInfo {
  id: string;
  chatType: number;
}
