import {Component, Inject, OnInit} from "@angular/core";
import {MatIconModule} from "@angular/material/icon";
import {MatDialog} from "@angular/material/dialog";
import {EndpointService} from "../../services/generic/endpoint.service";
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from "@angular/material/snack-bar";
import {AuthService} from "../../services/auth/auth.service";
import {DatePipe} from "@angular/common";
import {AdviceReportService} from "../../services/advice-report/advice-report.service";
import {AppComponent} from "../../app.component";

@Component({
  selector: 'auto-snackbar',
  templateUrl: './auto-snackbar.component.html',
  styleUrls: ['./auto-snackbar.component.css']
})

export class AutoSnackbarComponent implements OnInit{

  public index = null;

  private iniDate;

  public personalizado: boolean;
  public mensaje: string;

  public title: string;
  public subtitle: string;
  public icon: string;

  public iconActive: boolean;
  public iconTooltipe: string;

  public colorTitle: string;
  public colorSubtitle: string;
  public colorIcon: string;
  public background: string;

  public button1: boolean;
  public button2: boolean;
  public button3: boolean;

  public button1Text: string;
  public button2Text: string;
  public button3Text: string;

  public button1Background: string;
  public button2Background: string;
  public button3Background: string;

  public button1Color: string;
  public button2Color: string;
  public button3Color: string;

  public button1Border: string;
  public button2Border: string;
  public button3Border: string;

  public button1Icon: string;
  public button2Icon: string;
  public button3Icon: string;

  public button1IconColor: string;
  public button2IconColor: string;
  public button3IconColor: string;

  public button1Tooltipe: string;
  public button2Tooltipe: string;
  public button3Tooltipe: string;

  public button1ActiveIcon: boolean;
  public button2ActiveIcon: boolean;
  public button3ActiveIcon: boolean;

  public colorClose: string;
  public close: boolean;

  public action1;
  public action2;
  public action3;

  public link1: string;
  public link2: string;
  public link3: string;

  public pantalla1: number;
  public pantalla2: number;
  public pantalla3: number;

  public otra_plantilla1: number;
  public otra_plantilla2: number;
  public otra_plantilla3: number;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any,
              private _snackRef: MatSnackBarRef<AutoSnackbarComponent>,
              private adviceReportService: AdviceReportService,
              private authService: AuthService,
              private endpointService: EndpointService) {

  }


  ngOnInit(): void {

    this.index = this.data.index

    this.iniDate = new Date();

    this.personalizado = this.data.plantilla.personalizado === 1;
    this.mensaje = this.data.mensaje;

    this.title = this.data.plantilla.title;
    this.subtitle = this.data.plantilla.subtitle;
    this.icon = this.data.plantilla.icon;

    this.iconActive = this.data.plantilla.icon_active;
    this.iconTooltipe = this.data.plantilla.icon_tooltipe;

    this.colorTitle = this.data.plantilla.titleColor;
    this.colorSubtitle = this.data.plantilla.subtitleColor;
    this.colorIcon = this.data.plantilla.icon_color;
    this.background = this.data.plantilla.backgroundColor;

    this.button1 = this.data.boton[0]?.active;
    this.button2 = this.data.boton[1]?.active;
    this.button3 = this.data.boton[2]?.active;

    this.button1Text = this.data.boton[0]?.text;
    this.button2Text = this.data.boton[1]?.text;
    this.button3Text = this.data.boton[2]?.text;

    this.button1Background = this.data.boton[0]?.colorBackground;
    this.button2Background = this.data.boton[1]?.colorBackground;
    this.button3Background = this.data.boton[2]?.colorBackground;

    this.button1Color = this.data.boton[0]?.colorText;
    this.button2Color = this.data.boton[1]?.colorText;
    this.button3Color = this.data.boton[2]?.colorText;

    this.button1Border = this.data.boton[0]?.colorEdge;
    this.button2Border = this.data.boton[1]?.colorEdge;
    this.button3Border = this.data.boton[2]?.colorEdge;


    this.button1Icon = this.data.boton[0]?.icon;
    this.button2Icon = this.data.boton[1]?.icon;
    this.button3Icon = this.data.boton[2]?.icon;

    this.button1IconColor = this.data.boton[0]?.icon_color;
    this.button2IconColor = this.data.boton[1]?.icon_color;
    this.button3IconColor = this.data.boton[2]?.icon_color;

    this.button1Tooltipe = this.data.boton[0]?.icon_tooltipe;
    this.button2Tooltipe = this.data.boton[1]?.icon_tooltipe;
    this.button3Tooltipe = this.data.boton[2]?.icon_tooltipe;

    this.button1ActiveIcon = this.data.boton[0]?.icon_active;
    this.button2ActiveIcon = this.data.boton[1]?.icon_active;
    this.button3ActiveIcon = this.data.boton[2]?.icon_active;

    this.colorClose = this.data.plantilla.colorCerrar;
    this.close = this.data.plantilla.cerrar;

    this.action1 = this.data.boton[0]?.actionArray;
    this.action2 = this.data.boton[1]?.actionArray;
    this.action3 = this.data.boton[2]?.actionArray;

    this.link1 = this.data.boton[0]?.link;
    this.link2 = this.data.boton[1]?.link;
    this.link3 = this.data.boton[2]?.link;

    this.pantalla1 = this.data.boton[0]?.pantalla;
    this.pantalla2 = this.data.boton[1]?.pantalla;
    this.pantalla3 = this.data.boton[2]?.pantalla;

    this.otra_plantilla1 = this.data.boton[0]?.otra_plantilla;
    this.otra_plantilla2 = this.data.boton[1]?.otra_plantilla;
    this.otra_plantilla3 = this.data.boton[2]?.otra_plantilla;

    this._snackRef.afterDismissed().subscribe(() =>{
      let noti = this.adviceReportService.notifications.find(sup => sup.id_plantilla_ego === this.index)
      if(!this.data.demo) {
        this.adviceReportService.clickMonitoreoEgo(this.data.plantilla.id, null, null, this.iniDate, this.authService.userId);
      }
      if(noti !== undefined) this.adviceReportService.notifications = this.adviceReportService.notifications.filter(sup => sup.id_plantilla_ego !== this.index)
      console.log(noti)
      console.log(this.data)
      if(noti !== undefined) {
        this.endpointService.updateEgoNotificationFinalHour(noti.id_not, true).subscribe(data => {
          console.log('SE HA ACTUALIZADO CORRECTAMENTE LA HORA FINAL DE LA NOTIFICACIÓN');
        });
      }
    })

  }

  /*dismiss(){
    this._snackRef.dismiss();
  }*/

  closeSnackBar() {
    if(!this.data.demo) {
      this.adviceReportService.clickMonitoreoEgo(this.data.plantilla.id, null, null, this.iniDate, this.authService.userId);
    }
    this._snackRef.dismiss();
    this.endpointService.updateEgoNotificationFinalHour(this.data.not, true).subscribe(data => {
      console.log('SE HA ACTUALIZADO CORRECTAMENTE LA HORA FINAL DE LA NOTIFICACIÓN');
    });
  }

  buttonAction(i) {

    let idButton = null;

    switch (i) {
      case 0:
        this.ejecuteButtonAction(this.action1);
        idButton = this.action1.id_button;
        break;
      case 1:
        this.ejecuteButtonAction(this.action2);
        idButton = this.action2.id_button;
        break;
      case 2:
        this.ejecuteButtonAction(this.action3);
        idButton = this.action3.id_button;
        break;
    }

    if(!this.data.demo) {
      this.adviceReportService.clickMonitoreoEgo(this.data.plantilla.id, null, idButton, this.iniDate, this.authService.userId);
    }

  }

  ejecuteButtonAction(buttonAction){

    if(!this.data.demo) {

      let redirect = new Array();
      let externActions = new Array();

      for (let x = 0; x < buttonAction.length; x++) {
        if (buttonAction[x].action == 4) this.closeSnackBar();
        else if (buttonAction[x].action == 2 ||
          buttonAction[x].action == 24 ||
          buttonAction[x].action == 25) redirect.push(buttonAction[x]);
        else {
          externActions.push(buttonAction[x]);
          if (buttonAction[x].action == 6 ||
            buttonAction[x].action == 7) externActions[externActions.length - 1].id = this.data.usuario_secundario;
        }
      }

      this.adviceReportService.tratarAcciones(externActions, redirect);
    }
  }

}
