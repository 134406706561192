import { Component, Input, OnInit } from '@angular/core';
import { EndpointService } from 'app/services/generic/endpoint.service';
import { AuthService } from 'app/services/auth/auth.service';
import { FunctionsService } from 'app/services/generic/functions.service';
import { GenericService } from 'app/services/generic/generic.service';
import mermaid from 'mermaid';
import { FunctionsControllerService } from 'app/services/generic/functions-controller.service';

@Component({
  selector: 'app-db-schema-custom',
  templateUrl: './db-schema-custom.component.html',
  styleUrls: ['./db-schema-custom.component.scss']
})
export class DbSchemaCustomComponent implements OnInit {

  mermaidDefinition: string;
  public loaded = false;
  showOptions = 1;
  id_schema = 1;

  @Input() structure = null;
  @Input() index: number;
  @Input() param: any = null;


  constructor(private endpointService: EndpointService, public authService: AuthService, public functionsService: FunctionsService, public functionsControllerService: FunctionsControllerService, public genericService: GenericService) { }

  ngOnInit(): void {
    let routeParams = this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['input'];
    for (let k in routeParams) {
      if (routeParams[k]['id_db'] == 3 && routeParams[k]['bd_table'] == 'db_schemas' && routeParams[k]['bd_field'] == 'id_schema') {
        this.id_schema = routeParams[k]['value'];
        this.loadStructure();
        break;
      }
    }
  }

  changeShowOptions() {
    setTimeout(() => {
      this.loadStructure();
    });
  }

  loadStructure() {
    this.loaded = false;
    this.endpointService.getDBSchema(this.id_schema, this.showOptions).subscribe((resp) => {
      if (resp["response"] != null && resp["response"] != "") {
        let data = resp["response"];

        const tables = Array.from(new Set(data.fields.map(pk => pk.TABLE_NAME)));
        const relations = data.foreignKeys;

        // Crear la definición de Mermaid
        let definition = "%%{init: {'theme': 'base', 'themeVariables': { 'darkMode' : true, 'fontSize': '12px', 'primaryColor': '" + this.authService.primary_color + 60 + "' , 'primaryTextColor': '#000' , 'secondaryTextColor': '#000', 'secondaryColor': '" + this.authService.tertiary_color + 99 + "' , 'tertiaryColor': '" + this.authService.secondary_color + 99 + "' , 'primaryBorderColor': '" + this.authService.primary_color + 80 + "' , 'secondaryBorderColor': '" + this.authService.tertiary_color + 80 + "'}}}%%\n";
        definition += 'erDiagram\n';

        // Definir las tablas
        tables.forEach(table => {
          definition += `  ${table} {\n`;
          const fields = data.fields.filter(pk => pk.TABLE_NAME === table);
          fields.forEach(pk => {
            definition += `${pk.DATA_TYPE} ${pk.COLUMN_NAME}`;
            if(pk.COLUMN_KEY == "PRI") definition += ' PK';
            if(pk.COLUMN_KEY == "MUL") definition += ' FK';
            if(pk.COLUMN_KEY == "UNI") definition += ' UNI';
            definition += ' \n';
          });
          definition += '}\n';
        });

        // Definir las relaciones
        relations.forEach(rel => {
          definition += `${rel.TABLE_NAME} ||--|{ ${rel.REFERENCED_TABLE_NAME} : "${rel.COLUMN_NAME} => ${rel.REFERENCED_COLUMN_NAME}"\n`;
        });
        this.mermaidDefinition = definition;
        this.loaded = true;
        setTimeout(() => {
          this.renderMermaid();
        });
      }
    });
  }

  renderMermaid(): void {
    mermaid.initialize({ startOnLoad: false });
    const element = document.querySelector('#mermaid-diagram');
    if (element) {
      mermaid.init(undefined, element as HTMLElement);
      setTimeout(() => {
        this.addClickEventListeners();
      }, 1000);
    }
  }
  
  addClickEventListeners(): void {
    const nodes = document.querySelectorAll('#mermaid-diagram g');
    nodes.forEach(node => {
      const tableName = this.extractTableNameFromNode(node);
      node.addEventListener('click', () => this.handleNodeClick(tableName));
    });
  }

  extractTableNameFromNode(node: Element): string {
    const textElement = node.querySelector('.er.entityLabel');
    if (textElement) {
      return textElement.textContent.trim();
    } else {
      return ''; // Manejar el caso donde no se encuentra el texto esperado
    }
  }

  handleNodeClick(node): void {
    console.log('Node clicked:', node);
    let idFounded = false;
    if (!this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]) {
      this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']] = this.genericService.createNewParamVariable();
    }
    let output = this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']];
    if (output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']] && output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output']) {
      for (let j in output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output']) {
        if (output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output'][j]['id_db'] == 3 && output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output'][j]['bd_table'] == "db_schemas_tables" && output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output'][j]['bd_field'] == "bd_table") {
          output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output'][j]['value'] = node;
          idFounded = true;
          break;
        }
      }
    }
    if (!idFounded) {
      let obj = { id_db: 3, bd_table: "db_schemas_tables", bd_field: "bd_table", value: node }
      output['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['output'].push(obj);
    }
  
    this.structure[this.index]['no_save_params'] = true;
    this.functionsControllerService.getFunction(this.structure[this.index]);
    
  }

}
