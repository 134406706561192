import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {PeriodicElement} from "../../bill/bill.component";
import {EndpointService} from "../../services/generic/endpoint.service";
import {AuthService} from "../../services/auth/auth.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {StripePaymentComponent} from "../../stripe/stripe-payment/stripe-payment.component";
import {MatStepper} from "@angular/material/stepper";
import { ShoppingCartDeleteDialogComponent } from './shopping-cart-delete-dialog/shopping-cart-delete-dialog.component';
import {Router} from "@angular/router";
import {GenericService} from "../../services/generic/generic.service";

@Component({
  selector: 'app-shopping-cart',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit {

  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('stripePayment') stripePayment: StripePaymentComponent;

  public cartArray = [];
  public totalCarro: number;
  public movins: number; // numero de movins que se disponen
  public numMovins;
  public precioFinal;
  public movinsMinimasAComprar;

  // Descuentos
  public voucherCode;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { movins },
    public endpointService: EndpointService,
    private dialogRef: MatDialogRef<ShoppingCartComponent>,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public genericService: GenericService
  ) { }

  ngOnInit(): void {
    this.movins = parseInt(this.data.movins, 10);
    this.totalCarro = 0;
    this.cargaCarrito();
  }

  cargaCarrito() {
    this.endpointService.getCarritoItems(this.authService.getIdCompany(true), this.authService.getIdCompanyType(true), this.authService.userId, this.authService.languageId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.cartArray = data['response'];
        this.cartArray.sort((a,b) => (a.id_product_type > b.id_product_type) ? 1 : ((b.id_product_type > a.id_product_type) ? -1 : 0));
        this.calculaTotal();
      }
    });
  }

  calculaTotal() {
    this.totalCarro = 0;
    this.cartArray.forEach(elem => {
      if (elem.precio) this.totalCarro += parseFloat(elem.precio);
    });

    // Round to 2 decimals
    this.totalCarro = Number(this.totalCarro.toFixed(2));
  }

  eliminar(element: any) {
      this.endpointService.getDependentsOfProduct(element.id_product, this.authService.languageId).subscribe(data => {
        if (data['errorCode'] === 0) {
          let productosActuales = this.cartArray;
          let productosAfectados = data['response'];
          let intersection = productosActuales.filter(item1 => productosAfectados.some(item2 => item1.id_product === item2.id_product))

          // Si hay productos dependientes se pregunta antes
          if (intersection.length) {
            const dialogRef = this.dialog.open(ShoppingCartDeleteDialogComponent, {
              data: { producto:element, productos: intersection },
              autoFocus: true
            });

            dialogRef.afterClosed().subscribe(result => {
              // Si se acepta el dialog
              if (result) {
                intersection.push(element);

                this.endpointService.deleteElementsCarrito(intersection, this.authService.userId).subscribe(data => {
                  if (data['errorCode'] === 0) {
                    this.cargaCarrito();
                  } else {
                    this.snackBar.open('Se ha producido un error', 'X', {
                      duration: 8000,
                      panelClass: ['red-snackbar']
                    });
                  }
                });
              }
            });

          // Si no hay productos dependientes se borra directamente
          } else {
            if (!Array.isArray(element))
              element = [element]

            this.endpointService.deleteElementsCarrito(element, this.authService.userId).subscribe(data => {
              if (data['errorCode'] === 0) {
                this.cargaCarrito();
              } else {
                this.snackBar.open('Se ha producido un error', 'X', {
                  duration: 8000,
                  panelClass: ['red-snackbar']
                });
              }
            });
          }

        }
      });

  }

  comprar() {
    // Comprovar si el carro es empty
    if (!this.cartArray.length) {
      this.snackBar.open('El carro está vacío.', 'X', { duration: 6000, panelClass: ['red-snackbar'] });
    }

    // Comprovar si es tenen Movins
    else if (this.movins < this.totalCarro) {
      this.movinsMinimasAComprar = this.totalCarro - this.movins;
      this.stepper.selectedIndex = 1;
    }

    // Fer la compra
    else {
      this.movins = this.movins - this.totalCarro;
      console.log("Comprando carrito:")
      console.log(this.cartArray)
      this.endpointService.addProducts(this.authService.getIdCompany(), this.authService.getIdCompanyType(), this.authService.userId, this.cartArray, this.totalCarro).subscribe(data => {
        if (data['errorCode'] === 0) {
            this.compraRealizada();
        } else
          this.snackBar.open('Ha ocurrido un error', 'X', { duration: 6000, panelClass: ['red-snackbar'] });
      });
    }
  }

  public compraRealizada() {
    this.endpointService.deleteElementsCarrito(this.cartArray, this.authService.userId).subscribe(data => {
      if (data['errorCode'] === 0) {
        this.cargaCarrito();
        this.snackBar.open('¡Compra realizada con éxito!', 'X', { duration: 7000, panelClass: ['green-snackbar'] });
        // this.dialogRef.close({ movin: this.movins });
        this.genericService.refreshStructure(1);
      } else {
        this.snackBar.open('Ha ocurrido un error', 'X', { duration: 6000, panelClass: ['red-snackbar'] });
      }
    });
  }

  public openStripe($event: any): void {
    let suscripcion = 0;
    this.numMovins = $event.movin;
    this.precioFinal = $event.finalIva;
    this.stripePayment.pay(this.precioFinal, this.numMovins, suscripcion);
  }

  public applyVoucherCode() {
    this.endpointService.getVoucherCodeDiscounts(this.voucherCode, this.authService.adminArea, this.authService.getIdCompanyType()).subscribe(data => {
      if (data['errorCode'] === 0) {

        if (data['response'].length) {
          let intersection = this.cartArray.filter(item1 => data['response'].some(item2 => item1.id_product == item2.id_product));

          intersection.forEach(producto => {
            let descuento = data['response'].find(obj => { return obj.id_product === producto.id_product; });

            let price_discount = descuento.price_discount;
            let price_discount_perc = descuento.price_discount_perc;

            // Si el producto no tiene descuento o es inferior al nuevo
            if ((!producto.price_discount || price_discount > parseFloat(producto.price_discount)) || (!producto.price_discount_perc || price_discount_perc > parseFloat(producto.price_discount_perc))) {

              // Aplicar descuento
              producto.price_discount = price_discount;
              producto.price_discount_perc = price_discount_perc;

              // Calcular precio con descuento
              if (price_discount > 0) {
                producto.precio = parseFloat(producto.price_base) - parseFloat(price_discount);

              } else if (price_discount_perc > 0) {
                producto.precio = parseFloat(producto.price_base) * (1-(parseFloat(price_discount_perc)/100))
              }

              // Evitar negativos
              if (producto.precio < 0 || !producto.precio)
                producto.precio = 0;
            }
          });
          this.calculaTotal();
          this.snackBar.open('¡Descuento aplicado!', 'X', { duration: 4000, panelClass: ['green-snackbar'] });
        } else
          this.snackBar.open('¡El descuento no es válido!', 'X', { duration: 4000, panelClass: ['red-snackbar'] });
      } else
        this.snackBar.open('¡Oops! Parece que hubo un error con tu solicitud', 'X', { duration: 4000, panelClass: ['red-snackbar'] });
    });

    this.voucherCode = '';
  }

  save(n: object) {
    if (n['error'] === 0) {
      this.endpointService.insertPlanMovin(this.authService.getIdCompany(), this.authService.getIdCompanyType(), this.authService.userId, this.numMovins, this.precioFinal, n['paid']).subscribe(data => {
        if (data['errorCode'] === 0) {
          this.movins += this.numMovins;
          if (this.movins >= this.totalCarro) {
            this.movins = this.movins - this.totalCarro;
            this.endpointService.addProducts(this.authService.getIdCompany(), this.authService.getIdCompanyType(), this.authService.userId, this.cartArray, this.totalCarro).subscribe(data => {
              if (data['errorCode'] === 0) {
                this.compraRealizada();
              }
            });
          } else {
            this.snackBar.open('¡Oops! Parece que hubo un error...', 'X', {
              duration: 7000, panelClass: ['green-snackbar']
            });
            this.dialogRef.close(false);
          }
        }
      });
    } else {
      this.snackBar.open('¡Oops! Parece que hubo un error con tu solicitud', 'X', {
        duration: 4000, panelClass: ['red-snackbar']
      });
    }
  }

  cancelar(){
    this.dialogRef.close();
  }
}
