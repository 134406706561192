import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { EndpointService } from 'app/services/generic/endpoint.service';

@Component({
  selector: 'app-choose-account-calendar',
  templateUrl: './choose-account-calendar.component.html',
  styleUrls: ['./choose-account-calendar.component.scss']
})
export class ChooseAccountCalendarComponent implements OnInit {

  public myGroupEventsFilter: { userId: number, id: number | any, value: boolean, user: string, type: string | any, name: string, type_account: number }[];
	public checkAll: boolean;

  constructor(
    private endpointService: EndpointService,
    private _bottomSheetRef: MatBottomSheetRef<ChooseAccountCalendarComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.myGroupEventsFilter = data.myGroupEventsFilter;
  }

  ngOnInit(): void {
    this.setCheckBoxAll();
    this._bottomSheetRef.backdropClick().subscribe(e => {
      this.onClose(false);
    })
  }

  onClose(applyFilter = false): void {
    if (applyFilter) {
      // Actualitzar localStorage
      let tmpFilterNotSelectedAccounts : number[] = [];
      this.myGroupEventsFilter.forEach(acc => {
        if (!acc.value) {
          tmpFilterNotSelectedAccounts.push(acc.id);
        }
      });
      localStorage.setItem('event_not_share_account', JSON.stringify(tmpFilterNotSelectedAccounts));

      this._bottomSheetRef.dismiss({myGroupEventsFilter: this.myGroupEventsFilter});
    }
    else {
      this._bottomSheetRef.dismiss(false);
    }
  }

  public changeStatusAccount(acc: any, event: boolean, i: number) {
    /*
    if (event) localStorage.removeItem('event_share_account_' + acc.id);
    else localStorage.setItem('event_share_account_' + acc.id, 'false');
    */
    this.myGroupEventsFilter[i].value = event;
    this.setCheckBoxAll();
  }

  public checkUncheckAll(event: boolean) {
    this.myGroupEventsFilter.forEach(acc => {
      acc.value = event;
      /*
      if (event) localStorage.removeItem('event_share_account_' + acc.id);
      else localStorage.setItem('event_share_account_' + acc.id, 'false');
      */
    });
    this.checkAll = event;
  }

  public setCheckBoxAll(): void {
    this.checkAll = (this.myGroupEventsFilter.filter(e => e.value).length == this.myGroupEventsFilter.length);
  }
}
