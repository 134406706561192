import {Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { GenericService } from 'app/services/generic/generic.service';
import { RoutingService } from 'app/services/routing/routing.service';
import { EndpointService } from "../../../services/generic/endpoint.service";
import {AuthService} from "../../../services/auth/auth.service";
import { ConditionsClientService } from 'app/services-client/conditions-client.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit, OnDestroy {

  @Input() structure = null;
  @Input() type;
  @Input() dialogId;
  @Input() moduleId;

  public params = null;
  public formGroup = {};
  public loaded = false;
  public emptyDialog = true;
  private monitoreo;

  constructor(
    private endpointService: EndpointService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private routingService:RoutingService,
    public genericService: GenericService,
    public conditionsClientService: ConditionsClientService,
    public authService: AuthService
  ) {
    console.log("data2", data);
    this.structure = data['structure'];
    this.type = data['type'];
    this.dialogId = data['dialogId'];
    this.moduleId = data['moduleId'];
  }
  //Ahora se tendra que pasar el id de la functional area a cargar en el dialog
  ngOnInit(): void {
    if(this.structure['creatingMode'] == undefined || !this.structure['creatingMode']) {
      console.log("this.moduleIdthis.moduleId", this.moduleId);
      this.genericService.fetchDSBStructure(this.structure['id_functional_parent_initial'], this.dialogId, this.structure['id_query'], this.type, this.moduleId).subscribe((data): void => {
        this.structure['child'] = data;
        if(this.type == 3) { // Asignamos los parámetros al usuario
          if (this.genericService.paramControlVariables[this.moduleId]) this.params = this.genericService.paramControlVariables[this.moduleId]['params'][this.genericService.paramControlVariables[this.moduleId]['indexParam']]['intern'];
          console.log(this.params, "paraaaaams")
          this.assignParams(this.structure, this.params);
          this.loaded = true
        } else if(this.type == 6) { // Asignamos los parámetros al usuario
          if (this.genericService.paramControlVariables[this.moduleId]) this.params = this.genericService.paramControlVariables[this.moduleId]['params'][this.genericService.paramControlVariables[this.moduleId]['indexParam']]['intern'];
          this.assignParams(this.structure, this.params);
          this.loaded = true
        } else { // Borramos de los parámetros del local si hay, pues sino queda mal si se abre de otro sitio
          delete this.genericService.paramControlVariables[this.structure["id_functional_parent_initial_dsb"]];
          this.loaded = true;
        }
  
        this.endpointService.insertMonitoringInformation(this.authService.getLoggedInUserId(), this.dialogId, this.dialogId, this.genericService.staticHTML, this.genericService.paramControlVariables).subscribe(data => {
          this.monitoreo = data['response'];
        });
      });
    } else {
      this.loaded = true
    }
  }

  ngOnDestroy() {
    this.endpointService.updateMonitoringInformation(this.monitoreo, this.genericService.staticHTML).subscribe(data => {});
    let idFa = this.genericService.findElementWithId(this.dialogId, false, false, true);
    if(idFa && idFa['child']) idFa['child'] = {};
    if(this.genericService.formsChanged[this.dialogId]) this.genericService.formsChanged[this.dialogId] = {};
  }

  assignParams(subStructure, params){
    if(subStructure) {
      for(let i in params) {
        if(params[i]['value']) {
          if(subStructure['id_db'] && params[i]['id_db'] && subStructure['id_db'] == params[i]['id_db'] && subStructure['bd_table'] == params[i]['bd_table'] && subStructure['bd_field'] == params[i]['bd_field']) {
            if(subStructure['multiple'] && subStructure['multiple'] == 1) {
              if(subStructure['tmp_value'] == null) subStructure['tmp_value'] = [];
              subStructure['tmp_value'].push(params[i]['value']);
              if(subStructure['form']) {
                subStructure['form'].patchValue( {
                  [subStructure['id_functional_area'] + '-' + subStructure['bd_field']]: subStructure['tmp_value']
                })
              }
            } else {
              subStructure['tmp_value'] = params[i]['value'];
              if(subStructure['form']) {
                subStructure['form'].patchValue( {
                  [subStructure['id_functional_area'] + '-' + subStructure['bd_field']]: params[i]['value']
                })
              }
              break;
            }
          }
        }
      }
      if(subStructure['child']) {
        for(let j in subStructure['child']) {
          this.assignParams(subStructure['child'][j], params)
        }
      }
    }
  }

}
