import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {AutoPopupComponent} from "../../../auto-plantilla/auto-popup/auto-popup.component";
import {JourneyEgoPopComponent} from "../../popup/journey_ego_pop/journey-ego-pop.component";
import {MatIconModule} from "@angular/material/icon";
import {EndpointService} from "../../../services/generic/endpoint.service";
import {MatDialog} from "@angular/material/dialog";
import {MatBottomSheet} from "@angular/material/bottom-sheet";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'journey-ego',
  templateUrl: './journey-ego.component.html',
  styleUrls: ['./journey-ego.component.css']
})

export class JourneyEgoComponent implements OnInit {

  @Input() dataSourceJourney;
  public eventStatusList = Array();

  public inputFilter: string;

  @Input() selectedTab;

  @Input() journeyArray;
  @Output() updateJourney = new EventEmitter();
  @Output() iniJourney = new EventEmitter();
  @Output() filterPlantilla = new EventEmitter();
  @Output() quitFilterPlantilla = new EventEmitter();

  displayedColumns: string[] = ['operations', 'name', 'status',  'creator', 'date'];

  constructor( public endpointService: EndpointService,
               private dialog: MatDialog) { }

  ngOnInit(): void {

    this.eventStatusList.push(
      'Parado', 'Activo'
    );

    this.iniJourney.emit();

  }

  journeyPopup(id: number){

    const dialogRef = this.dialog.open(JourneyEgoPopComponent,{
      width: 'auto',
      height: 'auto',
      data:{id: id}
    });

    dialogRef.afterClosed().subscribe( data=>{
      this.updateJourney.emit();
    });

  }

  public applyFilter(filterValue: string): void {
    this.dataSourceJourney.filter = filterValue.trim().toLowerCase();

    if (this.dataSourceJourney.paginator) {
      this.dataSourceJourney.paginator.firstPage();
    }
  }

  selectCheckBox(id){
    this.quitFilterPlantilla.emit();
    let index = this.journeyArray.findIndex((element, index) =>{
      if(element.id == id) return true;
    });
    this.journeyArray[index].checkbox = true;
    this.filterPlantilla.emit();
  }


}
