import {Injectable, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {EndpointService} from './generic/endpoint.service';

@Injectable()
export class PredefinedValuesLoaderService implements OnInit {

  private loadedLists: any = [];
  private loading: any = [];

  public static getItemFromHash(hash: Array<OptionElement>, value: number) {
    if (hash[value]) {
      return hash[value].name;
    } else {
      return '-';
    }
  }

  constructor(private endPointService: EndpointService) {
  }

  ngOnInit() {
  }

  public getFieldList(idModulo: number, fieldName: string): SubjectListManager {
    let slm: SubjectListManager = null;
    if (this.loadedLists[idModulo] && this.loadedLists[idModulo][fieldName]) {
      return this.loadedLists[idModulo][fieldName];
    }

    if (!this.loadedLists[idModulo]) {
      this.loadedLists[idModulo] = [];
    }

    if (this.loading[idModulo] && this.loading[idModulo][fieldName]) {
      return this.loading[idModulo][fieldName];
    } else {
      slm = new SubjectListManager();
    }

    if (!this.loading[idModulo]) {
      this.loading[idModulo] = [];
    }
    this.loading[idModulo][fieldName] = slm;

    this.endPointService.loadPredefinedValuesService(idModulo, fieldName).subscribe(data => {
      data['response'].forEach(e => {
        slm.list.push(new OptionElement(e.id, e.name));
      });
      slm.list.forEach(element => {
        slm.hash[element.id] = element;
      });
      slm.loaded = true;
      slm.subject.next(slm.list);
      this.loadedLists[idModulo][fieldName] = slm;
      this.loading[idModulo][fieldName] = null;
    });

    return slm;
  }
}

export class OptionElement {
  public id: number;
  public name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class SubjectListManager {
  public loaded;
  public list: Array<OptionElement>;
  public hash: Array<OptionElement>;
  public subject: Subject<Array<OptionElement>>;

  constructor() {
    this.loaded = false;
    this.list = [];
    this.hash = [];
    this.subject = new Subject<Array<OptionElement>>();
  }
}
