import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'app/services/auth/auth.service';
import { EndpointService } from 'app/services/generic/endpoint.service';
import { FunctionsService } from 'app/services/generic/functions.service';
import { GenericCreatorService } from 'app/services/generic/custom/generic-creator.service';
import { TemplateService } from 'app/services/generic/custom/template-element';
import { GenericService } from 'app/services/generic/generic.service';


@Component({
  selector: 'app-generic-creator-custom',
  templateUrl: './generic-creator-custom.component.html',
  styleUrls: ['./generic-creator-custom.component.css']
})
export class GenericCreatorCustomComponent implements OnInit {

  @Input() structure = null;
  @Input() index: number;
  @Input() isStatic: boolean = false;
  lodaded: boolean = true;
  isPreview: boolean = false;
  public loadedGenericStructure: boolean = false;
  public idGenericCreatorReference = 0;

  constructor(
    public functionsService: FunctionsService,
    public endpointService: EndpointService,
    public templateService: TemplateService,
    public genericCreatorService: GenericCreatorService,
    public genericService: GenericService,
    public authService: AuthService
  ) {
    this.genericCreatorService.actualSelectedElementObs$.subscribe(data => {
      if (data) {
        console.log('datadatadatadata', data)
        this.genericCreatorService.actualSelectedElement = data;
      }
    });
  }

  ngOnInit(): void {
    this.genericCreatorService.isStatic = this.isStatic;
    this.genericCreatorService.tmpId = 0;
    this.genericCreatorService.activeTabIndex = 0;
    this.genericCreatorService.actualSelectedElement = null;
    this.genericCreatorService.expandedFloatingIndex = null;
    this.genericCreatorService.expandedFloatingElement = null;
    this.genericCreatorService.isSubElement = false;
    this.genericCreatorService.isAddingElement = false;
    this.getElements();
  }

  onChangePreview() {
    if (this.isPreview) this.genericCreatorService.hideOptionsCreator = true;
    else if (this.genericCreatorService.actualSelectedElement) this.genericCreatorService.hideOptionsCreator = false;
  }

  getElements() {
    let id = 0;
    let tableAdd = "";
    if(this.genericCreatorService.isStatic) tableAdd = "statichtml_";
    let routeParams = this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['params'][this.genericService.paramControlVariables[this.structure[this.index]['id_functional_parent_initial']]['indexParam']]['input'];
    for (let k in routeParams) {
      if (routeParams[k]['id_db'] == 3 && routeParams[k]['bd_table'] == tableAdd + 'functional_area' && routeParams[k]['bd_field'] == 'id_functional_area') {
        id = routeParams[k]['value'];
        break;
      }
      if (routeParams[k]['id_db'] == 3 && routeParams[k]['bd_table'] == tableAdd + 'routing_functional_area_language' && routeParams[k]['bd_field'] == 'id_functional_area') {
        id = routeParams[k]['value'];
        break;
      }
    }

    let dataAPI = this.genericService.loadData(id);
    dataAPI['isStatic'] = this.genericCreatorService.isStatic;
    this.genericService.devModeGeneric = false;
    this.genericService.devModeGenericFunction();
    this.endpointService.getElementsGenericCreator(dataAPI).subscribe(data => {
      this.genericCreatorService.arrayElementsGenericCreator = data['response'].structure;
      this.genericCreatorService.arrayElementsTipo = data['response']['db_schema'].functional_type;

      this.genericCreatorService.defaultArray = data['response']['db_schema'].get_all_columns;
      this.genericCreatorService.defaultArray['functional_area_restrictions'] = [];
      this.genericCreatorService.defaultArray['permissions_group_functional_area'] = [];
      this.genericCreatorService.defaultArray['functional_area_language'] = [];
      this.genericCreatorService.defaultArray['excluding_data_type_fa_restrictions'] = [];
      this.genericCreatorService.defaultArray['creatingMode'] = true;
      this.genericCreatorService.defaultArray = { ...this.genericCreatorService.defaultArray, ...data['response']['db_schema'].get_all_columns_fa_language }
      this.genericCreatorService.custom_classes = data['response']['db_schema'].class_custom;
      this.genericCreatorService.angular_classes = data['response']['db_schema'].class_angular;
      this.genericCreatorService.class_angular_icon = data['response']['db_schema'].class_angular_icon;
      this.genericCreatorService.functionalStatus = data['response']['db_schema'].functional_status;
      this.genericCreatorService.languages = data['response']['db_schema'].languages;
      this.genericCreatorService.functional_subtype = data['response']['db_schema'].functional_subtype;
      this.genericCreatorService.defaultLanguageElement = data['response']['db_schema'].get_all_columns_fa_language;
      this.genericCreatorService.get_all_columns_fa_restrictions = data['response']['db_schema'].get_all_columns_fa_restrictions;
      this.genericCreatorService.get_all_columns_excluding_data_type_fa_restrictions = data['response']['db_schema'].get_all_columns_excluding_data_type_fa_restrictions;
      this.genericCreatorService.queries = data['response']['db_schema'].queries;
      this.genericCreatorService.db = data['response']['db_schema'].db;
      this.genericCreatorService.functional_type = data['response']['db_schema'].functional_type;
      this.genericCreatorService.id_product_mkt = data['response']['db_schema'].id_product_mkt;
      this.genericCreatorService.id_product_mkt_no = data['response']['db_schema'].id_product_mkt_no;
      this.genericCreatorService.id_condition = data['response']['db_schema'].id_condition;
      this.genericCreatorService.condition_session_active = data['response']['db_schema'].condition_session_active;
      this.genericCreatorService.condition_internal_routing = data['response']['db_schema'].condition_internal_routing;
      this.genericCreatorService.class_custom_original = data['response']['db_schema'].class_custom;
      this.genericCreatorService.permissions_group = data['response']['db_schema'].permissions_group;
      this.genericCreatorService.functions = data['response']['db_schema'].functions;
      this.genericCreatorService.company_type = data['response']['db_schema'].company_type;
      this.genericCreatorService.data_type = data['response']['db_schema'].data_type;
      this.genericCreatorService.admin_area = data['response']['db_schema'].admin_area;
      // Storing data for floating elements to be use in details tab
      console.log('this.genericCreatorService.data_type', this.genericCreatorService.data_type)
      this.genericCreatorService.arrayFloatingElements = [];
      if (this.genericCreatorService?.arrayElementsGenericCreator?.length > 0 && this.genericCreatorService.arrayElementsGenericCreator[0]?.['child']?.length > 0) {
        this.genericCreatorService.arrayElementsGenericCreator[0]['child'].forEach((item: any) => {
          if (item['id_functional_parent_initial_dsb'] > 0) {
            this.genericCreatorService.arrayFloatingElements.push(item);
          }
        })
        this.genericCreatorService.id_functional_type = 0;
        let idQuery: any;
        this.genericCreatorService.queries.forEach(item => {
          if (item.id_query === this.genericCreatorService.arrayElementsGenericCreator[0].id_query) {
            idQuery = item;
          }
        })
        this.genericCreatorService.containerForm.controls['id_query'].setValue(idQuery);
        this.genericCreatorService.containerForm.controls['id_db'].setValue(this.genericCreatorService.arrayElementsGenericCreator[0].id_db);
        this.genericCreatorService.containerForm.controls['bd_table'].setValue(this.genericCreatorService.arrayElementsGenericCreator[0].bd_table);

        // this.genericCreatorService.formBuilderFloatingEle();
      }

      this.genericCreatorService.filterFunctionalTypes();
      this.genericCreatorService.addElementIdsTMPLoop(this.genericCreatorService.arrayElementsGenericCreator);
      this.genericService.asignFrontend(this.genericCreatorService.arrayElementsGenericCreator, this.genericCreatorService.arrayElementsGenericCreator[0]['id_funtional_area']);
      this.genericService.findFormAttributesLoop(this.genericCreatorService.arrayElementsGenericCreator);
      this.genericCreatorService.currentRecordIndex = this.genericCreatorService.arrayElementsGenericCreatorRecord.push(this.genericService.cloneVariable(this.genericCreatorService.arrayElementsGenericCreator)) - 1;
      this.loadedGenericStructure = true;
    })
  }

  saveFloatingElement() {
    this.genericCreatorService.expandedFloatingIndex = null;
    this.genericCreatorService.expandedFloatingElement = null;
  }

  deleteElementGrid() {
    this.genericCreatorService.arrayElementsGenericCreator[0]['child'].forEach(item => {
      item.id_functional_status_general = 2;
      item.hide = 1
      item.hide_xs = 1
      item.hide_s = 1
      item.hide_m = 1
      item.hide_l = 1
      item.hide_xl = 1
      item['mode_type'] = 'delete';
    })
    console.log(this.genericCreatorService.arrayElementsGenericCreator)
  }
  orderAllElements(array) { }

  public redoUndo(action) {
    console.log('arrayElementsGenericCreatorRecord', this.genericCreatorService.arrayElementsGenericCreatorRecord)
    console.log('currentRecordIndex', this.genericCreatorService.currentRecordIndex)

    if (this.genericCreatorService.currentRecordIndex >= 0) {
      if (action == "undo") {
        --this.genericCreatorService.currentRecordIndex;
        console.log('after reduce', this.genericCreatorService.currentRecordIndex)
        if (
          this.genericCreatorService.arrayElementsGenericCreatorRecord[
          this.genericCreatorService.currentRecordIndex
          ]
        ) {
          this.genericCreatorService.arrayElementsGenericCreator = this.genericCreatorService.arrayElementsGenericCreatorRecord[this.genericCreatorService.currentRecordIndex];
          // this.genericCreatorService.arrayElementsGenericCreator = this.genericCreatorService.arrayElementsGenericCreatorRecord[0];
        } else {
          ++this.genericCreatorService.currentRecordIndex;
        }
      } else {
        ++this.genericCreatorService.currentRecordIndex;
        if (
          this.genericCreatorService.arrayElementsGenericCreatorRecord[
          this.genericCreatorService.currentRecordIndex
          ]
        ) {
          this.genericCreatorService.arrayElementsGenericCreator =
            this.genericCreatorService.arrayElementsGenericCreatorRecord[
            this.genericCreatorService.currentRecordIndex
            ];
        } else {
          --this.genericCreatorService.currentRecordIndex;
        }
      }
    }
  }

}
