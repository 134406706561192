import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FunctionsService } from "../../../services/generic/functions.service";
import { GenericService } from "../../../services/generic/generic.service";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() structure = null;
  @Input() index: number;

  public maxArchives;
  public currentArchives;
  public imgURL = [];
  public imagePath: FileList;
  public file: File[] = [];
  public formData: FormData;
  public docsPath: FileList;
  public doc: File[] = [];
  public docURL = [];
  public acceptedImgTypes: string[];
  public acceptedImgTypesFormatted: string[] = [];
  public acceptedDocTypes: string[];
  public acceptedDocTypesFormatted: string[] = [];
  public maxSize;
  public maxSizeMB;
  public currentFiles: DataTransfer;

  private updateFormFieldSubscribe: Subscription;

  @ViewChild('uploaderForm') uploadForm;
  @ViewChild('docUpload') docUpload;
  @ViewChild('imgUpload') imgUpload;

  constructor(
    private functionsService: FunctionsService,
    private genericService: GenericService
  ) { }

  ngOnInit(): void {
    if (this.structure[this.index]['multiple'] == 1) this.maxArchives = this.structure[this.index]['max_length'] !== null && this.structure[this.index]['max_length'] !== undefined && this.structure[this.index]['config']['defaultMaxArchives'] > this.structure[this.index]['max_length'] ? this.structure[this.index]['max_length'] : this.structure[this.index]['config']['defaultMaxArchives'];
    else this.maxArchives = 1;
    this.acceptedImgTypes = this.structure[this.index]['config']['imageAcceptedType'];
    this.acceptedImgTypesFormatted = this.structure[this.index]['config']['imageAcceptedTypeFormatted'];
    this.acceptedDocTypes = this.structure[this.index]['config']['documentAcceptedType'];
    this.acceptedDocTypesFormatted = this.structure[this.index]['config']['documentAcceptedTypeFormatted'];
    this.maxSize = this.structure[this.index]['config']['maxSize'];
    this.maxSizeMB = this.structure[this.index]['config']['maxSize'] / 1000000;
    this.currentFiles = new DataTransfer();

    if(this.structure[this.index]['fileList'] && this.structure[this.index]['fileList'].length && this.structure[this.index]['fileList'].length > 0) {
      if(this.structure[this.index]['type'] === 'image') {
        this.imgURL = this.structure[this.index]['fileList'];
      } else {
        this.docURL = this.structure[this.index]['fileList'];
      }
    }

    this.updateFormFieldSubscribe = this.genericService.updateFormFields.subscribe((change) => {
      if (change) {
        this.structure[this.index]['fileList'] = []
        this.structure[this.index]['tmp_value'] = []
      }
    })
  }

  ngOnDestroy(): void {
    if (this.updateFormFieldSubscribe) {
      this.updateFormFieldSubscribe.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.structure[this.index]['form'] = this.uploadForm
    this.currentArchives = this.structure[this.index]['form']['nativeElement'][0]['files'].length
  }

  changeSelectedImage(event) {
    setTimeout(() => {
      this.imgURL = [];
      this.file = [];
      let fileList: FileList = event.target.files;
  
      if (fileList.length > 0 && fileList.length <= this.maxArchives) {
        if (this.structure[this.index]['multiple'] == 1) {
          fileList = this.mergeFileLists(fileList, this.currentFiles.files);
          this.currentFiles.items.clear();
        }
  
        this.imagePath = fileList;
        const partName = 'uploadFile';
        this.formData = new FormData();
  
        // Array temporal para mantener el orden
        let imageLoadPromises = [];
  
        for (let i = 0; i < fileList.length; i++) {
          if (this.acceptedImgTypes.includes(fileList[i].type)) {
            if (fileList[i].size < this.maxSize) {
              // Crear una promesa para cada archivo que se resuelve cuando el archivo es leído
              let fileLoadPromise = new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                  // Guardar la imagen en la posición correspondiente
                  resolve({ src: reader.result, file: fileList[i] });
                };
                reader.onerror = reject;
                reader.readAsDataURL(fileList[i]);
              });
  
              imageLoadPromises.push(fileLoadPromise);
            } else {
              this.genericService.openSnackBar("El archivo " + fileList[i].name + " supera los " + this.maxSizeMB + "MB permitidos", 10000, ["red-snackbar"]);
            }
          } else {
            this.genericService.openSnackBar("Los archivos de tipo ." + fileList[i].name.split('.')[1] + " no son aceptados.", 10000, ["red-snackbar"]);
          }
        }
  
        // Resolver todas las promesas y agregar las imágenes al array final
        Promise.all(imageLoadPromises).then((results: any[]) => {
          results.forEach(result => {
            this.imgURL.push(result.src);
            this.file.push(result.file);
            this.formData.append(partName + this.file.length, result.file, result.file.name);
            this.currentFiles.items.add(result.file);
          });
          this.structure[this.index]['fileList'] = this.imgURL;
          this.structure[this.index]['tmp_value'] = this.formData;
        }).catch(error => {
          console.error('Error al leer los archivos', error);
        });
      } else if (this.maxArchives == 1) {
        this.genericService.openSnackBar("Solamente puedes subir un fichero", 10000, ["red-snackbar"]);
      } else {
        this.genericService.openSnackBar("El número máximo de archivos es " + this.maxArchives, 10000, ["red-snackbar"]);
      }
  
      event.target.files = fileList;
    });
  }
  

  changeSelectedDoc(event) {
    setTimeout(() => {
      let docList: FileList = event.target.files;
      this.docURL = [];
      this.doc = [];
      
      if (docList.length > 0 && docList.length <= this.maxArchives) {
        if (this.structure[this.index]['multiple'] == 1) {
          docList = this.mergeFileLists(docList, this.currentFiles.files);
          this.currentFiles.items.clear();
        }
        this.docsPath = docList;
        const partName = 'uploadFile';
        this.formData = new FormData();
    
        // Array temporal para mantener el orden
        let docLoadPromises = [];
    
        for (let i = 0; i < docList.length; i++) {
          if (this.acceptedDocTypes.includes(docList[i].type)) {
            if (docList[i].size < this.maxSize) {
              // Crear una promesa para leer cada archivo
              let fileLoadPromise = new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                  // Guardar el documento en la posición correcta
                  resolve({ name: docList[i].name, file: docList[i] });
                };
                reader.onerror = reject;
                reader.readAsDataURL(docList[i]);
              });
    
              docLoadPromises.push(fileLoadPromise);
            } else {
              this.genericService.openSnackBar("El archivo " + docList[i].name + " supera los " + this.maxSizeMB + "MB permitidos", 10000, ["red-snackbar"]);
            }
          } else {
            this.genericService.openSnackBar("Los archivos de tipo ." + docList[i].name.split('.')[1] + " no son aceptados.", 10000, ["red-snackbar"]);
          }
        }
    
        // Resolver todas las promesas y agregar los documentos al array final
        Promise.all(docLoadPromises).then((results: any[]) => {
          results.forEach(result => {
            this.docURL.push(result.name);
            this.doc.push(result.file);
            this.formData.append(partName + this.doc.length, result.file, result.file.name);
            this.currentFiles.items.add(result.file);
          });
          this.structure[this.index]['fileList'] = this.docURL;
          this.structure[this.index]['tmp_value'] = this.formData;
        }).catch(error => {
          console.error('Error al leer los documentos', error);
        });
    
      } else if (this.maxArchives == 1) {
        this.genericService.openSnackBar("Solamente puedes subir un fichero", 10000, ["red-snackbar"]);
      } else {
        this.genericService.openSnackBar("El número máximo de archivos es " + this.maxArchives, 10000, ["red-snackbar"]);
      }
    
      event.target.files = docList;
    });
  }
  
  
  diselectImage(i) {
    this.imgUpload.nativeElement.value = '';
    this.imgURL.splice(i, 1);
    this.formData.delete("uploadFile" + i);
    this.currentFiles.items.remove(i);
    this.checkFileUploaded();
  }

  diselectDoc(i) {
    this.docUpload.nativeElement.value = '';
    this.docURL.splice(i, 1);
    this.formData.delete("uploadFile" + i);
    this.currentFiles.items.remove(i);
    this.checkFileUploaded();
  }

  onFileDropped(files) {
    let fake = {
      target: {
        files: files
      }
    };
    if (this.structure[this.index]['type'] == "image") this.changeSelectedImage(fake);
    else if (this.structure[this.index]['type'] == "document") this.changeSelectedDoc(fake);
  }

  mergeFileLists(incoming: FileList, actual: FileList) {
    if (actual !== null && actual !== undefined && actual.length > 0) {
      let names = [];
      let aux = new DataTransfer()
      for (let i in actual) {
        if (!isNaN(Number(i))) {
          names.push(actual.item(Number(i)).name)
          aux.items.add(actual.item(Number(i)))
        }
      }
      for (let j in incoming) {
        if (!isNaN(Number(j))) {
          if (!names.includes(incoming.item(Number(j)).name)) {
            aux.items.add(incoming.item(Number(j)))
            names.push(incoming.item(Number(j)).name)
          }
        }
      }
      return aux.files
    }
    return incoming
  }


  checkFileUploaded() {
    this.genericService.fileUploaded = this.structure[this.index]['fileList'].length > 0;
  }
}
