import {Component, OnInit, ViewChild} from "@angular/core";
import {UntypedFormControl} from "@angular/forms";
import {DynamicReportComponent} from "../workflow/subcomponents/dynamic-report/dynamic-report.component";
import {Strings} from "../../strings/strings";
import { JourneyEgoComponent } from "./subcomponents/journey_ego/journey-ego.component";
import { CampanyaEgoComponent } from "./subcomponents/campaña_ego/campanya-ego.component";
import {MatTableDataSource} from "@angular/material/table";
import {EndpointService} from "../services/generic/endpoint.service";
//import {MatDialog} from "@angular/material/dialog";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";

@Component({
  selector: 'ego',
  templateUrl: './ego.component.html',
  styleUrls: ['./ego.component.css']
})
export class EgoComponent implements OnInit {

  public inputFilter: string;

  public strings = Strings.WORKFLOW;

  public editReport = false;
  public showOtherButtons = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('DynamicReportComponent') public DynamicReportComponent: DynamicReportComponent;
  @ViewChild('JourneyEgoComponent') public JourneyEgoComponent: JourneyEgoComponent;
  @ViewChild('CampanyaEgoComponent') public CampanyaEgoComponent: CampanyaEgoComponent;

  public journeyArray;
  public campanaArray;

  public plantillasArray;
  public notification;
  public notificationSuperior;
  public notificationSuperiorHome;
  public dataSourcePlantilla: MatTableDataSource<any>;

  public dataSourceJourney: MatTableDataSource<any>;
  public dataSourceCampana: MatTableDataSource<any>;

  public selectedTab = new UntypedFormControl(0);

  public nCamp = 0;
  public nJour = 0;

  public actualTab;

  constructor( public endpointService: EndpointService) { }

  ngOnInit(): void {
  }

  public sendShowOtherButtons(){
    this.showOtherButtons = true;
  }

  public sendEditReport(){
    this.editReport = true;
  }

  setCurrentTab(event: number) {
    sessionStorage.setItem('__selectedTab__', event.toString());
    // return false;
  }


  public iniCampana(){
    this.endpointService.getAllCampana().subscribe( data => {
      this.campanaArray = data['response'];
      this.dataSourceCampana = new MatTableDataSource(this.campanaArray);
    });
  }

  public iniJourney(){
    this.endpointService.getAllJourney().subscribe( data => {
      this.journeyArray = data['response'];
      this.dataSourceJourney = new MatTableDataSource(this.journeyArray);
    });
  }

  public iniPlantillas(){

    this.endpointService.getAllPlantillas().subscribe(data =>{
      this.notification = data['response'].noti;
      this.notificationSuperior = data['response'].notiSup;
      this.plantillasArray = data['response'].plantillas;
      this.notificationSuperiorHome = data['response'].notiSupHome;
      this.dataSourcePlantilla = new MatTableDataSource(this.plantillasArray);
      this.dataSourcePlantilla.paginator = this.paginator;
      this.dataSourcePlantilla.sort = this.sort;
      //this.applyFilter(this.inputFilter);

    });
  }

  public updateJourney(){
    this.endpointService.getAllJourney().subscribe( data => {
      this.journeyArray = data['response'];
      //this.dataSourceJourney = new MatTableDataSource(this.journeyArray);
      this.filterJourney();
      this.updatePlantillas();
    });
  }

  public updateCampana(){
    this.endpointService.getAllCampana().subscribe( data => {
      this.campanaArray = data['response'];
      this.dataSourceCampana = new MatTableDataSource(this.campanaArray);
      this.updateJourney();
    });
  }

  public updatePlantillas(){

    //console.log("UPDATE PLANTILLA");

    this.endpointService.getAllPlantillas().subscribe(data =>{
      this.notification = data['response'].noti;
      this.notificationSuperior = data['response'].notiSup;
      this.plantillasArray = data['response'].plantillas;
      //this.dataSourcePlantilla = new MatTableDataSource(this.plantillasArray);
      this.filterPlantilla();
      this.dataSourcePlantilla.paginator = this.paginator;
      this.dataSourcePlantilla.sort = this.sort;
      //this.applyFilter(this.inputFilter);

    });

  }

  public filterJourney(){

    this.quitFilterPlantilla();

    let filterFinal = new Array();
    let filterC = new Array();
    for(let x = 0; x < this.campanaArray.length; x++) if(this.campanaArray[x].checkbox == true) filterC.push(this.campanaArray[x]);
    console.log(filterC);
    this.nCamp = filterC.length;

    if(filterC.length > 0){
      for(let x = 0; x < filterC.length ; x++){
        for(let y = 0; y < this.journeyArray.length; y++)
          if(filterC[x].id == this.journeyArray[y].id_campanya) filterFinal.push(this.journeyArray[y]);
      }
      console.log(filterFinal);
      this.dataSourceJourney = new MatTableDataSource(filterFinal);
    }else this.dataSourceJourney = new MatTableDataSource(this.journeyArray);

  }

  public quitFilterJourney(){
    this.nCamp = 0;
    for(let x = 0; x < this.campanaArray.length; x++) this.campanaArray[x].checkbox = false;
    this.dataSourceJourney = new MatTableDataSource(this.journeyArray);
  }

  public filterPlantilla(){

    let filterFinal = new Array();
    let filterJ = new Array();
    for(let x = 0; x < this.journeyArray.length; x++) if(this.journeyArray[x].checkbox == true) filterJ.push(this.journeyArray[x]);
    console.log(filterJ);
    this.nJour = filterJ.length;


    if(filterJ.length > 0){

      for(let x = 0; x < filterJ.length ; x++){
        for(let y = 0; y < this.plantillasArray.length; y++){
          if(filterJ[x].id == this.plantillasArray[y].id_customer_journey) filterFinal.push(this.plantillasArray[y]);
        }
      }
      console.log(filterFinal);
      this.dataSourcePlantilla = new MatTableDataSource(filterFinal);
    }else this.dataSourcePlantilla = new MatTableDataSource(this.plantillasArray);

  }

  public quitFilterPlantilla(){
    this.nJour = 0;
    for(let x = 0; x < this.journeyArray.length; x++) this.journeyArray[x].checkbox = false;
    this.dataSourcePlantilla = new MatTableDataSource(this.plantillasArray);
  }



}
