<ng-container *ngIf="structure === null || structure == undefined || !loadedStructure || !genericService.refreshStructureFinished || !genericService.finishedLoadStructure || genericService.tabChanging">
  <mat-progress-bar [ngClass]="{'loading-window':true, 'loading-window-top-5':this.routingService.loadedBreadcrumbs, 'loading-window-top-0': routingService.shouldOpenExternalWindow}" mode="indeterminate"></mat-progress-bar>
</ng-container>
<span *ngIf="this.changingTab() && structure !== null && structure != undefined && loadedStructure && genericService.finishedLoadStructure && !genericService.tabChanging"
      [ngClass]="genericService.containerFunctionArea['id_functional_status_mkt'] != 1 ? this.classContainer + ' ' + 'mkt-no-available' : this.classContainer"
      [id]="genericService.containerFunctionArea['id_functional_area']"
      [ngStyle]="genericService.containerFunctionArea['styleParsed']">
      <span [ngClass]="{'create-pdf-generic': functionsService.downloadingStaticPage, 'hiddenExternal': routingService.shouldOpenHelp || routingService.shouldOpenChat || routingService.shouldOpenExternalWindow || routingService.shouldOpenSearch || routingService.shouldOpenMenu }">
        <app-generic [structure]="structure"></app-generic>
      </span>
</span>